import * as React from 'react';
import { useTranslation } from '../../../../i18n';
import styles from './ReportTopBar.module.css';
import { ImportIcon } from 'evergreen-ui';
import AppButton from '../../../../components/AppButton/AppButton';
import LanguageSwitcher from '../../../../components/LanguageSwitcher';
import { i18n } from '@neurolytics/results-template';
import { ReportStatus } from '../../../../typings/report';

interface ReportTopBarProps {
    candidateFullName: string;
    onDownload: () => void;
    targetGroupName: string;
    reportStatus?: ReportStatus;
}

const ResultsTopBar: React.FC<ReportTopBarProps> = (props) => {
    const { candidateFullName, onDownload, targetGroupName, reportStatus } =
        props;
    const { t } = useTranslation();

    return (
        <div id="report_top_bar" className={`${styles.topbar}`}>
            <div className={styles.topbar__wrapper}>
                {/* candidate name */}
                <div className={styles.topbar__wrapperTitleGrid}>
                    <span className={styles.topbar__report_title}>
                        {t('REPORT.TITLE', {
                            candidate_full_name: candidateFullName,
                        })}
                    </span>
                </div>

                {/* download pdf*/}
                <div className={styles.topbar__wrapperDownloadGrid}>
                    <AppButton
                        icon={ImportIcon}
                        borderRadius="3px"
                        isDisabled={
                            !!reportStatus && reportStatus === 'DELETED'
                        }
                        onClick={onDownload}>
                        {t('REPORT.DOWNLOAD_PDF')}
                    </AppButton>
                </div>

                <div className={styles.topbar__wrapperLanguageSwitchGrid}>
                    <LanguageSwitcher secondI18n={i18n} />
                </div>

                <div className={styles.topbar__wrapperGroupGrid}>
                    <span
                        className={styles.topbar__wrapperGroupGrid__group_name}>
                        {targetGroupName}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ResultsTopBar;
