import style from './style.module.css';

export type ActiceSteps = 'first' | 'second' | 'third' | 'forth';

interface IScanStepsFlow {
    isScanSetupWizard: boolean;
    existingAssessmentSection?: boolean;
    active: ActiceSteps[];
}
const ScanStepsFlow = (props: IScanStepsFlow) => {
    const { isScanSetupWizard, active, existingAssessmentSection } = props;
    return (
        <ul className={style.steps}>
            <li
                className={
                    active.length === 1
                        ? style.active
                        : active.length > 1
                        ? style.steps__exist
                        : ''
                }
                style={{ width: existingAssessmentSection ? '33%' : '25%' }}>
                {isScanSetupWizard ? 'Group' : 'Scan'} details
            </li>
            <li
                className={
                    active.length === 2
                        ? style.active
                        : active.length > 2
                        ? style.steps__exist
                        : ''
                }
                style={{ width: existingAssessmentSection ? '33%' : '25%' }}>
                Scan setup
            </li>
            {!existingAssessmentSection && (
                <li
                    className={
                        active.length === 3
                            ? style.active
                            : active.length > 3
                            ? style.steps__exist
                            : ''
                    }>
                    Customize scan
                </li>
            )}
            <li
                className={active.length === 4 ? style.active : ''}
                style={{ width: existingAssessmentSection ? '33%' : '25%' }}>
                Review
            </li>
        </ul>
    );
};

export default ScanStepsFlow;
