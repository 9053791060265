import React, {Dispatch, FunctionComponent, SetStateAction} from 'react';
import styles from './ScanDetailsModals.module.css';
import DialogComponent from 'components/Dialog/Dialog';
import { ConfirmIcon, TrashIcon } from 'evergreen-ui';

interface ScanSetupModalsProps {
    shouldShowChangeScanNameModal: boolean;
    shouldShowDeleteScanModal: boolean;
    scanName: string;
    setScanName: Dispatch<SetStateAction<string>>;
    deleteEmptyScan: () => void;
    onDiscardModal: () => void;
    updateScanConfig: () => void;
}

const ScanDetailsModals: FunctionComponent<ScanSetupModalsProps> = (
    props: ScanSetupModalsProps,
) => {
    const {
        shouldShowChangeScanNameModal,
        shouldShowDeleteScanModal,
        scanName,
        setScanName,
        onDiscardModal,
        updateScanConfig,
        deleteEmptyScan,
    } = props;

    return (
        <>
            {shouldShowChangeScanNameModal && (
                <DialogComponent
                    title="Change scan name"
                    confirmButtonText="Change"
                    closeButtonText="Never mind"
                    isShown={shouldShowChangeScanNameModal}
                    onClose={onDiscardModal}
                    titleBackgroundColor={'white'}
                    onConfirm={updateScanConfig}
                    confirmIcon={ConfirmIcon}
                    isConfirmButtonDisabled={!scanName}>
                    <input
                        value={scanName}
                        onChange={(event)=>setScanName(event.target.value)}
                        className={styles.dialog__input}
                    />
                    {!scanName && (
                        <div style={{ color: 'red', paddingTop: '8px' }}>
                            This field can NOT be empty.
                        </div>
                    )}
                    <div className={styles.dialog__content}>
                        <div className={styles.dialog__info}>!</div>
                        <p className={styles.dialog__text}>
                            We recommend naming your scans using a standard
                            format (such as “job offer + month”, <br />
                            or “selected modules | month - year”) so you can
                            easily find already existing setups.
                        </p>
                    </div>
                </DialogComponent>
            )}
            {shouldShowDeleteScanModal && (
                <DialogComponent
                    title="Confirm to delete this scan"
                    confirmButtonText="Delete scan"
                    isShown={shouldShowDeleteScanModal}
                    onClose={onDiscardModal}
                    onConfirm={deleteEmptyScan}
                    confirmIcon={TrashIcon}>
                    <div className={styles.dialog__content}>
                        Are you sure you want to delete this scan?
                        <br />
                        <br />
                        This will permanently delete the scan configuration.
                    </div>
                </DialogComponent>
            )}
        </>
    );
};

export default ScanDetailsModals;
