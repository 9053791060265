import styled from 'styled-components';

interface IProps {
    completed?: boolean;
    active?: boolean;
}

export const MediaLibraryWrap = styled('div')`
    width: 100%;
    min-height: 350px;
    padding-top: 16px;
`;

export const TabsWrapper = styled('div')`
    border-bottom: 1px solid #435970;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const Tab = styled('div')<IProps>`
    border: 1px solid #435970;
    border-bottom: none;
    width: 148px;
    height: 40px;
    color: #849cb1;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #435970;
        opacity: 0.9;
        color: white;
        cursor: pointer;
    }
    ${({ active }) =>
        active &&
        `
            background-color: #435970;
        color: white;
        cursor: pointer;
  `}
`;

export const UploadContainer = styled('div')`
    padding-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
`;

export const UploadWrapper = styled('label')`
    width: 66%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightseagreen' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
    border-radius: 2px;
    gap: 16px;
    color: #849cb1;
    &.speaking-section {
        width: 35%;
    }
    input {
        display: none;
    }
    .file-drop-error {
        width: 100%;
        text-align: center;
        color: red;
        display: inline-block;
        margin-bottom: 1em;
    }
    .file-drop-note {
        width: 100%;
        text-align: center;
        color: rgb(0, 165, 173);
        display: inline-block;
        margin-top: 0.5em;
    }
`;

export const UploadTitle = styled('div')`
    color: #435970;
    font-size: 16px;
    &.video-title {
        padding-left: 32px;
    }
    &.speaking-title {
        font-weight: bold;
    }
`;

export const UploadButton = styled('div')`
    width: fit-content;
    background: rgb(0, 165, 173);
    cursor: pointer;
    border-radius: 7px;
    padding: 14px 36px;
    color: white;
    font-size: 16px;
    opacity: 1;
    input[type='file'] {
        display: none;
    }
    &:hover {
        opacity: 0.7;
    }
`;

export const AfterUploadedWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const AfterUploaded = styled('div')`
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 250px;
    border-radius: 2px;
    gap: 4px;
`;

export const TrashIconWrapper = styled('div')`
    width: 32px;
    height: 32px;
    border-radius: 2px;
    background-color: #435970;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    opacity: 1;
    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
`;

export const FilesListWrap = styled('div')`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 50px;
    padding: 50px;
    max-height: 400px;
    overflow: auto;
`;

export const PlayButtonWrapper = styled('div')`
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 20px;
    color: #fff;
    z-index: 1;
`;

export const FileCardWrapper = styled('div')`
    position: relative;
    height: 200px;
    //background-color: #e0e4e6;
    .file-card-video {
        width: 100%;
    }
    .file-card-video-sub {
        margin-top: 15px;
    }
`;
export const Svg = styled('svg')`
    cursor: pointer;
    position: absolute;
    width: 72px;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    .stroke-solid {
        stroke-dashoffset: 0;
        stroke-dasharray: 300;
        stroke-width: 4px;
        transition: stroke-dashoffset 1s ease, opacity 1s ease;
    }
    .icon {
        transform-origin: 50% 50%;
        transition: transform 200ms ease-out;
    }
`;
export const CheckboxWrapper = styled.div`
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    right: 0;
`;
export const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;
export const HiddenCheckBox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;
export const StyledCheckbox = styled.div<{ checked: boolean }>`
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: ${(props) => (props.checked ? '#00A5AC' : 'white')};
    border-radius: 3px;
    border: ${(props) =>
        props.checked ? '1px solid #00A5AC' : '1px solid #435970'};
    transition: all 150ms;
    &:hover {
        background-color: #00a5ac;
    }
    ${Icon} {
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    }
`;
