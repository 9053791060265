import { call, put } from 'redux-saga/effects';
import NeurolyticsAPI from 'services/NeurolyticsAPI';
import { actionsTypes } from 'store/companies/companies.actions';
import { Company } from './companies.reducer';

export function* getCompanies() {
    try {
        const companies: Company[] = yield call(NeurolyticsAPI.getCompanies);
        yield put({
            type: actionsTypes.GET_COMPANIES_SUCCESS,
            payload: { companies },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_COMPANIES_FAILURE,
            payload: { error },
        });
    }
}

export function* getCompanyById(action: { type: string; payload: any }) {
    const { companyId } = action.payload;
    try {
        const company: Company = yield call(
            NeurolyticsAPI.getCompanyById,
            companyId,
        );
        yield put({
            type: actionsTypes.GET_COMPANY_BY_ID_SUCCESS,
            payload: { company },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_COMPANY_BY_ID_FAILURE,
            payload: { error },
        });
    }
}

export function* createCompany(action: { type: string; payload: any }) {
    try {
        const { company } = action.payload;

        const requestPresignedUrlParams = {
            type: 'company-logo',
            file_name: `logo.png`,
            params: { company_slug: company.slug },
        };

        // @ts-ignore
        const logoUrl = yield call(
            [NeurolyticsAPI, 'uploadWithPresignedUrlFlow'],
            requestPresignedUrlParams,
            company.logo,
        );

        const createCompanyParams = {
            name: company.name,
            country: 'NL',
            slug: company.slug,
            logoUrl: logoUrl,
        };

        const createdCompany: Company = yield call(
            NeurolyticsAPI.createCompany,
            createCompanyParams,
        );

        yield put({
            type: actionsTypes.CREATE_COMPANY_SUCCESS,
            payload: { company: createdCompany },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.CREATE_COMPANY_FAILURE,
            payload: { error },
        });
    }
}
