import styled from 'styled-components';

export const FirstStepWrapper = styled('div')`
    padding: 4px 0;
`;
export const InputSection = styled('div')`
    margin-bottom: 40px;
`;
export const InputLabel = styled('div')`
    color: #435970;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;
export const Input = styled('input')`
    width: 70%;
    height: 40px;
    padding: 0 20px;
    outline: none;
    border: 1px solid #849cb1;
    &::placeholder {
        color: #849cb1;
    }
    &:hover {
        border: 1px solid #435970;
    }
    &:focus {
        border: 1px solid #435970;
    }
`;
export const InfoTooltip = styled('div')`
    font-size: 13px;
    position: absolute;
    top: calc(100% - 34px);
    left: 64px;
    visibility: hidden;
    color: transparent;
    background-color: transparent;
    width: 250px;
    padding: 20px;
    border-radius: 18px;
    transition: visibility 0.5s, color 0.5s, background-color 0.5s,
        padding 0.5s ease-in-out;
`;
export const InfoIcon = styled('div')`
    font-size: 14px;
    font-weight: bold;
    padding-top: 5px;
    color: white;
    width: 26px;
    height: 26px;
    background-color: #435970;
    border-radius: 50%;
    margin-left: 24px;
    text-align: center;
    &:hover {
        background-color: #849cb1;
        cursor: pointer;
    }
`;
export const InputLabelSpan = styled('span')`
    color: #849cb1;
    font-size: 14px;
    font-weight: normal;
    padding-left: 12px;
`;
export const InputWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
export const InfoIconWrapper = styled('div')`
    position: relative;
    display: flex;
    align-items: center;
    & ${InfoIcon}:hover + ${InfoTooltip} {
        visibility: visible;
        color: #435970;
        border: 1px solid #849cb1;
        line-height: 26px;
        width: 250px;
        padding: 20px;
        border-radius: 18px;
        background-color: #f0f6fb;
        cursor: pointer;
    }
`;
