import styled from 'styled-components';
interface IProps {
    headerSize?: number;
}

export const ReportViewWrapper = styled('div')<IProps>`
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    display: grid;
    grid-template-areas:
        'sidebar topbar'
        'sidebar header'
        'sidebar navbar'
        'sidebar content';
    grid-template-columns: 200px 1fr;
    ${({ headerSize }) => `
    grid-template-rows: 90px ${headerSize}px 60px 1fr;
  `}
    overflow: scroll;
    height: 100vh;
    scroll-behavior: smooth;
`;
