import React, { FunctionComponent, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { selectTargetGroupState } from 'store/targetGroups/targetGroups.selectors';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import {
    withStyles,
    Theme,
    createTheme,
    ThemeProvider,
} from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { Participant, UserAssessmentActivity } from 'typings/user';
import styles from './TargetGroupTable.module.css';
import TargetGroupTableFooter from './TargetGroupTableFooter/TargetGroupTableFooter';
import { TargetGroupParticipant } from '../../../typings/target_group';
import ParticipantRow from './ParticipantRow';
import { Assessment } from '../../../typings/assessment';

interface TargetGroupTableProps {
    scan?: Assessment;
    candidateTotalPages: number[];
    candidateCurrentPage: number;
    candidatesPerPage: number;
    participants: {
        [participantId: string]: Participant;
    };
    participantsSelected: Participant[];
    areNoParticipants: boolean;
    isUsersInfoLoading: boolean;
    isUsersDetailsLoading: boolean;
    onSelect: (userId: string, event: any) => void;
    onSelectAll: () => void;
    onConvertVideos: (userId: string) => void;
    // onSendInvitationEvent: () => void;
    onSendResetPasswordEvent: () => void;
    onSendReminderEvent: () => void;
    onSendReportEvent: () => void;
    onRemoveCandidateEvent: () => void;
    onFullDeleteCandidateEvent: () => void;
    onResetCandidateScanEvent: () => void;
    handleChangePage: (arg0: number) => void;
    updatingParticipants: boolean;
    resetScanLoading: boolean;
    targetGroupId: string;
    isSuperAdmin: boolean;
}

export const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: '#435970',
        boxShadow: '0 0 3px 0 #435970',
        fontSize: 14,
        minWidth: 100,
        borderRadius: 8,
    },
}))(Tooltip);

const theme = createTheme({
    overrides: {
        MuiTableHead: {
            root: {
                border: 'solid 1px #bbc9d9',
            },
        },
        MuiTableRow: {
            root: {
                '&$selected, &$selected:hover': {
                    backgroundColor: '#F6F9FD',
                },
            },
            head: { border: 'solid 1px #bbc9d9' },
        },
        MuiTableCell: {
            root: {
                '&$head': {
                    fontWeight: '700',
                    color: '#445870',
                    backgroundColor: '#F4F6FA',
                    borderRadius: 0,
                },
                lineHeight: '0.7',
                verticalAlign: 'middle',
            },
            sizeSmall: { padding: '5px' },
        },
        MuiTableContainer: {
            root: {
                maxHeight: 440,
            },
        },
    },
});

export const getLastCompletedSession = (
    userActivity?: UserAssessmentActivity,
) => {
    if (
        !userActivity ||
        !userActivity.sessions ||
        userActivity.sessions.length === 0
    ) {
        return null;
    }
    const completedSessions = userActivity.sessions.filter(
        (session) => session.finishedAt,
    );

    if (completedSessions.length > 0) {
        completedSessions.sort((a, b) =>
            b.finishedAt.localeCompare(a.finishedAt),
        );
        return completedSessions[0];
    }

    return userActivity.sessions[userActivity.sessions.length - 1];
};

const TargetGroupTable: FunctionComponent<TargetGroupTableProps> = (
    props: TargetGroupTableProps,
) => {
    const {
        scan,
        candidateTotalPages,
        candidateCurrentPage,
        candidatesPerPage,
        targetGroupId,
        participants,
        isSuperAdmin,
        participantsSelected,
        isUsersInfoLoading,
        areNoParticipants,
        updatingParticipants,
        resetScanLoading,
        isUsersDetailsLoading,
        onSelect,
        onSelectAll,
        onSendResetPasswordEvent,
        onSendReminderEvent,
        onSendReportEvent,
        onRemoveCandidateEvent,
        onFullDeleteCandidateEvent,
        onResetCandidateScanEvent,
        handleChangePage,
    } = props;
    const [usersShowingInfo, updateUsersShowingInfo] = useState<string[]>([]);
    const targetGroupsState = useSelector(selectTargetGroupState);
    const { participantsToAdd, participantsToRemove, data } = targetGroupsState
        .byId[targetGroupId]
        ? targetGroupsState.byId[targetGroupId]
        : {
              participantsToAdd: [],
              participantsToRemove: [],
              data: {
                  id: '',
                  assessment_id: '',
                  name: '',
                  created_at: '',
                  updated_at: '',
                  expiration_date: '',
                  participants: [],
                  config: {
                      is_public: false,
                      verify_emails: false,
                      auto_send_reports: false,
                  },
              },
          };

    const onUserShowInfoChange = (userId: string) => {
        const alreadyShowingInfo = usersShowingInfo.some((id) => id === userId);
        if (alreadyShowingInfo) {
            const update = [...usersShowingInfo].filter((id) => id !== userId);
            updateUsersShowingInfo(update);
        } else {
            const update = [...usersShowingInfo];
            // @ts-ignore
            update.push(userId);
            updateUsersShowingInfo(update);
        }
    };

    return (
        <React.Fragment>
            {!isUsersInfoLoading && (
                <div className={styles.target_group_table}>
                    <div className={styles.target_group_table__content}>
                        <ThemeProvider theme={theme}>
                            <TableContainer>
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={1} />
                                            <TableCell>
                                                <Checkbox
                                                    disabled={
                                                        isUsersDetailsLoading ||
                                                        updatingParticipants ||
                                                        resetScanLoading
                                                    }
                                                    indeterminate={
                                                        participantsSelected.length >
                                                            0 &&
                                                        participantsSelected.length <
                                                            Object.keys(
                                                                participants,
                                                            ).length
                                                    }
                                                    checked={
                                                        Object.keys(
                                                            participants,
                                                        ).length > 0 &&
                                                        Object.keys(
                                                            participants,
                                                        ).length ===
                                                            participantsSelected.length
                                                    }
                                                    onChange={onSelectAll}
                                                    classes={{
                                                        checked:
                                                            styles.checkedBox,
                                                        indeterminate:
                                                            styles.checkedBox,
                                                    }}
                                                    color="primary"
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                First name
                                            </TableCell>
                                            <TableCell align="left">
                                                Last name
                                            </TableCell>
                                            <TableCell align="center">
                                                Registered
                                            </TableCell>
                                            <TableCell align="center">
                                                Scan status
                                            </TableCell>
                                            <TableCell align="center">
                                                Report
                                            </TableCell>
                                            <TableCell align="center">
                                                Completion date
                                            </TableCell>
                                            <TableCell align="right">
                                                More info
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {areNoParticipants &&
                                            (!participantsToAdd ||
                                                participantsToAdd.length ===
                                                    0) && (
                                                <TableRow>
                                                    <TableCell
                                                        align="left"
                                                        colSpan={9}>
                                                        <div
                                                            className={
                                                                styles.no_candidates_yet
                                                            }>
                                                            <em>
                                                                There are no
                                                                candidates yet.
                                                            </em>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        {updatingParticipants &&
                                            participantsToAdd.length > 0 &&
                                            participantsToAdd.map(
                                                (participant, index) => {
                                                    return (
                                                        <TableRow
                                                            key={`row-user-${participant.first_name}-${index}`}>
                                                            <TableCell
                                                                align="left"
                                                                colSpan={9}>
                                                                <Skeleton
                                                                    className="skeleton"
                                                                    height={40}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                },
                                            )}
                                        {!isUsersInfoLoading &&
                                            Object.keys(participants).length >
                                                0 &&
                                            !!data &&
                                            Object.keys(participants).map(
                                                (participant) => {
                                                    const participantObject =
                                                        participants[
                                                            participant
                                                        ];

                                                    const invitationId =
                                                        data.participants.filter(
                                                            (
                                                                par: TargetGroupParticipant,
                                                            ) =>
                                                                par.user_id ===
                                                                participantObject.id,
                                                        )[0]?.invitation_id ||
                                                        '';

                                                    const isBeingRemoved =
                                                        participantsToRemove &&
                                                        participantsToRemove.length !==
                                                            0
                                                            ? participantsToRemove.some(
                                                                  (
                                                                      p: TargetGroupParticipant,
                                                                  ) =>
                                                                      p.user_id ===
                                                                      participantObject.id,
                                                              )
                                                            : false;

                                                    const isResetScanLoading =
                                                        participantsSelected &&
                                                        participantsSelected.length ===
                                                            1 &&
                                                        resetScanLoading
                                                            ? participantsSelected.some(
                                                                  (
                                                                      p: Participant,
                                                                  ) =>
                                                                      p.id ===
                                                                      participantObject.id,
                                                              )
                                                            : false;
                                                    if (participantObject.id) {
                                                        return (
                                                            <ParticipantRow
                                                                key={`participant-row_${participantObject.id}`}
                                                                participant={
                                                                    participantObject
                                                                }
                                                                scan={scan}
                                                                groupId={
                                                                    targetGroupId
                                                                }
                                                                participantsSelected={
                                                                    participantsSelected
                                                                }
                                                                usersShowingInfo={
                                                                    usersShowingInfo
                                                                }
                                                                isBeingRemoved={
                                                                    isBeingRemoved
                                                                }
                                                                isResetScanLoading={
                                                                    isResetScanLoading
                                                                }
                                                                onUserShowInfoChange={
                                                                    onUserShowInfoChange
                                                                }
                                                                invitationId={
                                                                    invitationId
                                                                }
                                                                isUsersDetailsLoading={
                                                                    isUsersDetailsLoading
                                                                }
                                                                updatingParticipants={
                                                                    updatingParticipants
                                                                }
                                                                isSuperAdmin={
                                                                    isSuperAdmin
                                                                }
                                                                onSelect={
                                                                    onSelect
                                                                }
                                                            />
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                },
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </ThemeProvider>

                        {Object.keys(participants).length >=
                            candidatesPerPage && (
                            <div className={styles.line_break} />
                        )}
                    </div>
                    <TargetGroupTableFooter
                        isUsersDetailsLoading={isUsersDetailsLoading}
                        resetScanLoading={resetScanLoading}
                        updatingParticipants={updatingParticipants}
                        handleChangePage={handleChangePage}
                        candidateCurrentPage={candidateCurrentPage}
                        candidateTotalPages={candidateTotalPages}
                        isSuperAdmin={isSuperAdmin}
                        participantsSelected={participantsSelected}
                        // onSendInvitationEvent={() => props.onSendInvitationEvent()}
                        onSendResetPasswordEvent={onSendResetPasswordEvent}
                        onSendReminderEvent={onSendReminderEvent}
                        onSendReportEvent={onSendReportEvent}
                        onRemoveCandidateEvent={onRemoveCandidateEvent}
                        onFullDeleteCandidateEvent={onFullDeleteCandidateEvent}
                        onResetCandidateScanEvent={onResetCandidateScanEvent}
                    />
                </div>
            )}
        </React.Fragment>
    );
};
const mapStateToProps = (state: { assessments: any; targetGroups: any }) => ({
    assessments: state.assessments,
    targetGroups: state.targetGroups,
});
export default connect(mapStateToProps)(TargetGroupTable);
