import { Participant } from '../../typings/user';
import { ErrorMessage } from '../../typings/error';
import { getErrorMessageByCode } from './errorGlossary';

export const findLatestError = (
    participant: Participant,
): ErrorMessage | undefined => {
    const statuses = participant.status
        ? Object.entries(participant.status)
        : [];
    const latestStatusEntry = statuses.sort(
        ([date1], [date2]) =>
            new Date(date2).getTime() - new Date(date1).getTime(),
    )[0];

    if (!latestStatusEntry) {
        return undefined;
    }

    const [, latestErrorCode] = latestStatusEntry;
    const errorMessages = getErrorMessageByCode(latestErrorCode);

    if (!errorMessages) {
        return undefined;
    }

    return errorMessages;
};
