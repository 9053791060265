import i18next, { i18n as I18n } from 'i18next';
import { useTranslation as i18nUseTranslation } from 'react-i18next';
import en from './translations/en.json';
import nl from './translations/nl.json';

const resources = {
    'en-US': {
        translation: en,
    },
    'nl-NL': {
        translation: nl,
    },
};

export function i18nFromLocale(): I18n {
    const LANGUAGE = 'nl-NL';

    return i18next.createInstance(
        {
            lng: LANGUAGE,
            fallbackLng: 'en-US',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            resources,
        },
        () => {},
    );
}

const i18n = i18nFromLocale();
document.documentElement.lang = i18n.language.substring(0, 2);
i18n.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng.substring(0, 2));
});

export default i18n;

/**
 * Wraps useTranslation() from react-i18next that abstracts the default namespace.
 * @returns see https://react.i18next.com/latest/usetranslation-hook
 */
export function useTranslation() {
    return i18nUseTranslation(undefined, { i18n });
}

// i18n.use(initReactI18next).init({
//   lng: 'nl-NL',
//   fallbackLng: 'en-US',
//   interpolation: {
//     escapeValue: false // not needed for react as it escapes by default
//   },
//   resources
// });

// export { i18n };
