import React, {useState, useEffect} from 'react';
import {
    TabNavigationWrapper,
    Tabs,
    TabsItemWrapper,
    Item,
    ItemLink,
} from './Style';
import {
    ResultsSectionProps,
    ResultsTab,
    SectionId,
    TabId
} from "../../../../typings";

export const ResultsNavBar = (props: ResultsSectionProps) => {
    const { sections, activeSection } = props;

    const [activeTab, setActiveTab] = useState<TabId>();

    useEffect(() => {
        setActiveTab(sections[activeSection]?.tabs[0].id);
    }, [activeSection, sections]);

    const definedActiveTab =
        activeTab ||
        sections[Object.keys(sections)[0] as SectionId]?.tabs[0].id;

    const navBarsToRender = [];
    for (const id in sections) {
        const sectionId = id as SectionId;
        navBarsToRender.push(
            <TabNavigationWrapper
                active={sectionId === activeSection}
                key={sectionId}>
                <Tabs>
                    {sections[sectionId]?.tabs.map(
                        (tab: ResultsTab, i: number) => {
                            return (
                                <TabsItemWrapper
                                    active={tab.id === definedActiveTab}
                                    key={i}>
                                    <Item>
                                        <ItemLink
                                            activeTab={
                                                tab.id === definedActiveTab
                                            }
                                            href={`#${tab.id}`}
                                            onClick={(e) =>
                                                onTabClick(e, tab.id)
                                            }>
                                            {tab.title}
                                        </ItemLink>
                                    </Item>
                                </TabsItemWrapper>
                            );
                        },
                    )}
                </Tabs>
            </TabNavigationWrapper>,
        );
    }

    const onTabClick = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        tabId: TabId,
    ) => {
        e.preventDefault();
        e.stopPropagation();

        const tabContent = document.getElementById(tabId);
        if (tabContent) {
            setActiveTab(tabId);
            tabContent.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return <>{navBarsToRender}</>;
};
