import { CreateDashboardUserSchema } from 'typings/dashboard_user';

export const actionsTypes = {
  CREATE_DASHBOARD_USER: 'DASHBOARD_USERS/CREATE_DASHBOARD_USER',
  CREATE_DASHBOARD_USER_SUCCESS: 'DASHBOARD_USERS/CREATE_DASHBOARD_USER_SUCCESS',
  CREATE_DASHBOARD_USER_FAILURE: 'DASHBOARD_USERS/CREATE_DASHBOARD_USER_FAILURE',
  GET_ALL_COMPANY_DASHBOARD_USERS: 'DASHBOARD_USERS/GET_ALL_COMPANY_DASHBOARD_USERS',
  GET_ALL_COMPANY_DASHBOARD_USERS_SUCCESS: 'DASHBOARD_USERS/GET_ALL_COMPANY_DASHBOARD_USERS_SUCCESS',
  GET_ALL_COMPANY_DASHBOARD_USERS_FAILURE: 'DASHBOARD_USERS/GET_ALL_COMPANY_DASHBOARD_USERS_FAILURE',
  REMOVE_RECRUITERS: 'DASHBOARD_USERS/REMOVE_RECRUITERS',
  REMOVE_RECRUITERS_SUCCESS: 'DASHBOARD_USERS/REMOVE_RECRUITERS_SUCCESS',
  REMOVE_RECRUITERS_FAILURE: 'DASHBOARD_USERS/REMOVE_RECRUITERS_FAILURE',
};

export const actions = {
  createDashboardUser: (companyId: string, dashboardUser: CreateDashboardUserSchema) => ({
    type: actionsTypes.CREATE_DASHBOARD_USER,
    payload: { companyId, dashboardUser },
  }),
  getAllDashboardUsers: (companyId: string) => ({
    type: actionsTypes.GET_ALL_COMPANY_DASHBOARD_USERS,
    payload: { companyId },
  }),
  deleteRecruiterUsers: (companyId: string, userIds: string[]) => ({
    type: actionsTypes.REMOVE_RECRUITERS,
    payload: { companyId, userIds },
  }),
};
