import { call, put } from 'redux-saga/effects';
import { actionsTypes, actions } from './users.actions';
import NeurolyticsAPI from '../../services/NeurolyticsAPI';
import { User } from 'typings/user';
import { UserInvitation } from '../../typings/target_group';

export function* getTargetGroupUsers(action: { type: string; payload: any }) {
    try {
        const { assessmentId, targetGroupId, page, candidatesPerPage } =
            action.payload;

        const { response } = yield call(
            NeurolyticsAPI.getTargetGroupUsers,
            assessmentId,
            targetGroupId,
        );
        const firstIndex = page * candidatesPerPage;
        const lastIndex = firstIndex + candidatesPerPage;
        const usersPerPage = response.slice(firstIndex, lastIndex);
        if (usersPerPage) {
            yield put(
                actions.getAllUsersInvitationsInGroup(
                    targetGroupId,
                    usersPerPage,
                ),
            );
        }
        yield put({
            type: actionsTypes.GET_TARGET_GROUP_USERS_SUCCESS,
            payload: { assessmentId, targetGroupId, users: usersPerPage, page },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_TARGET_GROUP_USERS_FAILURE,
            payload: { error },
        });
    }
}
export function* getAllUsersInvitationsInGroup(action: {
    type: string;
    payload: any;
}) {
    try {
        const { targetGroupId, users } = action.payload;

        const response: UserInvitation = yield call(
            [NeurolyticsAPI, 'getAllInvitationsInGroupApi'],
            targetGroupId,
        );
        yield put({
            type: actionsTypes.GET_ALL_USERS_INVITATIONS_IN_GROUP_SUCCESS,
            payload: { targetGroupId, users: users, invitations: response },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_ALL_USERS_INVITATIONS_IN_GROUP_FAILURE,
            payload: { error },
        });
    }
}
export function* getCandidate(action: { type: string; payload: any }) {
    try {
        const { candidateId, targetGroupId } = action.payload;

        const { response } = yield call(
            [NeurolyticsAPI, 'fetchCandidate'],
            candidateId,
        );
        yield put({
            type: actionsTypes.GET_CANDIDATE_SUCCESS,
            payload: { users: [response], targetGroupId },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_CANDIDATE_FAILURE,
            payload: { error },
        });
    }
}

export function* getTargetGroupUsersSuccess(action: {
    type: string;
    payload: any;
}) {
    const { targetGroupId, users }: { targetGroupId: string; users: User[] } =
        action.payload;
    for (let user of users) {
        yield put(actions.getUserActivity(targetGroupId, user.id, false));
        yield put(actions.getUserEmailInfo(targetGroupId, user.id));
    }
}

export function* getUserActivity(action: {
    type: string;
    payload: {
        targetGroupId: string;
        userId: string;
        includeReportUrl: boolean;
    };
}) {
    try {
        const { targetGroupId, userId, includeReportUrl } = action.payload;
        const { response } = yield call(
            [NeurolyticsAPI, 'getUserActivityApi'],
            targetGroupId,
            userId,
            includeReportUrl,
        );
        yield put({
            type: actionsTypes.GET_USER_ACTIVITY_SUCCESS,
            payload: { targetGroupId, userId, activity: response },
        });
    } catch (error) {
        const { targetGroupId, userId } = action.payload;
        yield put({
            type: actionsTypes.GET_USER_ACTIVITY_FAILURE,
            payload: { targetGroupId, userId, error },
        });
    }
}

export function* getUserEmailInfo(action: { type: string; payload: any }) {
    try {
        const { targetGroupId, userId } = action.payload;
        const { response } = yield call(
            NeurolyticsAPI.getUserEmailInfo,
            targetGroupId,
            userId,
        );

        yield put({
            type: actionsTypes.GET_USER_EMAIL_INFO_SUCCESS,
            payload: { targetGroupId, userId, emails: response },
        });
    } catch (error) {
        const { targetGroupId, userId } = action.payload;
        yield put({
            type: actionsTypes.GET_USER_EMAIL_INFO_FAILURE,
            payload: { targetGroupId, userId, error },
        });
    }
}

export function* convertUserVideos(action: { type: string; payload: any }) {
    try {
        const { targetGroupId, userId } = action.payload;
        yield call(NeurolyticsAPI.convertUserVideos, targetGroupId, userId);
        yield put({
            type: actionsTypes.CONVERT_USER_VIDEOS_SUCCESS,
            payload: { targetGroupId, userId },
        });
    } catch (error) {
        const { targetGroupId, userId } = action.payload;
        yield put({
            type: actionsTypes.CONVERT_USER_VIDEOS_FAILURE,
            payload: { targetGroupId, userId, error },
        });
    }
}

export function* sendEmail(action: { type: string; payload: any }) {
    const { assessmentId, targetGroupId, userId, email } = action.payload;

    try {
        let response;
        if (email === 'reminder') {
            // @ts-ignore
            response = yield call(
                NeurolyticsAPI.sendReminderEmails,
                assessmentId,
                targetGroupId,
                userId,
            );
        } else if (email === 'report') {
            // @ts-ignore
            response = yield call(
                NeurolyticsAPI.sendReportEmails,
                assessmentId,
                targetGroupId,
                userId,
            );
        } else if (email === 'resetPassword') {
            // @ts-ignore
            response = yield call(
                NeurolyticsAPI.sendResetPasswordEmails,
                userId,
            );
        } else {
            yield put({
                type: actionsTypes.SEND_EMAIL_FAILURE,
                payload: { error: new Error('Missing email type') },
            });
        }
        yield put({
            type: actionsTypes.SEND_EMAIL_SUCCESS,
            payload: { targetGroupId, userId, email, response },
        });

        yield put(actions.getUserEmailInfo(targetGroupId, userId));
    } catch (error) {
        yield put({
            type: actionsTypes.SEND_EMAIL_FAILURE,
            payload: { targetGroupId, userId, email, error },
        });
    }
}
