import { put, call } from 'redux-saga/effects';
import { authActionTypes } from './auth.actions';
import createAuth0Client, { Auth0Client, User } from '@auth0/auth0-spa-js';
import {
    AUTH0_AUDIENCE,
    AUTH0_CLIENT_ID,
    AUTH0_DOMAIN,
} from 'utils/environment-variables';
import { setAuth0Client, auth0Client } from 'Auth';

export function* initAuth0() {
    try {
        if (!auth0Client) {
            const domain = AUTH0_DOMAIN;
            const clientId = AUTH0_CLIENT_ID;
            const audience = AUTH0_AUDIENCE;

            if (!domain || !clientId) {
                yield put({
                    type: authActionTypes.INIT_AUTH0_FAILURE,
                    payload: { error: Error('Missing credentials') },
                });
                return;
            }

            const auth0: Auth0Client = yield createAuth0Client({
                domain: domain,
                client_id: clientId,
                audience: audience,
            });
            yield call(setAuth0Client, auth0);
        }
        yield put({
            type: authActionTypes.INIT_AUTH0_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: authActionTypes.INIT_AUTH0_FAILURE,
            payload: { error },
        });
    }
}

export function* initLogin(action: { type: string; payload: any }) {
    if (auth0Client) {
        const { mode, returnUrl } = action.payload;

        const loginOptions: {
            redirect_uri?: string;
            appState?: object;
            action?: string;
        } = {
            redirect_uri: window.location.origin,
        };

        if (returnUrl) {
            loginOptions.appState = { return_url: returnUrl };
        }

        if (mode) {
            loginOptions.action = mode;
        }

        yield auth0Client.loginWithRedirect(loginOptions);
    }
}

export function* storeUser() {
    try {
        if (auth0Client) {
            const isAuthenticated: boolean =
                yield auth0Client.isAuthenticated();
            if (isAuthenticated) {
                const user: User | undefined = yield auth0Client.getUser();
                const namespace = 'https://neurolytics.ai/';

                if (!user) {
                    yield put({
                        type: authActionTypes.STORE_USER_FAILURE,
                        payload: { error: Error('User not initialized') },
                    });
                    return;
                }

                if (user[`${namespace}id`]) {
                    user.id = user[`${namespace}id`];
                    delete user[`${namespace}id`];
                }
                if (user[`${namespace}roles`]) {
                    user.roles = user[`${namespace}roles`];
                    delete user[`${namespace}roles`];
                }
                if (user[`${namespace}permissions`]) {
                    user.permissions = user[`${namespace}permissions`];
                    delete user[`${namespace}permissions`];
                }
                if (user[`${namespace}target_group_permissions`]) {
                    user.target_group_permissions =
                        user[`${namespace}target_group_permissions`];
                    delete user[`${namespace}target_group_permissions`];
                }
                if (user[`${namespace}company_id`]) {
                    user.company_id = user[`${namespace}company_id`];
                    delete user[`${namespace}company_id`];
                }

                if (user[`${namespace}scans`]) {
                    user.scans = user[`${namespace}scans`];
                    delete user[`${namespace}scans`];
                }

                yield put({
                    type: authActionTypes.STORE_USER_SUCCESS,
                    payload: { user },
                });
            }
        }
    } catch (error) {
        yield put({
            type: authActionTypes.STORE_USER_FAILURE,
            payload: { error },
        });
    }
}
