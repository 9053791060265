import React, { FunctionComponent, useEffect } from 'react';
import {
    CandidateInfoTitle,
    CandidateInfoTitleSection,
    CloseIcon,
    CandidateInfoModalWrapper,
    SectionWrapper,
    DetailWrapper,
    Detail,
    Status,
    CandidateInfoSection,
} from './Styles';
import { CrossIcon } from 'evergreen-ui';
import { Participant } from '../../../../typings/user';
import { getLastCompletedSession } from '../TargetGroupTable';
import {
    formatDate,
    ISODateToString,
    msToMinutesString,
} from '../../../../utils/time';
import { getParticipantReport } from '../ParticipantRow';
import { participantHasErrors } from '../../utils';
import { findLatestError } from '../../../../utils/ErrorHandling/findLatestError';

interface ICandidateInfoModal {
    closeCandidateDetailsModal: () => void;
    participant: Participant;
    invitationId: string;
    isSuperAdmin: boolean;
    areAllModulesScored: boolean;
}
interface IActivityStatus {
    statusColor: 'red' | 'yellow' | 'green' | 'gray';
    label: string;
}

const ActivityStatus = (props: IActivityStatus) => {
    return (
        <Status>
            {props.statusColor === 'green' && <div className={'green'} />}
            {props.statusColor === 'yellow' && <div className={'yellow'} />}
            {props.statusColor === 'red' && <div className={'red'} />}
            {props.statusColor === 'gray' && <div className={'gray'} />}
            <span>{props.label}</span>
        </Status>
    );
};
const CandidateInfoModal: FunctionComponent<ICandidateInfoModal> = (props) => {
    const {
        closeCandidateDetailsModal,
        participant,
        invitationId,
        isSuperAdmin,
        areAllModulesScored,
    } = props;

    const { activity } = participant;

    useEffect(() => {
        const close = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                closeCandidateDetailsModal();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, [closeCandidateDetailsModal]);

    const participantReport = getParticipantReport(participant);

    const lastCompletedSession = activity
        ? getLastCompletedSession(activity)
        : null;

    const session = getLastCompletedSession(activity);

    const hasErrorStatus = participantHasErrors(
        participant.status ? participant.status : {},
    );

    const { files, analysis } = activity || {};

    const analysisStatus = analysis?.status;

    const latestErrorMessage = findLatestError(participant);

    return (
        <CandidateInfoModalWrapper
            className={'candidate-info-pop-up'}
            onClick={closeCandidateDetailsModal}>
            <CandidateInfoSection onClick={(e) => e.stopPropagation()}>
                <CloseIcon>
                    <CrossIcon
                        style={{ cursor: 'pointer' }}
                        onClick={closeCandidateDetailsModal}
                        size={24}
                    />
                </CloseIcon>
                <CandidateInfoTitleSection className={'main-section'}>
                    <CandidateInfoTitle className={'main'}>
                        Scan and {isSuperAdmin ? 'analysis' : 'candidate'}{' '}
                        details
                    </CandidateInfoTitle>
                </CandidateInfoTitleSection>
                <SectionWrapper className={'main-section'}>
                    <DetailWrapper>
                        <Detail className={'name'}>Name:</Detail>
                        <Detail className={'status-wrapper'}>
                            {participant.first_name} {participant.last_name}
                        </Detail>
                    </DetailWrapper>
                    <CandidateInfoTitleSection>
                        <CandidateInfoTitle className={'secondary-title'}>
                            Scan details
                        </CandidateInfoTitle>
                    </CandidateInfoTitleSection>
                    <DetailWrapper>
                        <Detail className={'name status-name'}>Status:</Detail>
                        <Detail className={'status-desc-wrapper'}>
                            <Detail className={'status-wrapper'}>
                                {!!participantReport &&
                                !!participantReport.created_at &&
                                areAllModulesScored ? (
                                    <span
                                        className={'status'}
                                        style={{
                                            backgroundColor: '#1AA277',
                                        }}>
                                        Completed
                                    </span>
                                ) : !!participantReport &&
                                  !!participantReport.created_at &&
                                  !areAllModulesScored ? (
                                    <span
                                        className={'status'}
                                        style={{
                                            backgroundColor: '#1AA277',
                                        }}>
                                        Partially completed
                                    </span>
                                ) : !!lastCompletedSession &&
                                  (!participantReport ||
                                      !participantReport.created_at) &&
                                  analysisStatus === 'pending' &&
                                  !hasErrorStatus ? (
                                    <span
                                        className={'status'}
                                        style={{
                                            background: 'rgba(95, 145, 204, 1)',
                                        }}>
                                        Analysing
                                    </span>
                                ) : !!lastCompletedSession &&
                                  (!participantReport ||
                                      !participantReport.created_at) &&
                                  analysisStatus === 'pending' &&
                                  hasErrorStatus ? (
                                    <span
                                        className={'status'}
                                        style={{
                                            backgroundColor: '#F6510E',
                                        }}>
                                        Failed
                                    </span>
                                ) : (
                                    <span
                                        className={'status'}
                                        style={{
                                            backgroundColor: '#BBC9D8',
                                        }}>
                                        Not started
                                    </span>
                                )}
                            </Detail>
                            {!!lastCompletedSession &&
                                (!participantReport ||
                                    !participantReport.created_at) &&
                                analysisStatus === 'pending' &&
                                hasErrorStatus && (
                                    <Detail className={'status-wrapper desc'}>
                                        {!!latestErrorMessage && isSuperAdmin
                                            ? latestErrorMessage.adminMessage
                                            : !!latestErrorMessage &&
                                              !isSuperAdmin
                                            ? latestErrorMessage.clientMessage
                                            : 'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.'}
                                    </Detail>
                                )}
                        </Detail>
                    </DetailWrapper>
                    {isSuperAdmin && (
                        <>
                            <CandidateInfoTitleSection>
                                <CandidateInfoTitle
                                    className={'secondary-title'}>
                                    File status
                                </CandidateInfoTitle>
                            </CandidateInfoTitleSection>
                            <DetailWrapper>
                                <Detail className={'name'}>
                                    Questionnaires uploaded:
                                </Detail>
                                <Detail>
                                    {files?.uploaded_json === 'all' && (
                                        <ActivityStatus
                                            statusColor="green"
                                            label="Successfull"
                                        />
                                    )}
                                    {files?.uploaded_json === 'some' && (
                                        <ActivityStatus
                                            statusColor="yellow"
                                            label="Some"
                                        />
                                    )}
                                    {files?.uploaded_json === 'none' && (
                                        <ActivityStatus
                                            statusColor="gray"
                                            label="None"
                                        />
                                    )}
                                </Detail>
                            </DetailWrapper>
                            <DetailWrapper>
                                <Detail className={'name'}>
                                    Videos uploaded:
                                </Detail>
                                <Detail>
                                    {files?.uploaded_videos === 'all' && (
                                        <ActivityStatus
                                            statusColor="green"
                                            label="Successfull"
                                        />
                                    )}
                                    {files?.uploaded_videos === 'some' && (
                                        <ActivityStatus
                                            statusColor="yellow"
                                            label="Some"
                                        />
                                    )}
                                    {files?.uploaded_videos === 'none' && (
                                        <ActivityStatus
                                            statusColor="gray"
                                            label="None"
                                        />
                                    )}
                                </Detail>
                            </DetailWrapper>
                            <DetailWrapper>
                                <Detail className={'name'}>
                                    Videos converted:
                                </Detail>
                                <Detail>
                                    {files?.videos_converted === 'all' && (
                                        <ActivityStatus
                                            statusColor="green"
                                            label="Successfull"
                                        />
                                    )}
                                    {files?.videos_converted === 'some' && (
                                        <ActivityStatus
                                            statusColor="yellow"
                                            label="Some"
                                        />
                                    )}
                                    {files?.videos_converted === 'none' && (
                                        <ActivityStatus
                                            statusColor="gray"
                                            label="None"
                                        />
                                    )}
                                </Detail>
                            </DetailWrapper>
                        </>
                    )}
                    {isSuperAdmin && (
                        <>
                            <CandidateInfoTitleSection>
                                <CandidateInfoTitle
                                    className={'secondary-title'}>
                                    Session
                                </CandidateInfoTitle>
                            </CandidateInfoTitleSection>
                            <DetailWrapper>
                                <Detail className={'name'}>Browser:</Detail>
                                <Detail>
                                    {!!session
                                        ? session.browser.name +
                                          ' ' +
                                          session.browser.version
                                        : '-'}
                                </Detail>
                            </DetailWrapper>
                            <DetailWrapper>
                                <Detail className={'name'}>OS:</Detail>
                                <Detail>
                                    {!!session ? session.device.os.name : '-'}
                                </Detail>
                            </DetailWrapper>
                        </>
                    )}
                    <DetailWrapper>
                        <Detail className={'name'}>Log-in at:</Detail>
                        <Detail>
                            {!!session && !!session.loggedInAt
                                ? ISODateToString(session.loggedInAt)
                                : '-'}
                        </Detail>
                    </DetailWrapper>
                    <DetailWrapper>
                        <Detail className={'name'}>Finished at:</Detail>
                        <Detail>
                            {!!session && !!session.finishedAt
                                ? ISODateToString(session.finishedAt)
                                : '-'}
                        </Detail>
                    </DetailWrapper>
                    <DetailWrapper>
                        <Detail className={'name'}>Total time:</Detail>
                        <Detail>
                            {!!session && !!session.totalTime
                                ? msToMinutesString(session.totalTime)
                                : '-'}
                        </Detail>
                    </DetailWrapper>
                </SectionWrapper>
                <hr />
                <SectionWrapper className={'info'}>
                    <CandidateInfoTitleSection>
                        <CandidateInfoTitle className={'secondary-title'}>
                            Candidate details
                        </CandidateInfoTitle>
                    </CandidateInfoTitleSection>
                    <DetailWrapper>
                        <Detail className={'name'}>Type:</Detail>
                        <Detail>
                            Registered on:{' '}
                            {formatDate(
                                participant.invitation_created_at
                                    ? participant.invitation_created_at
                                    : participant.created_at,
                            )}
                        </Detail>
                    </DetailWrapper>
                    <DetailWrapper>
                        <Detail className={'name'}>E-mail:</Detail>
                        <Detail>
                            {!!participant ? participant.email : '-'}
                        </Detail>
                    </DetailWrapper>
                    <DetailWrapper>
                        <Detail className={'name'}>Candidate ID:</Detail>
                        <Detail>{!!participant ? participant.id : '-'}</Detail>
                    </DetailWrapper>
                    {isSuperAdmin && (
                        <DetailWrapper>
                            <Detail className={'name'}>Invitation ID:</Detail>
                            <Detail>{invitationId}</Detail>
                        </DetailWrapper>
                    )}
                </SectionWrapper>
            </CandidateInfoSection>
        </CandidateInfoModalWrapper>
    );
};
export default CandidateInfoModal;
