import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import { reducer, initialState } from './reducers';

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const setupStore = (preloadedState : any) => {
  return configureStore({
    reducer: reducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          serializableCheck: false
        }).concat(middleware),
    devTools: true,
    preloadedState
  })
}

export const store = setupStore(initialState);

export const runSagaMiddleware = () => {
  sagaMiddleware.run(rootSaga);
};

runSagaMiddleware();


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;

