import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import styles from './ScanSetupModals.module.css';
import DialogComponent from 'components/Dialog/Dialog';
import { Select } from '../ScanStepsContent/SecondStep/style';
import { ConfirmIcon } from 'evergreen-ui';
import { Input } from '../ScanStepsContent/FirstStep/style';

interface ScanSetupModalsProps {
    shouldShowChangeLanguageModal: boolean;
    shouldShowChangeTargetGroupNameModal: boolean;
    shouldShowChangeDescriptionModal: boolean;
    shouldShowChangeAssessmentNameModal: boolean;
    language: string;
    targetGroupName: string;
    description: string;
    assessmentName: string;
    setLanguage: Dispatch<SetStateAction<string>>;
    setTargetGroupName: Dispatch<SetStateAction<string>>;
    setDescription: Dispatch<SetStateAction<string>>;
    setAssessmentName: Dispatch<SetStateAction<string>>;
    onDiscardModal: () => void;
    changeConfirmed: () => void;
}

const ScanSetupModals: FunctionComponent<ScanSetupModalsProps> = (
    props: ScanSetupModalsProps,
) => {
    const {
        shouldShowChangeLanguageModal,
        shouldShowChangeTargetGroupNameModal,
        shouldShowChangeDescriptionModal,
        shouldShowChangeAssessmentNameModal,
        language,
        targetGroupName,
        description,
        assessmentName,
        setLanguage,
        setTargetGroupName,
        setDescription,
        setAssessmentName,
        onDiscardModal,
        changeConfirmed,
    } = props;

    return (
        <>
            {shouldShowChangeLanguageModal && (
                <DialogComponent
                    title="Change scan language settings"
                    confirmButtonText="Change"
                    closeButtonText="Never mind"
                    isShown={shouldShowChangeLanguageModal}
                    onClose={onDiscardModal}
                    titleBackgroundColor={'white'}
                    onConfirm={changeConfirmed}
                    confirmIcon={ConfirmIcon}>
                    <div className={styles.dialog__content}>
                        Select scan language for your candidate group.
                    </div>
                    <Select
                        className="modal-select"
                        value={language}
                        onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>,
                        ) => setLanguage(event.target.value)}>
                        <option value="nl-NL">Dutch</option>
                        <option value="en-US">English</option>
                    </Select>
                </DialogComponent>
            )}
            {shouldShowChangeTargetGroupNameModal && (
                <DialogComponent
                    title="Change group name settings"
                    confirmButtonText="Change"
                    closeButtonText="Never mind"
                    isShown={shouldShowChangeTargetGroupNameModal}
                    onClose={onDiscardModal}
                    titleBackgroundColor={'white'}
                    onConfirm={changeConfirmed}
                    isConfirmButtonDisabled={!targetGroupName}
                    confirmIcon={ConfirmIcon}>
                    <div className={styles.dialog__content}>
                        <p>Give your group a name.</p>
                        <strong>Note: Group names are visible to candidates.</strong>
                    </div>
                    <Input
                        value={targetGroupName}
                        onChange={(event) =>
                            setTargetGroupName(event.target.value)
                        }
                    />
                    {!targetGroupName && (
                        <div style={{ color: 'red', paddingTop: '8px' }}>
                            This field can NOT be empty.
                        </div>
                    )}
                </DialogComponent>
            )}
            {shouldShowChangeDescriptionModal && (
                <DialogComponent
                    title="Change group description settings"
                    confirmButtonText="Change"
                    closeButtonText="Never mind"
                    isShown={shouldShowChangeDescriptionModal}
                    onClose={onDiscardModal}
                    titleBackgroundColor={'white'}
                    onConfirm={changeConfirmed}
                    confirmIcon={ConfirmIcon}>
                    <div className={styles.dialog__content}>
                        <p>
                            Give a more in-depth description of the
                            candidates in this group.
                        </p>
                    </div>
                    <Input
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                    />
                </DialogComponent>
            )}
            {shouldShowChangeAssessmentNameModal && (
                <DialogComponent
                    title="Change scan name settings"
                    confirmButtonText="Change"
                    closeButtonText="Never mind"
                    isShown={shouldShowChangeAssessmentNameModal}
                    onClose={onDiscardModal}
                    titleBackgroundColor={'white'}
                    onConfirm={changeConfirmed}
                    confirmIcon={ConfirmIcon}
                    isConfirmButtonDisabled={!assessmentName}>
                    <div className={styles.dialog__content}>
                        <p>Give your scan a name.</p>
                    </div>
                    <Input
                        value={assessmentName}
                        onChange={(event) =>
                            setAssessmentName(event.target.value)
                        }
                    />
                    {!assessmentName && (
                        <div style={{ color: 'red', paddingTop: '8px' }}>
                            This field can NOT be empty.
                        </div>
                    )}
                </DialogComponent>
            )}
        </>
    );
};

export default ScanSetupModals;
