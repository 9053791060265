import React, { useState, useEffect, FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styles from './ManageUsersView.module.css';
import UsersTable from 'views/ManageUsersView/UsersTable/UsersTable';
import AddUsersForm from 'views/ManageUsersView/AddUsersForm/AddUsersForm';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import { AddIcon, UserIcon, ErrorIcon } from 'evergreen-ui';
import AppButton from 'components/AppButton/AppButton';
import { actions as dashboardUsersActions } from 'store/dashboardUsers/dashboardUsers.actions';
import { selectCompaniesState } from 'store/companies/companies.selectors';
import { Company } from 'store/companies/companies.reducer';
import { AuthenticatedUserProps } from '../../hoc/WithAuthLayout';
import ManageUsersModals from './ManageUsersModals/ManageUsersModals';
import { DashboardUser } from '../../typings/dashboard_user';
import { selectDashboardUsersState } from '../../store/dashboardUsers/dashboardUsers.selectors';

interface ManageUsersViewProps extends AuthenticatedUserProps {}

const ManageUsersView: FunctionComponent<ManageUsersViewProps> = (props) => {
    const { userRoles } = props;
    const isSuperAdmin = userRoles.includes('super-admin');
    const hasCompanyAdminRole = userRoles.includes('company-admin');
    const [addingUser, setAddingUser] = useState<boolean>(false);
    const { companySlug } = useParams();
    const dispatch = useDispatch();
    const { dashboardUsers, loading } = useSelector(selectDashboardUsersState);
    const { companies } = useSelector(selectCompaniesState);

    const currentCompany = companies.find(
        (company: Company) => company.slug === companySlug,
    );
    const companyUsers =
        currentCompany?.id && dashboardUsers[currentCompany.id]
            ? dashboardUsers[currentCompany.id]
            : [];

    const [showDeleteRecruiterModal, setShowDeleteRecruiterModal] =
        useState<boolean>(false);

    const [selectedUsers, setSelectedUsers] = useState<DashboardUser[]>([]);

    const renderHeader = () => {
        return (
            <div className={styles.manage_users_header}>
                <div className={styles.icon_container}>
                    <UserIcon size={33} />
                </div>
                <h1>{addingUser ? 'Add user' : 'Manage team'}</h1>
            </div>
        );
    };

    const getUser = (userId: string): DashboardUser | null => {
        return (
            companyUsers.find(
                (user) => user.app_metadata.nl_user_id === userId,
            ) || null
        );
    };

    const onUserSelectChange = (
        user: DashboardUser,
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (user.app_metadata && user.app_metadata.nl_user_id) {
            const userId = user.app_metadata.nl_user_id;
            const selected = [...selectedUsers];
            if (event.target.checked) {
                const user = getUser(userId);
                if (user) {
                    selected.push(user);
                    setSelectedUsers(selected);
                }
            } else if (!event.target.checked) {
                const notSelected = selected.filter(
                    (user) => user.app_metadata.nl_user_id !== userId,
                );
                setSelectedUsers(notSelected);
            }
        }
    };

    const onUserSelectChangeAll = () => {
        if (companyUsers.length !== selectedUsers.length) {
            setSelectedUsers(companyUsers);
        } else {
            setSelectedUsers([]);
        }
    };

    const handleDeleteRecruiterUser = () => {
        if (currentCompany && selectedUsers.length > 0) {
            const selectedUserIds: string[] = selectedUsers.map(
                (user) => user.app_metadata.nl_user_id,
            );
            dispatch(
                dashboardUsersActions.deleteRecruiterUsers(
                    currentCompany.id,
                    selectedUserIds,
                ),
            );
        }
        const newSelectedUsers = [...selectedUsers].filter((selected) => {
            return !companyUsers.find(
                (user) =>
                    user.app_metadata.nl_user_id ===
                    selected.app_metadata.nl_user_id,
            );
        });
        setSelectedUsers(newSelectedUsers);
        discardModals();
    };

    const discardModals = () => {
        setShowDeleteRecruiterModal(false);
    };

    const renderTableInfoBox = () => {
        return (
            <div className={styles.users_info_box}>
                <div className={styles.users_info_box__info}>
                    <div className={styles.users_info_box__name}>
                        <Link
                            to={`/${
                                currentCompany ? currentCompany.slug : ''
                            }`}>
                            <h2>
                                {currentCompany
                                    ? `${currentCompany.name} team`
                                    : 'Your team'}
                            </h2>
                        </Link>
                    </div>
                </div>

                <div className={styles.users_info_box__add_user}>
                    <div className={styles.button}>
                        {!addingUser && (
                            <AppButton
                                icon={AddIcon}
                                onClick={() => setAddingUser(true)}>
                                Add new user
                            </AppButton>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (currentCompany) {
            dispatch(
                dashboardUsersActions.getAllDashboardUsers(currentCompany.id),
            );
        }
    }, [currentCompany, dispatch]);

    return (
        <>
            {(hasCompanyAdminRole || isSuperAdmin) && (
                <React.Fragment>
                    {renderHeader()}
                    {renderTableInfoBox()}
                    {currentCompany && !addingUser && (
                        <UsersTable
                            companyUsers={companyUsers}
                            selectedUsers={selectedUsers}
                            loading={loading}
                            isSuperAdmin={isSuperAdmin}
                            hasCompanyAdminRole={hasCompanyAdminRole}
                            onDeleteRecruiterUser={() => {
                                setShowDeleteRecruiterModal(true);
                            }}
                            onSelect={(
                                user: DashboardUser,
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => onUserSelectChange(user, event)}
                            onSelectAll={onUserSelectChangeAll}
                        />
                    )}
                    {currentCompany && addingUser && (
                        <AddUsersForm
                            cancelAddingUser={() => setAddingUser(false)}
                            companyId={currentCompany.id}
                        />
                    )}
                </React.Fragment>
            )}
            {!hasCompanyAdminRole && !isSuperAdmin && (
                <ErrorPage
                    icon={ErrorIcon}
                    iconColor={'danger'}
                    message="You do not have permission to view this page."
                />
            )}
            <ManageUsersModals
                selectedUsers={selectedUsers}
                showDeleteRecruiterModal={showDeleteRecruiterModal}
                handleDeleteRecruiterUser={handleDeleteRecruiterUser}
                onDiscardModal={() => discardModals()}
            />
        </>
    );
};

export default ManageUsersView;
