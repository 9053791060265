import styled from 'styled-components';
import * as variables from 'assets/styles/variables';

export const Container = styled.div`
  padding: 40px;
`;

export const HeaderBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 25px;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
`;

export const InfoTextLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 22px;
  margin-right: 25px;
`;

export const InfoTextRight = styled.div`
  justify-content: flex-end;
  border-left: solid 2px #435970;
  display: flex;
  flex-flow: column;
  padding-left: 25px;
`;

export const HeadingText = styled.div`
  padding-right: 25px;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  margin-bottom: 4px;
`;

export const SubHeadingText = styled.div`
  color: ${variables.tealblue};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
`;

export const SubHeadingIcon = styled.div`
  color: ${variables.tealblue};
  display: inline-block;
  margin-right: 5px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 25px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  margin: 20px 0;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #435970;
`;

export const Content = styled.div`
  margin: 25px;
`;
