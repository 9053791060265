import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { store } from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {APP_ENVIRONMENT} from 'utils/environment-variables';

const environment =
  APP_ENVIRONMENT === 'local' ? 'Local' : APP_ENVIRONMENT === 'dev' ? 'Staging' : 'production';

Sentry.init({
  environment: environment,
  dsn: 'https://3570e9a25000480e9a3b7135249f0742@o267867.ingest.sentry.io/5961272',
  // integrations: [new Integrations.BrowserTracing()],
  beforeBreadcrumb(breadcrumb, hint) {
    return breadcrumb.category === 'ui.click' ? null : breadcrumb;
  }
});

const container = document.getElementById('root')!;
const root = createRoot(container as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
