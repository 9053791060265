import { actionsTypes } from './companies.actions';

export interface Company {
  id: string;
  name: string;
  slug: string;
  country: string;
  logoUrl: string;
}

export interface CompaniesState {
  creatingCompany: boolean;
  newCompanies: string[];
  error: any;
  loadingCompanies: boolean;
  companies: Company[];
}

export const companiesInitialState: CompaniesState = {
  creatingCompany: false,
  newCompanies: [],
  error: false,
  loadingCompanies: false,
  companies: []
};

const companiesReducer = (state: CompaniesState = companiesInitialState, action: { type: string; payload: any; }): CompaniesState => {
  switch (action.type) {
    case actionsTypes.GET_COMPANIES: {
      return {
        ...state,
        loadingCompanies: true
      };
    }
    case actionsTypes.GET_COMPANIES_SUCCESS: {
      const { companies } = action.payload;
      return {
        ...state,
        loadingCompanies: false,
        companies
      };
    }
    case actionsTypes.GET_COMPANIES_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        loadingCompanies: false,
        error
      };
    }
    case actionsTypes.GET_COMPANY_BY_ID: {
        return {
        ...state,
        loadingCompanies: true
      };
    }
    case actionsTypes.GET_COMPANY_BY_ID_SUCCESS: {
      const { company } = action.payload;
      return {
        ...state,
        loadingCompanies: false,
        companies: company.slug
          ? [...state.companies.filter((com) => com.slug !== company.slug), company]
          : [...state.companies]
      };
    }
    case actionsTypes.GET_COMPANY_BY_ID_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        loadingCompanies: false,
        error
      };
    }
    case actionsTypes.CREATE_COMPANY: {
      return {
        ...state,
        creatingCompany: true
      };
    }
    case actionsTypes.CREATE_COMPANY_SUCCESS: {
      const { company } = action.payload;
      return {
        ...state,
        companies: [...state.companies, company],
        creatingCompany: false
      };
    }
    case actionsTypes.CREATE_COMPANY_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        creatingCompany: false,
        error
      };
    }
    default:
      return state;
  }
};

export default companiesReducer;
