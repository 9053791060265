import * as React from 'react';
import {
  Container,
  ImageColumn,
  Image,
  TextColumn,
  DashboardDescription,
  LoginButtonContainer,
  ForgotPassword,
  Updates
} from './LoginInit.style';

import AppButton from 'components/AppButton/AppButton';
import { LogInIcon } from 'evergreen-ui';

interface LoginInitProps {
  onSubmit: () => any;
  onForgotPassword: () => any;
}

const LoginInit: React.FC<LoginInitProps> = (props) => {
  return (
    <Container>
      <ImageColumn>
        <Image src='/culture-fit.png' />
      </ImageColumn>
      <TextColumn>
        <h1>Hello!</h1>
        <DashboardDescription>Click below to log in</DashboardDescription>
        <LoginButtonContainer>
          <AppButton icon={LogInIcon} onClick={() => props.onSubmit()} width={'120px'}>
            Log in
          </AppButton>
          <ForgotPassword onClick={() => props.onForgotPassword()}>Forgot password?</ForgotPassword>
        </LoginButtonContainer>

        <Updates>
          {/*<h3>December 2, 2021: What's new</h3>*/}
          {/*We're excited to share some updates including*/}
          {/*<ul>*/}
          {/*  <li>More secure login for you and your candidates</li>*/}
          {/*  <li>*/}
          {/*    New feature to manage your team and give your colleagues access to the Dashboard (available to company*/}
          {/*    admin users)*/}
          {/*  </li>*/}
          {/*  <li>Your candidate’s scores directly in the dashboard</li>*/}
          {/*</ul>*/}
        </Updates>
      </TextColumn>
    </Container>
  );
};

export default LoginInit;
