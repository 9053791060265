import React, { FunctionComponent, useEffect, useState } from 'react';
import { AuthenticatedUserProps } from '../../hoc/WithAuthLayout';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { selectAssessmentsById } from '../../store/assessments/assessments.selectors';
import { actions as assessmentActions } from '../../store/assessments/assessments.actions';
import { actions as targetGroupsActions } from '../../store/targetGroups/targetGroups.actions';
import { selectTargetGroupState } from '../../store/targetGroups/targetGroups.selectors';
import {
    Card,
    CardHeader,
    CardsWrapper,
    CardTitle,
    OpenedSection,
    ScanDetailsHeaderBackWrapper,
    ScanDetailsWrapper,
    ScanModulesSection,
    ScanSubTitle,
    ScanTargetGroupsSection,
    ScanTitle,
    ScanTitleEdit,
    ScanTitleEditWrapper,
    ScanTitleSection,
    ScanTitleWrapper,
    Tab,
    TabsWrapper,
    ScanModuleDescription,
    VideoSection,
    ScanModuleCheckboxWrapper,
    CheckboxContainer,
    Icon,
    StyledCheckbox,
    VideoPreviewWrapper,
    VideoTitle,
    Label,
    ScanDeleteSection,
    Empty,
} from './Styles';
import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronUpIcon,
    EditIcon,
    PeopleIcon,
} from 'evergreen-ui';
import { Company } from 'store/companies/companies.reducer';
import CardsView, { LabelObject } from '../../components/CardsView/CardsView';
import AppButton from '../../components/AppButton/AppButton';
import ScanDetailsModals from './Components/ScanDetailsModals/ScanDetailsModals';
import { selectCompaniesState } from '../../store/companies/companies.selectors';
interface ScansDetailsViewProps extends AuthenticatedUserProps {}
interface IModulesSection {
    cultureFit: {
        isOpen: boolean;
    };
    speaking: {
        isOpen: boolean;
    };
    cognitiveAbilities: {
        isOpen: boolean;
    };
}
const ScansDetailsView: FunctionComponent<ScansDetailsViewProps> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { assessmentId } = useParams();

    const { companies } = useSelector(selectCompaniesState);
    const assessments = useSelector(selectAssessmentsById);
    const targetGroupsFromStore = useSelector(selectTargetGroupState);
    const [companyId, setCompanyId] = useState<string>('');
    const currentCompany = companies.find(
        (company: Company) => company.id === companyId,
    );
    const [section, setSection] = useState<IModulesSection>({
        cultureFit: {
            isOpen: false,
        },
        speaking: {
            isOpen: false,
        },
        cognitiveAbilities: {
            isOpen: false,
        },
    });
    const [shouldShowChangeScanNameModal, showChangeScanNameModal] =
        useState<boolean>(false);
    const [shouldShowDeleteScanModal, showDeleteScanModal] =
        useState<boolean>(false);

    const scan =
        assessmentId &&
        assessments[assessmentId] &&
        assessments[assessmentId].data;

    const [scanName, setScanName] = useState<string>('');

    const targetGroups =
        assessmentId &&
        targetGroupsFromStore.byAssessmentId[assessmentId] &&
        targetGroupsFromStore.byAssessmentId[assessmentId].list;

    const hasCultureFitVideo =
        scan &&
        scan.modules &&
        scan.modules.find((module) => {
            return module.id === 'culture-fit';
        });
    const hasCultureFitQuestionnaire =
        scan &&
        scan.modules &&
        scan.modules.find((module) => {
            return module.id === 'culture-fit-questionnaire';
        });
    const hasCultureFit = hasCultureFitVideo || hasCultureFitQuestionnaire;
    const hasSpeaking =
        scan &&
        scan.modules &&
        scan.modules.find((module) => {
            return module.id === 'interview';
        });
    const hasSpeakingVideo =
        hasSpeaking &&
        hasSpeaking.questions &&
        hasSpeaking.questions.find((question) => {
            return !!question.videos;
        });
    const hasSpeakingVideo1 =
        hasSpeaking && hasSpeaking.questions && hasSpeaking.questions[0].videos;
    const hasSpeakingVideo2 =
        hasSpeaking && hasSpeaking.questions && hasSpeaking.questions[1].videos;
    const hasCognitiveAbilities =
        scan &&
        scan.modules &&
        scan.modules.find((module) => {
            return module.id === 'mental-test';
        });
    const handleOpenModuleSection = (chosen: string) => {
        if (chosen === 'culture-fit') {
            setSection((state) => ({
                ...state,
                cultureFit: {
                    isOpen: !section.cultureFit.isOpen,
                },
            }));
        }
        if (chosen === 'speaking') {
            setSection((state) => ({
                ...state,
                speaking: {
                    isOpen: !section.speaking.isOpen,
                },
            }));
        }
        if (chosen === 'cognitive-abilities') {
            setSection((state) => ({
                ...state,
                cognitiveAbilities: {
                    isOpen: !section.cognitiveAbilities.isOpen,
                },
            }));
        }
    };

    const discardModals = () => {
        if (scan) {
            setScanName(scan.name);
        }
        showChangeScanNameModal(false);
        showDeleteScanModal(false);
    };

    const deleteEmptyScan = () => {
        if (assessmentId) {
            dispatch(
                assessmentActions.deleteEmptyScan(
                    assessmentId,
                    navigate,
                    currentCompany?.slug,
                ),
            );
        }
    };

    const updateScanConfig = () => {
        if (assessmentId) {
            dispatch(
                assessmentActions.updateAssessmentConfig(assessmentId, {
                    name: scanName,
                }),
            );
            showChangeScanNameModal(false);
        }
    };

    useEffect(() => {
        if (assessmentId) {
            dispatch(
                targetGroupsActions.getAssessmentsTargetGroups(assessmentId),
            );
        }
    }, [assessmentId, dispatch]);

    useEffect(() => {
        if (
            assessmentId &&
            assessments &&
            assessments[assessmentId] &&
            assessments[assessmentId].data.company_id
        ) {
            setCompanyId(assessments[assessmentId].data.company_id);
        }
    }, [assessmentId, assessments]);

    // fetch data on first load
    useEffect(() => {
        if (assessmentId) {
            dispatch(assessmentActions.getAssessment(assessmentId));
        }
    }, [assessmentId, dispatch]);

    useEffect(() => {
        if (scan) {
            setScanName(scan.name);
        }
    }, [scan]);

    if (!assessmentId) {
        return <div>Failed: No scan details!</div>;
    }

    if (!scan) {
        return <div>Loading...</div>;
    }

    return (
        <ScanDetailsWrapper>
            <ScanDetailsHeaderBackWrapper onClick={() => navigate('/')}>
                <ChevronLeftIcon />
                Back to dashboard
            </ScanDetailsHeaderBackWrapper>
            <ScanTitleSection>
                <ScanTitleWrapper>
                    <ScanTitle>{scanName}</ScanTitle>
                    <ScanSubTitle>
                        <PeopleIcon color={'#0BA6AC'} />
                        <span>
                            {' '}
                            {targetGroups?.length === 1
                                ? `Used by 1 group`
                                : `Used by ${targetGroups?.length} groups`}
                        </span>
                    </ScanSubTitle>
                </ScanTitleWrapper>
                <ScanTitleEditWrapper
                    onClick={() => showChangeScanNameModal(true)}>
                    <EditIcon color={'rgba(67, 89, 112, 0.8)'} />
                    <ScanTitleEdit>edit name</ScanTitleEdit>
                </ScanTitleEditWrapper>
            </ScanTitleSection>
            <ScanTitle className={'secondary-title'}>
                Groups using this scan
            </ScanTitle>
            <ScanTargetGroupsSection isEmpty={targetGroups?.length === 0}>
                {targetGroups &&
                    targetGroups.map((targetGroup) => {
                        const numberParticipants =
                            targetGroup.participants.length;
                        const subtitle =
                            numberParticipants === 1 &&
                            targetGroup.type === 'CANDIDATE'
                                ? `1 candidate`
                                : numberParticipants === 1 &&
                                  targetGroup.type === 'INTERNAL'
                                ? `1 participant`
                                : numberParticipants !== 1 &&
                                  targetGroup.type === 'INTERNAL'
                                ? `${numberParticipants} participants`
                                : `${numberParticipants} candidates`;
                        const cardLabels: LabelObject[] = [];

                        cardLabels.push({
                            color: 'secondary',
                            text: scan.language,
                        });

                        return (
                            <Link
                                key={targetGroup.id}
                                to={`/assessments/${assessmentId}/target-groups/${targetGroup.id}`}>
                                <CardsView labels={cardLabels}>
                                    <h1>{targetGroup.name}</h1>
                                    <h2>{subtitle}</h2>
                                </CardsView>
                            </Link>
                        );
                    })}
                {targetGroups?.length === 0 && (
                    <Empty>
                        <svg
                            width="81"
                            height="51"
                            viewBox="0 0 81 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M59.4118 30.5253C59.4118 29.7469 58.8224 29.3577 57.6437 29.3577H21.3495C20.46 29.3577 19.5093 29.5968 18.4974 30.0749C17.4855 30.5531 16.6905 31.1369 16.1122 31.8263L6.30477 43.9355C5.90448 44.4692 5.70434 44.914 5.70434 45.2698C5.70434 46.0482 6.29367 46.4374 7.47233 46.4374H43.7666C44.6562 46.4374 45.6124 46.1927 46.6354 45.7035C47.6584 45.2142 48.4479 44.6249 49.0039 43.9355L58.8113 31.8263C59.2116 31.337 59.4118 30.9034 59.4118 30.5253L59.4118 30.5253ZM21.3495 25.0878H46.969V19.7505C46.969 18.8609 46.6576 18.1047 46.035 17.482C45.4123 16.8594 44.6561 16.548 43.7665 16.548H24.552C23.6624 16.548 22.9063 16.2367 22.2836 15.614C21.6609 14.9913 21.3495 14.2352 21.3495 13.3456V11.2106C21.3495 10.3211 21.0382 9.56493 20.4155 8.94223C19.7928 8.31953 19.0367 8.0082 18.1471 8.00822H7.47233C6.58277 8.00822 5.82665 8.31957 5.20395 8.94227C4.58125 9.56496 4.26991 10.3211 4.26991 11.2107V39.6656L12.8097 29.1576C13.7882 27.979 15.0781 27.006 16.6793 26.2387C18.2805 25.4715 19.8373 25.0879 21.3495 25.0879V25.0878ZM63.6817 30.5253C63.6817 31.9041 63.1702 33.2385 62.1472 34.5283L52.3064 46.6375C51.3501 47.8162 50.0602 48.7892 48.4368 49.5564C46.8133 50.3236 45.2566 50.7073 43.7665 50.7073H7.47233C5.42634 50.7073 3.66945 49.9734 2.20167 48.5056C0.733891 47.0378 0 45.2809 0 43.235V11.2106C0 9.16463 0.733891 7.40774 2.20167 5.93995C3.66945 4.47217 5.42634 3.73828 7.47233 3.73828H18.1471C20.1931 3.73828 21.95 4.47217 23.4178 5.93995C24.8856 7.40774 25.6195 9.16463 25.6195 11.2106V12.2781H43.7666C45.8126 12.2781 47.5695 13.012 49.0373 14.4798C50.505 15.9475 51.2389 17.7044 51.2389 19.7505V25.0878H57.6438C58.8447 25.0878 59.9455 25.3603 60.9463 25.9051C61.9471 26.45 62.6921 27.2339 63.1813 28.2569C63.5149 28.9685 63.6817 29.7247 63.6817 30.5253Z"
                                fill="#A0ABB7"
                            />
                            <path
                                d="M61.8244 11.4768L63.0486 3.00003"
                                stroke="#A0ABB7"
                                strokeWidth="4.05"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M54.4501 12.0811L50.5109 4.47603"
                                stroke="#A0ABB7"
                                strokeWidth="4.05"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M72.6514 32.9823L66.9464 26.5943"
                                stroke="#A0ABB7"
                                strokeWidth="4.05"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M68.2312 13.698L74.5124 7.87564"
                                stroke="#A0ABB7"
                                strokeWidth="4.05"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M69.5504 20.3629L78.0763 21.1775"
                                stroke="#A0ABB7"
                                strokeWidth="4.05"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <p>
                            This scan is not used by any groups. When you’re
                            creating a new group you can select this scan to
                            use.
                        </p>
                    </Empty>
                )}
            </ScanTargetGroupsSection>
            <ScanModulesSection>
                <ScanTitleWrapper>
                    <ScanTitle className={'secondary-title'}>
                        Scan configuration
                    </ScanTitle>
                    <ScanSubTitle className={'secondary-sub'}>
                        <span className={'secondary-sub'}>
                            Details of the scan configuration and assets.
                        </span>
                    </ScanSubTitle>
                </ScanTitleWrapper>
                <CardsWrapper>
                    {!!hasCultureFit && (
                        <Card>
                            <CardHeader
                                onClick={() => {
                                    handleOpenModuleSection('culture-fit');
                                }}>
                                <CardTitle>Cultural Fit</CardTitle>
                                {!section.cultureFit.isOpen && (
                                    <ChevronUpIcon size={26} />
                                )}
                                {section.cultureFit.isOpen && (
                                    <ChevronDownIcon size={26} />
                                )}
                            </CardHeader>
                            {section.cultureFit.isOpen && (
                                <OpenedSection>
                                    <ScanModuleDescription>
                                        The cultural fit module gives insights
                                        on candidate’s match to the
                                        organizations values, culture and
                                        working environment.
                                        <ScanModuleCheckboxWrapper>
                                            <Label
                                                checked={!!hasCultureFitVideo}>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={
                                                            !!hasCultureFitVideo
                                                        }>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Engagement score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                        <ScanModuleCheckboxWrapper>
                                            <Label
                                                checked={
                                                    !!hasCultureFitQuestionnaire
                                                }>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={
                                                            !!hasCultureFitQuestionnaire
                                                        }>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Work preferences scores
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                    </ScanModuleDescription>
                                    <VideoPreviewWrapper>
                                        {!!hasCultureFitVideo &&
                                            hasCultureFitVideo.videos && (
                                                <video
                                                    width={'100%'}
                                                    height={'280px'}
                                                    controls
                                                    src={
                                                        hasCultureFitVideo
                                                            .videos.low_res
                                                    }>
                                                    Your browser does not
                                                    support the video tag.
                                                </video>
                                            )}
                                    </VideoPreviewWrapper>
                                </OpenedSection>
                            )}
                        </Card>
                    )}
                    {!!hasSpeaking && (
                        <Card>
                            <CardHeader
                                onClick={() => {
                                    handleOpenModuleSection('speaking');
                                }}>
                                <CardTitle>Speaking</CardTitle>
                                {!section.speaking.isOpen && (
                                    <ChevronUpIcon size={26} />
                                )}
                                {section.speaking.isOpen && (
                                    <ChevronDownIcon size={26} />
                                )}
                            </CardHeader>
                            {section.speaking.isOpen && (
                                <OpenedSection>
                                    <ScanModuleDescription>
                                        The Speaking module gives insights on a
                                        candidate’s general motivation and
                                        overall confidence based on the
                                        candidates recording their answers to
                                        two pre-defined questions.
                                        <TabsWrapper>
                                            {!hasSpeakingVideo && (
                                                <Tab active={!hasSpeakingVideo}>
                                                    Written questions
                                                </Tab>
                                            )}
                                            {!!hasSpeakingVideo && (
                                                <Tab
                                                    active={!!hasSpeakingVideo}>
                                                    Video questions
                                                </Tab>
                                            )}
                                        </TabsWrapper>
                                        <ScanModuleCheckboxWrapper>
                                            <Label checked={!!hasSpeaking}>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={!!hasSpeaking}>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    General motivation score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                        <ScanModuleCheckboxWrapper>
                                            <Label checked={!!hasSpeaking}>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={!!hasSpeaking}>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Overall confidence score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                    </ScanModuleDescription>
                                    <VideoSection>
                                        {!!hasSpeakingVideo &&
                                            !!hasSpeakingVideo1 &&
                                            hasSpeakingVideo2 && (
                                                <>
                                                    <VideoTitle>
                                                        Question 1: - Why do you
                                                        want to work for this
                                                        company?
                                                    </VideoTitle>
                                                    <video
                                                        width={'100%'}
                                                        height={'280px'}
                                                        controls
                                                        src={
                                                            hasSpeakingVideo1.low_res
                                                        }>
                                                        Your browser does not
                                                        support the video tag.
                                                    </video>
                                                    <VideoTitle>
                                                        Question 2: - What can
                                                        you bring to the company
                                                        in your role?
                                                    </VideoTitle>
                                                    <video
                                                        width={'100%'}
                                                        height={'280px'}
                                                        controls
                                                        src={
                                                            hasSpeakingVideo2.low_res
                                                        }>
                                                        Your browser does not
                                                        support the video tag.
                                                    </video>
                                                </>
                                            )}
                                    </VideoSection>
                                </OpenedSection>
                            )}
                        </Card>
                    )}
                    {!!hasCognitiveAbilities && (
                        <Card>
                            <CardHeader
                                onClick={() => {
                                    handleOpenModuleSection(
                                        'cognitive-abilities',
                                    );
                                }}>
                                <CardTitle>Cognitive Abilities</CardTitle>
                                {!section.cognitiveAbilities.isOpen && (
                                    <ChevronUpIcon size={26} />
                                )}
                                {section.cognitiveAbilities.isOpen && (
                                    <ChevronDownIcon size={26} />
                                )}
                            </CardHeader>
                            {section.cognitiveAbilities.isOpen && (
                                <OpenedSection>
                                    <ScanModuleDescription>
                                        The Cognitive ability module gives
                                        insights on candidates mental
                                        capabilities and stress.
                                        <ScanModuleCheckboxWrapper>
                                            <Label
                                                checked={
                                                    !!hasCognitiveAbilities
                                                }>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={
                                                            !!hasCognitiveAbilities
                                                        }>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Stress resilience score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                        <ScanModuleCheckboxWrapper>
                                            <Label
                                                checked={
                                                    !!hasCognitiveAbilities
                                                }>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={
                                                            !!hasCognitiveAbilities
                                                        }>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Ease under pressure score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                        <ScanModuleCheckboxWrapper>
                                            <Label
                                                checked={
                                                    !!hasCognitiveAbilities
                                                }>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={
                                                            !!hasCognitiveAbilities
                                                        }>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Cognitive abilities scores
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                    </ScanModuleDescription>
                                </OpenedSection>
                            )}
                        </Card>
                    )}
                </CardsWrapper>
            </ScanModulesSection>
            <ScanDeleteSection>
                <h3>Delete Scan</h3>
                {targetGroups && targetGroups?.length > 0 && (
                    <p>
                        This scan cannot be deleted because there are still
                        groups that are using it. <br />
                        <br />
                        To delete this configuration please remove all groups
                        connected to this scan.
                    </p>
                )}
                {targetGroups?.length === 0 && (
                    <p>Deleting this scan is permanent.</p>
                )}
                <AppButton
                    isDisabled={!!targetGroups?.length}
                    onClick={() => {
                        showDeleteScanModal(true);
                    }}>
                    Delete scan
                </AppButton>
            </ScanDeleteSection>
            <ScanDetailsModals
                shouldShowChangeScanNameModal={shouldShowChangeScanNameModal}
                scanName={scanName}
                setScanName={setScanName}
                deleteEmptyScan={deleteEmptyScan}
                shouldShowDeleteScanModal={shouldShowDeleteScanModal}
                onDiscardModal={discardModals}
                updateScanConfig={updateScanConfig}
            />
        </ScanDetailsWrapper>
    );
};
export default ScansDetailsView;
