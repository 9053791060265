import React from 'react';
import {
    SuccessBox,
    SuccessScreenCheckMarkWrapper,
    SuccessScreenWrapper,
} from './style';


interface SuccessPageProps {
    children: React.ReactElement
}
const SuccessPage = (props: SuccessPageProps) => {
    const { children } = props;
    const fireworks = document.getElementById('fireworks');

    setTimeout(function () {
        if (fireworks) fireworks.style.display = 'none';
    }, 5000);

    return (
        <>
            <SuccessScreenWrapper>
                <div id="fireworks" className="fireworks"></div>
            </SuccessScreenWrapper>
            <SuccessBox>
                <SuccessScreenCheckMarkWrapper>
                    <svg
                        className="success"
                        height="123"
                        viewBox="0 0 216 123"
                        width="216"
                        xmlns="http://www.w3.org/2000/svg">
                        <g
                            fill="none"
                            fillRule="evenodd"
                            transform="translate(0 2)">
                            <g
                                opacity=".493001"
                                stroke="#0ba6ac"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2.5"
                                transform="translate(149)">
                                <path d="m10.5 0v6" />
                                <path d="m10.5 14v6" />
                                <path
                                    d="m3.5 7v6"
                                    transform="matrix(0 -1 1 0 -6.5 13.5)"
                                />
                                <path
                                    d="m17.5 7v6"
                                    transform="matrix(0 -1 1 0 7.5 27.5)"
                                />
                            </g>
                            <g
                                opacity=".493001"
                                stroke="#0ba6ac"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2.5"
                                transform="translate(68 103)">
                                <path d="m8.5 0v4" />
                                <path d="m8.5 12v4" />
                                <path
                                    d="m2.5 6v4"
                                    transform="matrix(0 -1 1 0 -5.5 10.5)"
                                />
                                <path
                                    d="m14.5 6v4"
                                    transform="matrix(0 -1 1 0 6.5 22.5)"
                                />
                            </g>
                            <path
                                d="m119 24c3.865993 0 7 3.1340068 7 7s-3.134007 7-7 7h64c3.865993 0 7 3.1340068 7 7s-3.134007 7-7 7h22c3.865993 0 7 3.1340068 7 7s-3.134007 7-7 7h-19c-3.865993 0-7 3.1340068-7 7s3.134007 7 7 7h6c3.865993 0 7 3.1340068 7 7s-3.134007 7-7 7h-146c-3.8659932 0-7-3.1340068-7-7s3.1340068-7 7-7h-39c-3.86599325 0-7-3.1340068-7-7s3.13400675-7 7-7h40c3.8659932 0 7-3.1340068 7-7s-3.1340068-7-7-7h-25c-3.8659932 0-7-3.1340068-7-7s3.1340068-7 7-7h40c-3.8659932 0-7-3.1340068-7-7s3.1340068-7 7-7zm90 56c3.865993 0 7 3.1340068 7 7s-3.134007 7-7 7-7-3.1340068-7-7 3.134007-7 7-7z"
                                fill="#0ba6ac"
                                opacity=".204683"
                            />
                            <circle
                                className="success__circle"
                                cx="119"
                                cy="63"
                                fill="#fff"
                                r="48.5"
                                stroke="#0ba6ac"
                                strokeWidth="3"
                            />
                            <path
                                d="m107.636557 35.3726612c-1.997911-.8660559-4.076131-1.5809105-6.220341-2.1301924-3.3319644-.8535491-6.8232737-1.3072867-10.4201894-1.3072867-1.4744401 0-2.9311348.0762427-4.3663827.2250138m-4.7382148.7679658c-1.3069168.2898579-2.5909631.6408076-3.8490122 1.0497115-.9291518.3020023-1.8441227.6356175-2.743653.9995813"
                                fill="#fff"
                                opacity=".351539"
                                stroke="#4bca81"
                                strokeLinecap="round"
                                strokeWidth="2.5"
                                transform="matrix(.7193398 -.69465837 .69465837 .7193398 2.293353 72.984092)"
                            />
                            <path
                                className="success__check"
                                d="m114.119231 74.3307692-11.423077-11.4923077c-.415385-.4153846-.415385-1.1076923 0-1.5230769l1.523077-1.5230769c.415384-.4153846 1.107692-.4153846 1.523077 0l8.584615 8.6538461c.276923.2769231.761539.2769231 1.038462 0l16.823077-16.9615384c.415384-.4153846 1.107692-.4153846 1.523076 0l1.523077 1.5230769c.415385.4153846.415385 1.1076923 0 1.5230769l-19.592307 19.8c-.415385.4846154-1.107693.4846154-1.523077 0z"
                                fill="#0ba6ac"
                            />
                        </g>
                    </svg>
                </SuccessScreenCheckMarkWrapper>
                <div className="success-title">SUCCESS</div>
                <div className="success-children">{children}</div>
            </SuccessBox>
        </>
    );
};
export default SuccessPage;
