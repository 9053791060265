import styled from "styled-components";

interface IProps {
    active?: boolean;
}

export const Content=styled('div')<IProps>`
  background-color: #EFF5FB;
  grid-area: content;
  padding: 3em;
  display:${({ active }) => active? "block" : "none"};
`;
export const ReportTabContent=styled('div')`
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  scroll-margin: 140px;
`;
export const ContentResult=styled.div`
  border-radius: 6px;
  padding: 2em;
  background-color: #ffffff;
  div div div div .info-description {
    align-self: center !important;
  }
`;