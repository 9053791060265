import React, { FunctionComponent, useState } from 'react';
import ScansCardsList from '../../../components/ScansCardsList/ScansCardsList';
import ScanActiveModules from '../../../components/ScanActiveModules/ScanActiveModules';
import { Assessment } from '../../../typings/assessment';
import { Company } from '../../../store/companies/companies.reducer';

interface TargetGroupScanProps {
    currentCompany: Company;
    assessment: Assessment;
    scanGroupsCount: number;
}

const TargetGroupScan: FunctionComponent<TargetGroupScanProps> = (props) => {
    const { currentCompany, assessment, scanGroupsCount } = props;
    const [openScanDetails, setOpenScanDetails] = useState<boolean>(false);
    const scanAndGroups = [
        {
            scan: assessment,
            groupsCount: scanGroupsCount,
        },
    ];

    const handleOpenScanDetails = () => {
        setOpenScanDetails(true);
    };

    return (
        <div>
            {currentCompany?.slug && !openScanDetails && (
                <ScansCardsList
                    handleScanDetails={handleOpenScanDetails}
                    isTargetGroupDeepDive={true}
                    scansAndGroups={scanAndGroups}
                    companySlug={currentCompany.slug}
                />
            )}
            {openScanDetails && (
                <ScanActiveModules
                    scan={assessment}
                    isTargetGroupDeepDive={true}
                    closeScanDetails={() => setOpenScanDetails(false)}
                />
            )}
        </div>
    );
};

export default TargetGroupScan;
