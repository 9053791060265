import {ResetIcon, TrashIcon} from 'evergreen-ui';
import React, { FunctionComponent } from 'react';
import styles from './TargetGroupModals.module.css';
import DialogComponent from 'components/Dialog/Dialog';
import { Participant } from 'typings/user';

interface TargetGroupModalsProps {
    participantsSelected: Participant[];
    shouldShowInviteModal: boolean;
    shouldShowResetPasswordModal: boolean;
    shouldShowReminderModal: boolean;
    shouldShowReportModal: boolean;
    shouldShowRemoveModal: boolean;
    shouldShowResetCandidateScanSuccessModal: boolean;
    shouldShowFullDeleteCandidateModal: boolean;
    shouldShowResetCandidateScanModal: boolean;
    shouldShowDeleteTargetGroupModal: boolean;
    participantsThatHaveReport: Participant[];
    sendResetPasswordEmails: () => void;
    sendReminderEmails: () => void;
    sendReportEmails: () => void;
    removeCandidates: () => void;
    fullDeleteCandidates: () => void;
    resetCandidateScan: () => void;
    deleteTargetGroup: () => void;
    isReportReadyToBeSent: (participant: Participant) => boolean;
    participantsThatNotFinishedScan: Participant[];
    onDiscardModal: () => void;
}

const TargetGroupModals: FunctionComponent<TargetGroupModalsProps> = (
    props: TargetGroupModalsProps,
) => {
    const {
        participantsSelected,
        shouldShowResetPasswordModal,
        shouldShowReminderModal,
        shouldShowReportModal,
        shouldShowRemoveModal,
        shouldShowResetCandidateScanSuccessModal,
        shouldShowFullDeleteCandidateModal,
        shouldShowResetCandidateScanModal,
        shouldShowDeleteTargetGroupModal,
        participantsThatHaveReport,
        participantsThatNotFinishedScan,
        isReportReadyToBeSent,
        sendResetPasswordEmails,
        sendReminderEmails,
        sendReportEmails,
        removeCandidates,
        fullDeleteCandidates,
        resetCandidateScan,
        deleteTargetGroup,
        onDiscardModal,
    } = props;

    // const getEmailInfo = (
    //   template: 'UserInvitation' | 'UserReminder' | 'UserReport',
    //   participant: ParticipantTableData
    // ): UserEmailInfo => {
    //   if (participant.emails_info) {
    //     const { emails_info } = participant;
    //     const email = emails_info.find((email) => email.template === template);
    //     if (email) {
    //       return email;
    //     }
    //     return null;
    //   } else return null;
    // };

    return (
        <>
            {shouldShowResetPasswordModal && (
                <DialogComponent
                    title="Confirm to send reset password email"
                    confirmButtonText="Send reset password email"
                    isShown={shouldShowResetPasswordModal}
                    onClose={onDiscardModal}
                    onConfirm={sendResetPasswordEmails}>
                    {participantsSelected.length > 0 && (
                        <div className={styles.dialog__content}>
                            The following candidate(s) will receive an email
                            with a link to reset their own passwords.
                            <br />
                            Do you really want to send a reset password email to
                            the following candidate(s)?
                            <table>
                                <tbody>
                                    {participantsSelected.map(
                                        (participant, i) => {
                                            return (
                                                <React.Fragment
                                                    key={
                                                        'table__' +
                                                        participant +
                                                        i
                                                    }>
                                                    <tr
                                                        key={
                                                            'table__' +
                                                            participant.id
                                                        }>
                                                        <td>
                                                            <li
                                                                style={{
                                                                    paddingTop: 0,
                                                                }}>
                                                                {
                                                                    participant.first_name
                                                                }{' '}
                                                                {
                                                                    participant.last_name
                                                                }
                                                            </li>
                                                        </td>
                                                        <td>
                                                            {participant.email}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </DialogComponent>
            )}
            {shouldShowReminderModal && (
                <DialogComponent
                    title="Confirm to send reminder email"
                    confirmButtonText="Send reminder email"
                    isShown={shouldShowReminderModal}
                    onClose={onDiscardModal}
                    onConfirm={sendReminderEmails}>
                    {participantsSelected.length > 0 && (
                        <div className={styles.dialog__content}>
                            Do you really want to send a reminder email to the
                            following candidate(s)?
                            <table>
                                <tbody>
                                    {participantsSelected.map(
                                        (participant, i) => {
                                            return (
                                                <React.Fragment
                                                    key={
                                                        'table__' +
                                                        participant +
                                                        i
                                                    }>
                                                    <tr
                                                        key={
                                                            'table__' +
                                                            participant.id
                                                        }>
                                                        <td>
                                                            <li
                                                                style={{
                                                                    paddingTop: 0,
                                                                }}>
                                                                {
                                                                    participant.first_name
                                                                }{' '}
                                                                {
                                                                    participant.last_name
                                                                }
                                                            </li>
                                                        </td>
                                                        <td>
                                                            {participant.email}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </DialogComponent>
            )}
            {shouldShowRemoveModal && (
                <DialogComponent
                    title="Confirm to remove candidate"
                    confirmButtonText="Remove candidate(s)"
                    isShown={shouldShowRemoveModal}
                    onClose={onDiscardModal}
                    onConfirm={removeCandidates}
                    confirmIcon={TrashIcon}>
                    {participantsThatNotFinishedScan.length > 0 && (
                        <>
                            <div className={styles.dialog__content}>
                                Do you really want to remove the following
                                candidate(s)?
                                <table>
                                    <tbody>
                                        {participantsSelected
                                            .filter((participant) =>
                                                participantsThatNotFinishedScan.includes(
                                                    participant,
                                                ),
                                            )
                                            .map((participant, i) => {
                                                return (
                                                    <React.Fragment
                                                        key={
                                                            'table__' +
                                                            participant +
                                                            i
                                                        }>
                                                        <tr
                                                            key={
                                                                'table__' +
                                                                participant.id
                                                            }>
                                                            <td>
                                                                <li
                                                                    style={{
                                                                        paddingTop: 0,
                                                                    }}>
                                                                    {
                                                                        participant.first_name
                                                                    }{' '}
                                                                    {
                                                                        participant.last_name
                                                                    }
                                                                </li>
                                                            </td>
                                                            <td>
                                                                {
                                                                    participant.email
                                                                }
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            {participantsSelected.length !==
                                participantsThatNotFinishedScan.length && (
                                <div
                                    className={
                                        styles.dialog__candidates_cannot_receive_modal_action
                                    }>
                                    The following candidate(s) cannot be removed
                                    because they have already completed the
                                    scan. Please contact support to delete
                                    candidate scan data.
                                    <table>
                                        <tbody>
                                            {participantsSelected
                                                .filter(
                                                    (participant) =>
                                                        !participantsThatNotFinishedScan.includes(
                                                            participant,
                                                        ),
                                                )
                                                .map((participant, i) => {
                                                    return (
                                                        <React.Fragment
                                                            key={
                                                                'table__' +
                                                                participant +
                                                                i
                                                            }>
                                                            <tr
                                                                key={
                                                                    'table__' +
                                                                    participant.id
                                                                }>
                                                                <td>
                                                                    <li
                                                                        style={{
                                                                            paddingTop: 0,
                                                                        }}>
                                                                        {
                                                                            participant.first_name
                                                                        }{' '}
                                                                        {
                                                                            participant.last_name
                                                                        }
                                                                    </li>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        participant.email
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </>
                    )}
                </DialogComponent>
            )}
            {shouldShowFullDeleteCandidateModal && (
                <DialogComponent
                    title="Confirm to delete candidate completely"
                    confirmButtonText="Delete candidate(s)"
                    isShown={shouldShowFullDeleteCandidateModal}
                    onClose={onDiscardModal}
                    onConfirm={fullDeleteCandidates}
                    confirmIcon={TrashIcon}>
                    {participantsSelected.length > 0 && (
                        <div className={styles.dialog__content}>
                            Do you really want to delete the following
                            candidate(s)?
                            <table>
                                <tbody>
                                    {participantsSelected.map(
                                        (participant, i) => {
                                            return (
                                                <React.Fragment
                                                    key={
                                                        'table__' +
                                                        participant +
                                                        i
                                                    }>
                                                    <tr
                                                        key={
                                                            'table__' +
                                                            participant.id
                                                        }>
                                                        <td>
                                                            <li
                                                                style={{
                                                                    paddingTop: 0,
                                                                }}>
                                                                {
                                                                    participant.first_name
                                                                }{' '}
                                                                {
                                                                    participant.last_name
                                                                }
                                                            </li>
                                                        </td>
                                                        <td>
                                                            {participant.email}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </DialogComponent>
            )}
            {shouldShowResetCandidateScanModal && (
                <DialogComponent
                    title="Confirm to reset candidate scan"
                    confirmButtonText="Reset scan"
                    isShown={shouldShowResetCandidateScanModal}
                    onClose={onDiscardModal}
                    onConfirm={resetCandidateScan}
                    confirmIcon={ResetIcon}>
                    {participantsSelected.length === 1 && (
                        <div className={styles.dialog__content}>
                            Do you really want to reset the scan for the
                            following candidate?
                            <table>
                                <tbody>
                                    {participantsSelected.map(
                                        (participant, i) => {
                                            return (
                                                <React.Fragment
                                                    key={
                                                        'table__' +
                                                        participant +
                                                        i
                                                    }>
                                                    <tr
                                                        key={
                                                            'table__' +
                                                            participant.id
                                                        }>
                                                        <td>
                                                            <li
                                                                style={{
                                                                    paddingTop: 0,
                                                                }}>
                                                                {
                                                                    participant.first_name
                                                                }{' '}
                                                                {
                                                                    participant.last_name
                                                                }
                                                            </li>
                                                        </td>
                                                        <td>
                                                            {participant.email}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </DialogComponent>
            )}
            {shouldShowResetCandidateScanSuccessModal && (
                <DialogComponent
                    title="Reset candidate scan"
                    isShown={shouldShowResetCandidateScanSuccessModal}
                    onClose={onDiscardModal}
                    closeButtonText={'Close'}>
                    <div className={styles.dialog__content}>
                        Scan was reset successfully.
                    </div>
                </DialogComponent>
            )}
            {shouldShowReportModal && (
                <DialogComponent
                    title="Confirm to send report"
                    confirmButtonText="Send report"
                    isShown={shouldShowReportModal}
                    onClose={onDiscardModal}
                    onConfirm={sendReportEmails}>
                    {participantsThatHaveReport.length > 0 && (
                        <div className={styles.dialog__content}>
                            Do you really want to send a report to the following
                            candidates?
                            <table>
                                <tbody>
                                    <ul className={styles.dialog__list}>
                                        {participantsSelected.map(
                                            (participant) => {
                                                return (
                                                    <React.Fragment>
                                                        {isReportReadyToBeSent(
                                                            participant,
                                                        ) && (
                                                            <tr
                                                                key={
                                                                    'table__' +
                                                                    participant.id
                                                                }>
                                                                <td>
                                                                    <li>
                                                                        {
                                                                            participant.first_name
                                                                        }{' '}
                                                                        {
                                                                            participant.last_name
                                                                        }
                                                                    </li>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        participant.email
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            },
                                        )}
                                    </ul>
                                </tbody>
                            </table>
                        </div>
                    )}
                    {participantsSelected.some(
                        (participant) => !isReportReadyToBeSent(participant),
                    ) && (
                        <div
                            className={
                                styles.dialog__candidates_cannot_receive_modal_action
                            }>
                            The following user(s) cannot receive the report yet,
                            because their report has not yet been generated.
                            <table>
                                <tbody>
                                    <ul className={styles.dialog__list}>
                                        {participantsSelected.map(
                                            (participant) => {
                                                return (
                                                    <>
                                                        {!isReportReadyToBeSent(
                                                            participant,
                                                        ) && (
                                                            <tr
                                                                key={
                                                                    'table__' +
                                                                    participant.id
                                                                }>
                                                                <td>
                                                                    <li>
                                                                        {
                                                                            participant.first_name
                                                                        }{' '}
                                                                        {
                                                                            participant.last_name
                                                                        }
                                                                    </li>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        participant.email
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                );
                                            },
                                        )}
                                    </ul>
                                </tbody>
                            </table>
                        </div>
                    )}
                </DialogComponent>
            )}
            {shouldShowDeleteTargetGroupModal && (
                <DialogComponent
                    title="Confirm to delete this group"
                    confirmButtonText="Delete group"
                    isShown={shouldShowDeleteTargetGroupModal}
                    onClose={onDiscardModal}
                    onConfirm={deleteTargetGroup}
                    confirmIcon={TrashIcon}>
                    <div className={styles.dialog__content}>
                        Are you sure you want to delete this group?
                        <br />
                        <br />
                        This will permanently delete the group configuration and
                        cannot be undone.
                    </div>
                </DialogComponent>
            )}
        </>
    );
};

export default TargetGroupModals;
