import { actionsTypes } from './assessments.actions';
import { Assessment } from 'typings/assessment';

export interface AssessmentsState {
    byCompanyId: {
        [companyId: string]: {
            list: Assessment[];
            totalCount: number;
            isFetching: boolean;
        };
    };
    byId: {
        [assessmentId: string]: {
            data: Assessment;
            updating: boolean;
            error: any;
        };
    };
    error: any;
    creatingScan: boolean;
    isLoading?: boolean;
}
export const assessmentsInitialState = {
    byCompanyId: {},
    byId: {},
    error: null,
    creatingScan: false,
};

const assessmentsReducer = (
    state: AssessmentsState = assessmentsInitialState,
    action: { type: string; payload?: any },
) => {
    switch (action.type) {
        case actionsTypes.GET_ASSESSMENT_SUCCESS: {
            const { assessment } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [assessment.id]: {
                        data: assessment,
                        updating: false,
                        error: null,
                    },
                },
                byCompanyId: {
                    ...state.byCompanyId,
                    [assessment.company_id]: {
                        list: [assessment],
                        totalCount: 1,
                        isFetching: false,
                    },
                },
                error: null,
            };
        }
        case actionsTypes.GET_ASSESSMENT_FAILURE: {
            return {
                ...state,
                error: action.payload.error,
            };
        }
        case actionsTypes.GET_COMPANY_ASSESSMENTS: {
            const { companyId } = action.payload;
            return {
                ...state,
                byCompanyId: {
                    ...state.byCompanyId,
                    [companyId]: {
                        ...state.byCompanyId[companyId],
                        isFetching: true,
                    },
                },
                error: null,
            };
        }
        case actionsTypes.GET_COMPANY_ASSESSMENTS_SUCCESS: {
            const { companyId, assessments } = action.payload;
            const assessmentsById = { ...state.byId };
            assessments.forEach((assessment: Assessment) => {
                assessmentsById[assessment.id] = {
                    data: assessment,
                    updating: false,
                    error: null,
                };
            });
            return {
                ...state,
                byCompanyId: {
                    ...state.byCompanyId,
                    [companyId]: {
                        list: assessments,
                        totalCount: assessments.length,
                        isFetching: false,
                    },
                },
                byId: assessmentsById,
                error: null,
            };
        }
        case actionsTypes.GET_COMPANY_ASSESSMENTS_FAILURE: {
            return {
                ...state,
                error: action.payload.error,
            };
        }

        case actionsTypes.CREATE_ASSESSMENT:
            return {
                ...state,
                creatingScan: true,
            };
        case actionsTypes.CREATE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                error: null,
                creatingScan: false,
            };
        case actionsTypes.CREATE_ASSESSMENT_FAILURE:
            const { error } = action.payload;
            return {
                ...state,
                creatingScan: false,
                error,
            };
        case actionsTypes.DELETE_SCAN: {
            return {
                ...state,
                deletingScan: true,
                error: null
            };
        }
        case actionsTypes.DELETE_SCAN_SUCCESS: {
            const { assessmentId } = action.payload;
            let nextState = {...state.byId};
            delete nextState[assessmentId];
            return {
                ...state,
                byId:nextState,
                deletingScan: false,
                error: null
            };
        }
        case actionsTypes.DELETE_SCAN_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                deletingScan: false,
                error: error
            };
        }
        case actionsTypes.UPDATE_ASSESSMENT_CONFIG: {
            const { assessmentId } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [assessmentId]: {
                        ...state.byId[assessmentId],
                        updating: true
                    }
                },
                error: null
            };
        }
        case actionsTypes.UPDATE_ASSESSMENT_CONFIG_SUCCESS: {
            const { assessmentId, assessment } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [assessmentId]: {
                        data: {
                            ...state.byId[assessmentId].data,
                            name: assessment.name
                        },
                        updating: false,
                        error: null
                    }
                },
                error: null
            };
        }
        case actionsTypes.UPDATE_ASSESSMENT_CONFIG_FAILURE: {
            const { assessmentId, error } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [assessmentId]: {
                        ...state.byId[assessmentId],
                        updating: false,
                        error
                    }
                },
                error: null
            };
        }
        case actionsTypes.CREATE_SCAN:
            return {
                ...state,
                isLoading: true,
            };
        case actionsTypes.CREATE_SCAN_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case actionsTypes.CREATE_SCAN_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default assessmentsReducer;
