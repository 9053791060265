import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth0Client } from 'Auth';
import { initAuth0, storeUser } from '../store/auth/auth.actions';
import { selectAuthState } from '../store/auth/auth.selectors';

const useAuth = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(selectAuthState);

    const onRedirectCallback = async () => {
        try {
            if (auth0Client) {
                const { appState } = await auth0Client.handleRedirectCallback();
                const isAuthenticated: boolean =
                    await auth0Client.isAuthenticated();
                if (isAuthenticated && !user) {
                    dispatch(storeUser());
                }
                return appState;
            }
        } catch (error) {
            // todo: log
        }
    };

    useEffect(() => {
        dispatch(initAuth0());
    }, [dispatch]);

    return { onRedirectCallback };
};

export default useAuth;
