import React, { useEffect, useState } from "react";
import styles from "./CompaniesModals.module.css";
import DialogComponent from "components/Dialog/Dialog";
import { AddIcon } from "evergreen-ui";
import { NewCompanySchema } from "typings/company";

interface CompanyModalsProps {
  onDiscardModal: () => void;
  shouldShowCreateCompanyModal: boolean;
  companyToCreate: NewCompanySchema;
  onConfirmCreateCompany: () => void;
  logo: string | undefined;
}

const CompaniesModals: React.FC<CompanyModalsProps> = (
  props: CompanyModalsProps
) => {
  let [shouldShowCreateUserModal, setShouldShowCreateUserModal] =
    useState(false);

  useEffect(() => {
    if (props.shouldShowCreateCompanyModal !== shouldShowCreateUserModal) {
      setShouldShowCreateUserModal(props.shouldShowCreateCompanyModal);
    }
  }, [props.shouldShowCreateCompanyModal, shouldShowCreateUserModal]);

  const discardModals = () => {
    setShouldShowCreateUserModal(false);
    props.onDiscardModal();
  };

  return (
    <React.Fragment>
      {shouldShowCreateUserModal && (
        <DialogComponent
          title="Confirm to create new company"
          confirmIcon={AddIcon}
          confirmButtonText="Create company"
          isShown={shouldShowCreateUserModal}
          onClose={() => discardModals()}
          onConfirm={() => props.onConfirmCreateCompany()}
        >
          <div className={styles.dialog__content}>
            Are you sure you want to create this company?
            <ul className={styles.dialog__list}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <li>
                        <b>Company:</b> {props.companyToCreate.name}
                      </li>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <li>
                        <b>Slug:</b> {props.companyToCreate.slug}
                      </li>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <li>
                        <b>Logo:</b> <br />
                        <img
                          className={styles.dialog__logoPreview}
                          src={props.logo}
                          alt="Company logo"
                        />
                      </li>
                    </td>
                  </tr>
                </tbody>
              </table>{" "}
            </ul>
          </div>
        </DialogComponent>
      )}
    </React.Fragment>
  );
};

export default CompaniesModals;
