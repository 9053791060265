import React, { useEffect, useState } from 'react';
import {
    Card,
    CardsWrapper,
    CardTitle,
    CheckMarkIcon,
    OpenedSection,
    IconsWrapper,
    ThirdStepDescription,
    ThirdStepWrapper,
    CardHeader,
    UploadSection,
    TabsWrapper,
    Tab,
    UploadTitle,
    UploadContainer,
    WrittenQuestion,
} from './style';
import {
    ButtonWrapper,
    PrevButton,
    ScanSetupFooterWrapper,
    ScanSetupHeaderTitle,
    ScanSetupHeaderTitleWrapper,
} from '../../../style';
import AppButton from '../../../../../components/AppButton/AppButton';
import ScanStepsFlow from '../../ScanStepsFlow';
import { ChevronDownIcon, ChevronUpIcon, TickIcon } from 'evergreen-ui';
import MediaLibrary from '../../../../../components/MediaLibrary';
import {
    CreateScanThirdStepProps,
    MediaLibraryVideo,
} from '../../../../../typings';

interface ISections {
    cultureFit: {
        isOpen: boolean;
        isCompleted?: boolean;
        video: MediaLibraryVideo;
    };
    speaking: {
        isOpen: boolean;
        isCompleted?: boolean;
        videos: {
            first: MediaLibraryVideo;
            second: MediaLibraryVideo;
        };
    };
    cognitiveAbilities: {
        isOpen: boolean;
    };
}

const ThirdStep = (props: CreateScanThirdStepProps) => {
    const {
        goToNextStep,
        goToPrevStep,
        companyId,
        isCultureFitQuestionnaireEnabled,
        isCultureFitVideoEnabled,
        isSpeakingEnabled,
        isCognitiveAbilitiesEnabled,
        cultureFitVideo,
        setCultureFitVideo,
        speakingVideo1,
        setSpeakingVideo1,
        speakingVideo2,
        setSpeakingVideo2,
        speakingWrittenQuestions,
        setSpeakingWrittenQuestions,
        cultureFitIsCompleted,
        setCultureFitIsCompleted,
        speakingIsCompleted,
        setSpeakingIsCompleted,
        newAssessmentSection,
        existingAssessmentSection,
        isScanSetupWizard,
    } = props;
    const [section, setSection] = useState<ISections>({
        cultureFit: {
            isOpen: false,
            isCompleted: cultureFitIsCompleted,
            video: {
                url: '',
                file: undefined,
                id: undefined,
            },
        },
        speaking: {
            isOpen: false,
            isCompleted: speakingIsCompleted,
            videos: {
                first: {
                    url: '',
                    file: undefined,
                    id: undefined,
                },
                second: {
                    url: '',
                    file: undefined,
                    id: undefined,
                },
            },
        },
        cognitiveAbilities: {
            isOpen: false,
        },
    });

    const handleActiveTab = (tab: string) => {
        if (tab === 'written-question') {
            setSpeakingWrittenQuestions(true);
        }
        if (tab === 'video-question') {
            setSpeakingWrittenQuestions(false);
        }
    };

    const handleOpenSection = (chosen: string) => {
        if (chosen === 'culture-fit') {
            setSection((state) => ({
                ...state,
                cultureFit: {
                    ...state.cultureFit,
                    isOpen: !section.cultureFit.isOpen,
                },
            }));
        }
        if (chosen === 'speaking') {
            setSection((state) => ({
                ...state,
                speaking: {
                    ...state.speaking,
                    isOpen: !section.speaking.isOpen,
                },
            }));
        }
        if (chosen === 'cognitive-abilities') {
            setSection((state) => ({
                ...state,
                cognitiveAbilities: {
                    ...state.cognitiveAbilities,
                    isOpen: !section.cognitiveAbilities.isOpen,
                },
            }));
        }
    };
    const handleConfirmation = (chosen: string) => {
        if (chosen === 'culture-fit') {
            setCultureFitVideo(section.cultureFit.video);
            setCultureFitIsCompleted(true);
            setSection((state) => ({
                ...state,
                cultureFit: {
                    ...state.cultureFit,
                    isCompleted: true,
                    isOpen: false,
                },
            }));
        }
        if (chosen === 'speaking-video-questions') {
            setSpeakingVideo1(section.speaking.videos.first);
            setSpeakingVideo2(section.speaking.videos.second);
            setSpeakingWrittenQuestions(false);
            setSpeakingIsCompleted(true);
            setSection((state) => ({
                ...state,
                speaking: {
                    ...state.speaking,
                    isCompleted: true,
                    isOpen: false,
                },
            }));
        }
        if (chosen === 'speaking-written-questions') {
            setSpeakingVideo1(undefined);
            setSpeakingVideo2(undefined);
            setSpeakingWrittenQuestions(true);
            setSpeakingIsCompleted(true);
            setSection((state) => ({
                ...state,
                speaking: {
                    ...state.speaking,
                    isCompleted: true,
                    isOpen: false,
                },
            }));
        }
    };

    useEffect(() => {
        if (!isCultureFitVideoEnabled && isCultureFitQuestionnaireEnabled) {
            setCultureFitVideo(undefined);
            setCultureFitIsCompleted(true);
        }
    }, [
        isCultureFitQuestionnaireEnabled,
        isCultureFitVideoEnabled,
        setCultureFitIsCompleted,
        setCultureFitVideo,
    ]);

    return (
        <>
            <ScanSetupHeaderTitleWrapper>
                <ScanSetupHeaderTitle>
                    Create a new scan
                </ScanSetupHeaderTitle>
                <AppButton
                    isDisabled={
                        (isScanSetupWizard &&
                            newAssessmentSection &&
                            (((isCultureFitQuestionnaireEnabled ||
                                isCultureFitVideoEnabled) &&
                                !cultureFitIsCompleted) ||
                                (isSpeakingEnabled && !speakingIsCompleted))) ||
                        (!isScanSetupWizard &&
                            (((isCultureFitQuestionnaireEnabled ||
                                isCultureFitVideoEnabled) &&
                                !cultureFitIsCompleted) ||
                                (isSpeakingEnabled && !speakingIsCompleted)))
                    }
                    onClick={goToNextStep}>
                    Next
                </AppButton>
            </ScanSetupHeaderTitleWrapper>
            <ScanStepsFlow
                isScanSetupWizard={isScanSetupWizard}
                active={['first','second','third']}
                existingAssessmentSection={existingAssessmentSection}
            />
            {((isScanSetupWizard && companyId && newAssessmentSection) ||
                !isScanSetupWizard) && (
                <ThirdStepWrapper>
                    <ThirdStepDescription>
                        Specify the selected modules
                    </ThirdStepDescription>
                    <CardsWrapper>
                        {(isCultureFitQuestionnaireEnabled ||
                            isCultureFitVideoEnabled) && (
                            <Card>
                                <CardHeader
                                    aria-label="Culture fit section header"
                                    onClick={() => {
                                        handleOpenSection('culture-fit');
                                    }}>
                                    <CardTitle>Cultural Fit</CardTitle>
                                    <IconsWrapper>
                                        <CheckMarkIcon
                                            completed={
                                                section.cultureFit
                                                    .isCompleted ||
                                                (isCultureFitQuestionnaireEnabled &&
                                                    !isCultureFitVideoEnabled)
                                            }>
                                            <TickIcon />
                                        </CheckMarkIcon>
                                        {!section.cultureFit.isOpen && (
                                            <ChevronUpIcon size={26} />
                                        )}
                                        {section.cultureFit.isOpen && (
                                            <ChevronDownIcon size={26} />
                                        )}
                                    </IconsWrapper>
                                </CardHeader>
                                {section.cultureFit.isOpen && (
                                    <OpenedSection aria-label="Culture fit section">
                                        {isCultureFitVideoEnabled && (
                                            <>
                                                <ThirdStepDescription>
                                                    Upload or select an existing
                                                    company video that your
                                                    candidates will watch during
                                                    the scan.
                                                </ThirdStepDescription>
                                                <MediaLibrary
                                                    onToUploadSelect={(
                                                        selectedFile,
                                                    ) => {
                                                        setSection((state) => ({
                                                            ...state,
                                                            cultureFit: {
                                                                ...state.cultureFit,
                                                                video: selectedFile,
                                                            },
                                                        }));
                                                    }}
                                                    onFileDeSelect={() => {
                                                        setSection((state) => ({
                                                            ...state,
                                                            cultureFit: {
                                                                ...state.cultureFit,
                                                                videoFile:
                                                                    undefined,
                                                                video: {
                                                                    url: '',
                                                                    file: undefined,
                                                                    id: undefined,
                                                                },
                                                            },
                                                        }));
                                                    }}
                                                    preselectedVideo={
                                                        cultureFitVideo
                                                            ? cultureFitVideo
                                                            : section.cultureFit
                                                                  .video
                                                    }
                                                    companyId={companyId}
                                                />
                                                <div style={{ float: 'right' }}>
                                                    <AppButton
                                                        aria-label="Culture fit confirmation"
                                                        isDisabled={
                                                            !section.cultureFit
                                                                .video.url
                                                        }
                                                        onClick={() => {
                                                            handleConfirmation(
                                                                'culture-fit',
                                                            );
                                                        }}>
                                                        Confirm
                                                    </AppButton>
                                                </div>
                                            </>
                                        )}
                                        {isCultureFitQuestionnaireEnabled &&
                                            !isCultureFitVideoEnabled && (
                                                <ThirdStepDescription>
                                                    Nothing to select here. Your
                                                    candidates will get a
                                                    questionnaire to learn more
                                                    about their work
                                                    preferences.
                                                </ThirdStepDescription>
                                            )}
                                    </OpenedSection>
                                )}
                            </Card>
                        )}
                        {isSpeakingEnabled && (
                            <Card>
                                <CardHeader
                                    aria-label="Speaking section header"
                                    onClick={() => {
                                        handleOpenSection('speaking');
                                    }}>
                                    <CardTitle>Speaking</CardTitle>
                                    <IconsWrapper>
                                        <CheckMarkIcon
                                            completed={
                                                section.speaking.isCompleted
                                            }>
                                            <TickIcon />
                                        </CheckMarkIcon>
                                        {!section.speaking.isOpen && (
                                            <ChevronUpIcon size={26} />
                                        )}
                                        {section.speaking.isOpen && (
                                            <ChevronDownIcon size={26} />
                                        )}
                                    </IconsWrapper>
                                </CardHeader>
                                {section.speaking.isOpen && (
                                    <OpenedSection aria-label="Speaking section">
                                        <ThirdStepDescription>
                                            Your candidates will be asked two
                                            questions to answer in a video
                                            recording. Use our textual questions
                                            or have a look at our
                                            <a
                                                href="https://s3.eu-west-1.amazonaws.com/public.neurolytics/neurolytics/Neurolytics+Guide-+How+to+record+speaking+videos.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: '#00a5ac' }}>
                                                {' '}
                                                video guide
                                            </a>
                                            , to create and upload your own
                                            video.
                                        </ThirdStepDescription>
                                        <UploadSection>
                                            <TabsWrapper>
                                                <Tab
                                                    aria-label="Speaking written question tab"
                                                    active={
                                                        speakingWrittenQuestions
                                                    }
                                                    onClick={() =>
                                                        handleActiveTab(
                                                            'written-question',
                                                        )
                                                    }>
                                                    Written questions
                                                </Tab>
                                                <Tab
                                                    aria-label="Speaking video question tab"
                                                    active={
                                                        !speakingWrittenQuestions
                                                    }
                                                    onClick={() =>
                                                        handleActiveTab(
                                                            'video-question',
                                                        )
                                                    }>
                                                    Video questions
                                                </Tab>
                                            </TabsWrapper>
                                            {!speakingWrittenQuestions && (
                                                <>
                                                    <UploadTitle className="speaking-title">
                                                        Video 1: - Why do you
                                                        want to work for this
                                                        company?
                                                    </UploadTitle>
                                                    <UploadContainer aria-label="Speaking video question content">
                                                        <MediaLibrary
                                                            onToUploadSelect={(
                                                                selectedFile,
                                                            ) => {
                                                                setSection(
                                                                    (
                                                                        state,
                                                                    ) => ({
                                                                        ...state,
                                                                        speaking:
                                                                            {
                                                                                ...state.speaking,
                                                                                videos: {
                                                                                    ...state
                                                                                        .speaking
                                                                                        .videos,
                                                                                    first: selectedFile,
                                                                                },
                                                                            },
                                                                    }),
                                                                );
                                                            }}
                                                            onFileDeSelect={() => {
                                                                setSection(
                                                                    (
                                                                        state,
                                                                    ) => ({
                                                                        ...state,
                                                                        speaking:
                                                                            {
                                                                                ...state.speaking,
                                                                                videos: {
                                                                                    ...state
                                                                                        .speaking
                                                                                        .videos,
                                                                                    first: {
                                                                                        url: '',
                                                                                        file: undefined,
                                                                                        id: undefined,
                                                                                    },
                                                                                },
                                                                            },
                                                                    }),
                                                                );
                                                            }}
                                                            preselectedVideo={
                                                                speakingVideo1
                                                                    ? speakingVideo1
                                                                    : section
                                                                          .speaking
                                                                          .videos
                                                                          .first
                                                            }
                                                            companyId={
                                                                companyId
                                                            }
                                                        />
                                                    </UploadContainer>
                                                    <UploadTitle className="speaking-title">
                                                        Video 2: - What can you
                                                        bring to the company in
                                                        your role?
                                                    </UploadTitle>
                                                    <UploadContainer>
                                                        <MediaLibrary
                                                            onToUploadSelect={(
                                                                selectedFile,
                                                            ) => {
                                                                setSection(
                                                                    (
                                                                        state,
                                                                    ) => ({
                                                                        ...state,
                                                                        speaking:
                                                                            {
                                                                                ...state.speaking,
                                                                                videos: {
                                                                                    ...state
                                                                                        .speaking
                                                                                        .videos,
                                                                                    second: selectedFile,
                                                                                },
                                                                            },
                                                                    }),
                                                                );
                                                            }}
                                                            onFileDeSelect={() => {
                                                                setSection(
                                                                    (
                                                                        state,
                                                                    ) => ({
                                                                        ...state,
                                                                        speaking:
                                                                            {
                                                                                ...state.speaking,
                                                                                videos: {
                                                                                    ...state
                                                                                        .speaking
                                                                                        .videos,
                                                                                    second: {
                                                                                        url: '',
                                                                                        file: undefined,
                                                                                        id: undefined,
                                                                                    },
                                                                                },
                                                                            },
                                                                    }),
                                                                );
                                                            }}
                                                            preselectedVideo={
                                                                speakingVideo2
                                                                    ? speakingVideo2
                                                                    : section
                                                                          .speaking
                                                                          .videos
                                                                          .second
                                                            }
                                                            companyId={
                                                                companyId
                                                            }
                                                        />
                                                    </UploadContainer>
                                                    <div
                                                        style={{
                                                            float: 'right',
                                                        }}>
                                                        <AppButton
                                                            isDisabled={
                                                                !section
                                                                    .speaking
                                                                    .videos
                                                                    .first
                                                                    .url ||
                                                                !section
                                                                    .speaking
                                                                    .videos
                                                                    .second.url
                                                            }
                                                            onClick={() => {
                                                                handleConfirmation(
                                                                    'speaking-video-questions',
                                                                );
                                                            }}>
                                                            Confirm
                                                        </AppButton>
                                                    </div>
                                                </>
                                            )}
                                            {speakingWrittenQuestions && (
                                                <>
                                                    <WrittenQuestion aria-label="Speaking written question content">
                                                        <div>
                                                            Your candidates will
                                                            see the following
                                                            two questions:
                                                        </div>
                                                        <div className="written-desc">
                                                            1) Why do you want
                                                            to work for this
                                                            company?
                                                            <br />
                                                            2) What can you
                                                            bring to the company
                                                            in your role?
                                                        </div>
                                                    </WrittenQuestion>

                                                    <div
                                                        style={{
                                                            float: 'right',
                                                        }}>
                                                        <AppButton
                                                            isDisabled={
                                                                !speakingWrittenQuestions
                                                            }
                                                            onClick={() => {
                                                                handleConfirmation(
                                                                    'speaking-written-questions',
                                                                );
                                                            }}>
                                                            Confirm
                                                        </AppButton>
                                                    </div>
                                                </>
                                            )}
                                        </UploadSection>
                                    </OpenedSection>
                                )}
                            </Card>
                        )}
                        {isCognitiveAbilitiesEnabled && (
                            <Card>
                                <CardHeader
                                    onClick={() => {
                                        handleOpenSection(
                                            'cognitive-abilities',
                                        );
                                    }}>
                                    <CardTitle>Cognitive Abilities</CardTitle>
                                    <IconsWrapper>
                                        <CheckMarkIcon completed={true}>
                                            <TickIcon />
                                        </CheckMarkIcon>
                                        {!section.cognitiveAbilities.isOpen && (
                                            <ChevronUpIcon size={26} />
                                        )}
                                        {section.cognitiveAbilities.isOpen && (
                                            <ChevronDownIcon size={26} />
                                        )}
                                    </IconsWrapper>
                                </CardHeader>
                                {section.cognitiveAbilities.isOpen && (
                                    <OpenedSection>
                                        <ThirdStepDescription>
                                            Nothing to select here. Your
                                            candidates will get a time
                                            questionnaire to learn more about
                                            their cognitive abilities.
                                        </ThirdStepDescription>
                                    </OpenedSection>
                                )}
                            </Card>
                        )}
                    </CardsWrapper>
                </ThirdStepWrapper>
            )}
            <ScanSetupFooterWrapper>
                <ButtonWrapper>
                    <PrevButton>
                        <AppButton color={'gray'} onClick={goToPrevStep}>
                            Previous
                        </AppButton>
                    </PrevButton>
                    <AppButton
                        isDisabled={
                            (isScanSetupWizard &&
                                newAssessmentSection &&
                                (((isCultureFitQuestionnaireEnabled ||
                                    isCultureFitVideoEnabled) &&
                                    !cultureFitIsCompleted) ||
                                    (isSpeakingEnabled &&
                                        !speakingIsCompleted))) ||
                            (!isScanSetupWizard &&
                                (((isCultureFitQuestionnaireEnabled ||
                                    isCultureFitVideoEnabled) &&
                                    !cultureFitIsCompleted) ||
                                    (isSpeakingEnabled &&
                                        !speakingIsCompleted)))
                        }
                        onClick={goToNextStep}>
                        Next
                    </AppButton>
                </ButtonWrapper>
            </ScanSetupFooterWrapper>
        </>
    );
};

export default ThirdStep;
