import React, { useState } from 'react';
import { i18n as I18n } from 'i18next';
import i18n from '../../i18n';
import { Container } from './LanguageSwitcher.style';

import { Button, Menu, MenuItem } from '@material-ui/core';
import { GlobeNetworkIcon } from 'evergreen-ui';

interface LanguageSwitcherProps {
    secondI18n?: I18n;
}

function LanguageSwitcher(props: LanguageSwitcherProps) {
    const { secondI18n } = props;
    const languages = {
        'nl-NL': { nativeName: 'Nederlands' },
        'en-US': { nativeName: 'English' },
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLanguageChange = (language: 'nl-NL' | 'en-US') => {
        i18n.changeLanguage(language);
        secondI18n && secondI18n.changeLanguage(language);
        setAnchorEl(null);
    };

    return (
        <Container>
            <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={false}
                onClick={handleClick}>
                <GlobeNetworkIcon size={20} />
                &nbsp;
                {languages[i18n.language as 'nl-NL' | 'en-US'].nativeName ===
                'Nederlands'
                    ? 'English'
                    : 'Nederlands'}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                disableScrollLock={true}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {Object.keys(languages).map((language) => (
                    <MenuItem
                        key={language}
                        onClick={() =>
                            handleLanguageChange(language as 'nl-NL' | 'en-US')
                        }
                        selected={i18n.language === language}>
                        {languages[language as 'nl-NL' | 'en-US'].nativeName}
                    </MenuItem>
                ))}
            </Menu>
        </Container>
    );
}

export default LanguageSwitcher;
