export const authActionTypes = {
    INIT_AUTH0: 'AUTH/INIT_AUTH0',
    INIT_AUTH0_SUCCESS: 'AUTH/INIT_AUTH0_SUCCESS',
    INIT_AUTH0_FAILURE: 'AUTH/INIT_AUTH0_FAILURE',
    INIT_LOGIN: 'AUTH/INIT_LOGIN',
    STORE_USER: 'AUTH/STORE_USER',
    STORE_USER_SUCCESS: 'AUTH/STORE_USER_SUCCESS',
    STORE_USER_FAILURE: 'AUTH/STORE_USER_FAILURE',
};

export const initAuth0 = () => ({ type: authActionTypes.INIT_AUTH0 });

export const initLogin = (params?: { mode?: string; returnUrl?: string }) => ({
    type: authActionTypes.INIT_LOGIN,
    payload: params ? { mode: params.mode, returnUrl: params.returnUrl } : {},
});

export const storeUser = () => ({ type: authActionTypes.STORE_USER });
