import { actionsTypes } from './users.actions';
import { Participant, User } from 'typings/user';
import { UserInvitation } from '../../typings/target_group';

export interface UsersState {
    byTargetGroup: {
        [targetGroupId: string]: {
            [userId: string]: Participant;
        };
    };
    fetchingUsers: boolean;
    error: any;
}
export const usersInitialState = {
    byTargetGroup: {},
    fetchingUsers: false,
    error: null,
};

const usersReducer = (
    state: UsersState = usersInitialState,
    action: { type: string; payload: any },
) => {
    switch (action.type) {
        case actionsTypes.GET_TARGET_GROUP_USERS:
        case actionsTypes.GET_ALL_USERS_INVITATIONS_IN_GROUP:
        case actionsTypes.GET_CANDIDATE: {
            return {
                ...state,
                fetchingUsers: true,
            };
        }

        case actionsTypes.GET_TARGET_GROUP_USERS_SUCCESS: {
            const { targetGroupId, users } = action.payload;

            const participants: {
                [userId: string]: Participant;
            } = {};

            users.forEach((user: any) => {
                participants[user.id] = {
                    id: user.id,
                    email: user.email,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    created_at: user.created_at,
                    updated_at: user.updated_at,
                };
            });
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...participants,
                    },
                },
                fetchingUsers: false,
            };
        }
        case actionsTypes.GET_ALL_USERS_INVITATIONS_IN_GROUP_SUCCESS: {
            const { targetGroupId, users, invitations } = action.payload;

            const participants: {
                [userId: string]: Participant;
            } = {};

            users.forEach((user: User) => {
                const userInvitation = invitations.find(
                    (invitation: UserInvitation) =>
                        invitation.user_id === user.id,
                );
                participants[user.id] = {
                    ...user,
                    invitation_created_at: userInvitation.invitation_created_at,
                    status: userInvitation.status,
                };
            });
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...participants,
                    },
                },
                fetchingUsers: false,
            };
        }
        case actionsTypes.GET_CANDIDATE_SUCCESS: {
            const { targetGroupId, users } = action.payload;

            const participants: {
                [userId: string]: Participant;
            } = {};

            participants[users[0].id] = {
                id: users[0].id,
                email: users[0].email,
                first_name: users[0].first_name,
                last_name: users[0].last_name,
                created_at: users[0].created_at,
                updated_at: users[0].updated_at,
            };
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...state.byTargetGroup[targetGroupId],
                        ...participants,
                    },
                },
                fetchingUsers: false,
            };
        }

        case actionsTypes.GET_TARGET_GROUP_USERS_FAILURE:
        case actionsTypes.GET_ALL_USERS_INVITATIONS_IN_GROUP_FAILURE:
        case actionsTypes.GET_CANDIDATE_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                fetchingUsers: false,
                error,
            };
        }

        case actionsTypes.REMOVE_PARTICIPANT: {
            const { targetGroupId, userId } = action.payload;
            const participants = { ...state.byTargetGroup[targetGroupId] };
            delete participants[userId];
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: participants,
                },
            };
        }

        case actionsTypes.GET_USER_ACTIVITY: {
            const { targetGroupId, userId } = action.payload;
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...state.byTargetGroup[targetGroupId],
                        [userId]: {
                            ...(state.byTargetGroup[targetGroupId] || {})[
                                userId
                            ],
                            loading_activity: true,
                            error: false,
                        },
                    },
                },
            };
        }
        case actionsTypes.GET_USER_ACTIVITY_SUCCESS: {
            const { targetGroupId, userId, activity } = action.payload;
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...state.byTargetGroup[targetGroupId],
                        [userId]: {
                            ...state.byTargetGroup[targetGroupId][userId],
                            activity,
                            loading_activity: false,
                            error: false,
                        },
                    },
                },
            };
        }
        case actionsTypes.GET_USER_ACTIVITY_FAILURE: {
            const { targetGroupId, userId } = action.payload;
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...state.byTargetGroup[targetGroupId],
                        [userId]: {
                            ...state.byTargetGroup[targetGroupId][userId],
                            loading_activity: false,
                            error: true,
                        },
                    },
                },
            };
        }

        case actionsTypes.GET_USER_EMAIL_INFO: {
            const { targetGroupId, userId } = action.payload;
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...state.byTargetGroup[targetGroupId],
                        [userId]: {
                            ...state.byTargetGroup[targetGroupId][userId],
                            loading_emails_info: true,
                            error: false,
                        },
                    },
                },
            };
        }
        case actionsTypes.GET_USER_EMAIL_INFO_SUCCESS: {
            const { targetGroupId, userId, emails } = action.payload;
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...state.byTargetGroup[targetGroupId],
                        [userId]: {
                            ...state.byTargetGroup[targetGroupId][userId],
                            emails_info: emails,
                            loading_emails_info: false,
                            error: false,
                        },
                    },
                },
            };
        }
        case actionsTypes.GET_USER_EMAIL_INFO_FAILURE: {
            const { targetGroupId, userId } = action.payload;
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...state.byTargetGroup[targetGroupId],
                        [userId]: {
                            ...state.byTargetGroup[targetGroupId][userId],
                            emails_info: null,
                            loading_emails_info: false,
                            error: true,
                        },
                    },
                },
            };
        }

        case actionsTypes.SEND_EMAIL: {
            const { targetGroupId, userId, email } = action.payload;
            const sendingStatus = {};

            if (email === 'reminder') {
                // @ts-ignore
                sendingStatus['sending_reminder'] = true;
            } else if (email === 'report') {
                // @ts-ignore
                sendingStatus['sending_report'] = true;
            } else if (email === 'resetPassword') {
                // @ts-ignore
                sendingStatus['sending_reset_password'] = true;
            }
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...state.byTargetGroup[targetGroupId],
                        [userId]: {
                            ...state.byTargetGroup[targetGroupId][userId],
                            ...sendingStatus,
                        },
                    },
                },
            };
        }

        case actionsTypes.SEND_EMAIL_SUCCESS: {
            const { targetGroupId, userId, email, response } = action.payload;
            const { not_sent } = response;

            const sendingStatus = {};
            if (not_sent) {
                // @ts-ignore
                sendingStatus['reportsNotSent'] = not_sent;
            }

            if (email === 'reminder') {
                // @ts-ignore
                sendingStatus['sending_reminder'] = false;
            } else if (email === 'report') {
                // @ts-ignore
                sendingStatus['sending_report'] = false;
            } else if (email === 'resetPassword') {
                // @ts-ignore
                sendingStatus['sending_reset_password'] = false;
            }
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...state.byTargetGroup[targetGroupId],
                        [userId]: {
                            ...state.byTargetGroup[targetGroupId][userId],
                            ...sendingStatus,
                        },
                    },
                },
            };
        }

        case actionsTypes.SEND_EMAIL_FAILURE: {
            const { targetGroupId, userId, email, error } = action.payload;
            const sendingStatus = {};

            if (email === 'reminder') {
                // @ts-ignore
                sendingStatus['sending_reminder'] = false;
            } else if (email === 'report') {
                // @ts-ignore
                sendingStatus['sending_report'] = false;
            } else if (email === 'resetPassword') {
                // @ts-ignore
                sendingStatus['sending_reset_password'] = false;
            }
            return {
                ...state,
                byTargetGroup: {
                    ...state.byTargetGroup,
                    [targetGroupId]: {
                        ...state.byTargetGroup[targetGroupId],
                        [userId]: {
                            ...state.byTargetGroup[targetGroupId][userId],
                            ...sendingStatus,
                            error,
                        },
                    },
                },
            };
        }

        default:
            return state;
    }
};

export default usersReducer;
