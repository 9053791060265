import styled from 'styled-components';

export const CardList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
    padding: 25px 0;
    row-gap: 25px;
    column-gap: 25px;
    position: relative;
    a {
        text-decoration: none;
    }
`;
export const Card = styled.div`
    cursor: pointer;
    position: relative;
`;
export const CheckboxWrapper = styled.div`
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    right: 6px;
    top: 4px;
`;
export const Icon = styled.svg<{ checked: boolean }>`
    fill: none;
    stroke: white;
    stroke-width: 2px;
    .radioDot {
        fill: ${(props) =>
            props.checked ? '#00A5AC' : 'rgba(67, 89, 112, 0.5)'};
        opacity: ${(props) => (props.checked ? '1' : '0.5')};
        &:hover {
            opacity: 1;
        }
    }
`;
export const HiddenCheckBox = styled.input.attrs({ type: 'radio' })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;
export const StyledCheckbox = styled.div<{ checked: boolean }>`
    display: inline-block;
    width: 26px;
    height: 26px;
    background-color: white;
    opacity: ${(props) => (props.checked ? '1' : '0.5')};
    border-radius: 50%;
    transition: all 150ms;
    border: ${(props) =>
        props.checked
            ? '1px solid #00A5AC'
            : '1px solid rgba(67, 89, 112, 0.5)'};
    &:hover {
        opacity: 1;
    }
`;
