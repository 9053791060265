import styled from 'styled-components';

interface IProps {
    completed?: boolean;
    active?: boolean;
    isEmpty?: boolean;
}

export const ScanDetailsWrapper = styled('div')`
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 24px 0;
    width: 100%;
`;

export const ScanDetailsHeaderBackWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 156px;
    margin-bottom: 24px;
    color: #849cb1;
    &:hover {
        color: #435970;
        cursor: pointer;
    }
`;

export const ScanTitleSection = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 56px;
    margin-bottom: 56px;
`;
export const ScanTitleWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
`;
export const ScanTitle = styled('div')`
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #40596f;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.secondary-title {
        font-size: 24px;
        line-height: 28px;
    }
`;
export const ScanSubTitle = styled('div')`
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    &.secondary-sub {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #435970;
        width: 50%;
    }
    span {
        color: #0ba6ac;
        &.secondary-sub {
            color: #435970;
        }
    }
`;
export const ScanTitleEditWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
`;
export const ScanTitleEdit = styled('span')`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding-left: 4px;
    color: rgba(67, 89, 112, 0.8);
    &:hover {
        cursor: pointer;
        color: rgba(67, 89, 112, 1);
    }
`;
export const ScanTargetGroupsSection = styled('div')<IProps>`
    margin: 16px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
    padding-top: 25px;
    padding-bottom: 80px;
    row-gap: 25px;
    column-gap: 25px;
    border-bottom: 1px solid #bbc9d8;
    a {
        text-decoration: none;
    }
    ${({ isEmpty }) =>
        isEmpty &&
        `
          display: block;

  `}
`;
export const Empty = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    p {
        color: #a0abb7;
        max-width: 560px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }
`;
export const ScanModulesSection = styled('div')`
    margin-top: 68px;
`;

export const ScanModuleDescription = styled('div')`
    color: #435970;
    font-size: 16px;
    padding-top: 8px;
    font-weight: normal;
    max-width: 40%;
    line-height: 28px;
    &.secondary-desc {
        max-width: 70%;
    }
`;
export const CardsWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 72px;
    padding: 32px 0 80px 0;
    border-bottom: 1px solid #bbc9d8;
`;
export const CardTitle = styled('div')`
    color: #435970;
    font-size: 20px;
    font-weight: bold;
`;
export const Card = styled('div')`
    width: 100%;
    border: 1px solid transparent;
    padding: 24px 32px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    background-color: white;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    }
`;
export const CardHeader = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    min-height: 56px;
`;
export const OpenedSection = styled('div')`
    width: 100%;
    min-height: 100px;
    padding: 16px 24px 16px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;
export const VideoSection = styled('div')`
    max-width: 500px;
`;
export const TabsWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 0;
`;
export const Tab = styled('div')<IProps>`
    border: 1px solid #a0abb7;
    width: 148px;
    height: 40px;
    color: #a0abb7;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${({ active }) =>
        active &&
        `
        border: 1px solid #a0abb7;
        background-color: #a0abb7;
        color: white;
  `}
`;

export const VideoTitle = styled('div')`
    color: #435970;
    font-size: 16px;
    padding: 16px;
`;

export const WrittenQuestion = styled('div')`
    line-height: 44px;
    color: #435970;
    padding-right: 60px;
`;
export const ScanModuleCheckboxWrapper = styled('div')`
    padding-top: 24px;
    font-size: 16px;
`;
export const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`;
export const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;
export const StyledCheckbox = styled.div<{ checked: boolean }>`
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: ${(props) => (props.checked ? '#a0abb7' : 'white')};
    border-radius: 3px;
    border: ${(props) =>
        props.checked ? '1px solid #a0abb7' : '1px solid #435970'};
    transition: all 150ms;
    ${Icon} {
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    }
`;
export const Label = styled.label<{ checked: boolean }>`
    text-decoration: ${(props) => (props.checked ? 'none' : 'line-through')};
`;
export const VideoPreviewWrapper = styled('div')`
    max-width: 500px;
`;
export const ScanDeleteSection = styled('div')`
    margin: 32px 0;
    h3 {
        font-size: 24px;
        color: #40596f;
        font-weight: bold;
    }

    p {
        padding: 16px 0 24px 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        color: #435970;
        width: 50%;
    }
`;
