import styled from 'styled-components';

export const CandidateInfoModalWrapper = styled.div`
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: rgba(67, 89, 112, 0.87);
`;
export const CloseIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 16px 0 0;
    background-color: white;
`;
export const CandidateInfoSection = styled('div')`
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    width: 45%;
    outline: none;
    border: 1px solid #849cb1;
    border-radius: 12px;
    background-color: rgba(244, 246, 250);
    overflow-y: auto;
    max-height: 90vh;
    height: auto;
    margin: auto;
`;

export const CandidateInfoTitleSection = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 56px;
    &.main-section {
        border: none;
        background-color: white;
    }
`;
export const CandidateInfoTitle = styled('div')`
    font-weight: 700;
    font-size: 22px;
    color: #40596f;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.main {
        padding: 0 32px;
        line-height: 30px;
    }
    &.secondary-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        padding-top: 16px;
    }
`;

export const SectionWrapper = styled('div')`
    padding: 8px 32px 32px 32px;
    &.info {
        padding: 8px 32px 32px 32px;
    }
    &.main-section {
        background-color: white;
    }
`;

export const DetailWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    //justify-content: flex-start;
    //gap: 20%;
    width: 100%;
    text-align: left;
`;

export const Detail = styled('span')`
    width: 68%;
    text-align: left;
    padding-top: 10px;
    line-height: 20px;
    &.name {
        width: 30%;
    }
    &.status-name {
        padding-top: 16px;
    }
    &.status-desc-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
    }

    &.status-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        line-height: 26px;
    }
    &.desc {
        color: rgba(64, 89, 111, 0.8);
        font-size: 12px;
        font-weight: 400;
    }
    .status {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        color: white;
        border-radius: 8px;
        padding: 8px 16px;
    }
`;

export const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;

export const Status = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    .green {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: rgba(26, 162, 119, 1);
    }
    .gray {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: rgb(187, 201, 216);
    }
    .red {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: rgba(255, 87, 86, 1);
    }
    .yellow {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: #fbe7a1;
    }
`;
