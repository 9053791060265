import {
    BaselineCategoryNames,
    BaselineRange,
    ResultsSections,
    WorkPreferenceBaselineData,
} from '../../typings';
import {
    CultureFitSummary,
    SpeakingSummary,
    CognitiveAbilitiesSummary,
    CultureFitEngagement,
    WorkPreferences,
    CultureFitMatch,
    SpeakingGeneralMotivation,
    SpeakingOverallConfidence,
    CognitiveAbilitiesStressResilience,
    CognitiveAbilitiesEaseUnderPressure,
    CognitiveAbilitiesCategoriesExplanations,
    ScoresAvailability,
    CultureFitImage,
    SpeakingImage,
    CognitiveAbilitiesImage,
    getCultureFitScores,
    getSpeakingScores,
    getCognitiveAbilitiesScores,
    ThriveAndStruggle,
    CognitiveAbilitiesExplanation,
    Score,
} from '@neurolytics/results-template';
import { TFunction } from 'react-i18next';

export const getResultSections = (
    expectedScores: ScoresAvailability,
    scores: Score[],
    companyName: string,
    workPreferenceBaseline: WorkPreferenceBaselineData,
    t: TFunction,
): ResultsSections => {
    const cultureFitScores = getCultureFitScores(scores);
    const speakingScores = getSpeakingScores(scores);
    const cognitiveAbilitiesScores = getCognitiveAbilitiesScores(scores);

    const sections: ResultsSections = {
        summary: {
            title: t('REPORT.SECTIONS.SUMMARY.TITLE'),
            tabs: [],
        },
    };

    if (
        expectedScores.cultureFit.companyEngagement ||
        expectedScores.cultureFit.workPreferences ||
        expectedScores.cultureFit.cultureMatch
    ) {
        sections.summary.tabs.push({
            title: t('REPORT.SECTIONS.CULTURE_FIT.TITLE'),
            id: 'summary-culture-fit',
            content: (
                <CultureFitSummary
                    expectedCultureFitScores={expectedScores.cultureFit}
                    companyName={companyName}
                    cultureFitScores={cultureFitScores}
                    userRole={'recruiter'}
                    hasBaseline={
                        !!workPreferenceBaseline &&
                        !!workPreferenceBaseline.length
                    }
                />
            ),
        });
        sections.cultureFit = {
            title: t('REPORT.SECTIONS.CULTURE_FIT.TITLE'),
            description: t('REPORT.SECTIONS.CULTURE_FIT.DESCRIPTION'),
            image: CultureFitImage,
            tabs: [],
        };

        if (expectedScores.cultureFit.companyEngagement) {
            sections.cultureFit.tabs.push({
                title: t('REPORT.SECTIONS.CULTURE_FIT.TAB_ENGAGEMENT'),
                id: 'culture-fit-engagement',
                content: (
                    <CultureFitEngagement
                        companyName={companyName}
                        engagementScore={cultureFitScores?.company_engagement}
                        userRole={'recruiter'}
                        isWeb={true}
                    />
                ),
            });
        }
        if (
            expectedScores.cultureFit.cultureMatch &&
            cultureFitScores &&
            cultureFitScores.categories &&
            cultureFitScores.culture_match
        ) {
            sections.cultureFit.tabs.push({
                title: t('REPORT.SECTIONS.CULTURE_FIT.TAB_CULTURE_MATCH'),
                id: 'culture-fit-match',
                content: (
                    <CultureFitMatch
                        companyName={companyName}
                        cultureFitCategories={cultureFitScores.categories}
                        matchScore={cultureFitScores.culture_match}
                        userRole={'recruiter'}
                        baseline={workPreferenceBaseline}
                        isWeb={true}
                    />
                ),
            });
        }
        if (
            expectedScores.cultureFit.workPreferences &&
            cultureFitScores &&
            cultureFitScores.categories
        ) {
            sections.cultureFit.tabs.push(
                {
                    title: t(
                        'REPORT.SECTIONS.CULTURE_FIT.TAB_WORK_PREFERENCES',
                    ),
                    id: 'culture-fit-work-preferences',
                    content: (
                        <WorkPreferences
                            companyName={companyName}
                            cultureFitCategories={cultureFitScores.categories}
                            isWeb={true}
                            hasBaseline={
                                !!workPreferenceBaseline &&
                                !!workPreferenceBaseline.length
                            }
                        />
                    ),
                },
                {
                    title: t('REPORT.SECTIONS.CULTURE_FIT.TAB_THRIVE_AND_GROW'),
                    id: 'culture-fit-thrive-and-grow',
                    content: (
                        <ThriveAndStruggle
                            companyName={companyName}
                            cultureFitCategories={cultureFitScores.categories}
                            isWeb={true}
                        />
                    ),
                },
            );
        }
    }

    if (
        expectedScores.speaking.motivation ||
        expectedScores.speaking.confidence
    ) {
        sections.summary.tabs.push({
            title: t('REPORT.SECTIONS.SPEAKING.TITLE'),
            id: 'summary-speaking',
            content: (
                <SpeakingSummary
                    speakingScores={speakingScores}
                    userRole={'recruiter'}
                />
            ),
        });
        sections.speaking = {
            title: t('REPORT.SECTIONS.SPEAKING.TITLE'),
            description: t('REPORT.SECTIONS.SPEAKING.DESCRIPTION'),
            image: SpeakingImage,
            tabs: [],
        };

        if (expectedScores.speaking.motivation) {
            sections.speaking.tabs.push({
                title: t('REPORT.SECTIONS.SPEAKING.TAB_MOTIVATION'),
                id: 'speaking-motivation',
                content: (
                    <SpeakingGeneralMotivation
                        companyName={companyName}
                        motivationScore={speakingScores?.work_motivation}
                        userRole={'recruiter'}
                        isWeb={true}
                    />
                ),
            });
        }
        if (expectedScores.speaking.confidence) {
            sections.speaking.tabs.push({
                title: t('REPORT.SECTIONS.SPEAKING.TAB_CONFIDENCE'),
                id: 'speaking-confidence',
                content: (
                    <SpeakingOverallConfidence
                        confidenceScore={speakingScores?.confidence}
                        userRole={'recruiter'}
                        isWeb={true}
                    />
                ),
            });
        }
    }

    if (
        expectedScores.cognitiveAbilities.stressResilience ||
        expectedScores.cognitiveAbilities.ease ||
        expectedScores.cognitiveAbilities.categories
    ) {
        sections.summary.tabs.push({
            title: t('REPORT.SECTIONS.COGNITIVE_ABILITIES.TITLE'),
            id: 'summary-cognitive-abilities',
            content: (
                <CognitiveAbilitiesSummary
                    userRole={'recruiter'}
                    mentalTestScores={cognitiveAbilitiesScores}
                    companyName={companyName}
                />
            ),
        });
        sections.cognitiveAbilities = {
            title: t('REPORT.SECTIONS.COGNITIVE_ABILITIES.TITLE'),
            description: t('REPORT.SECTIONS.COGNITIVE_ABILITIES.DESCRIPTION'),
            image: CognitiveAbilitiesImage,
            tabs: [],
        };

        if (expectedScores.cognitiveAbilities.stressResilience) {
            sections.cognitiveAbilities.tabs.push({
                title: t('REPORT.SECTIONS.COGNITIVE_ABILITIES.TAB_STRESS'),
                id: 'cognitive-abilities-stress',
                content: (
                    <CognitiveAbilitiesStressResilience
                        stressResilienceScore={
                            cognitiveAbilitiesScores?.stress_resilience
                        }
                        userRole={'recruiter'}
                        isWeb={true}
                    />
                ),
            });
        }
        if (expectedScores.cognitiveAbilities.ease) {
            sections.cognitiveAbilities.tabs.push({
                title: t('REPORT.SECTIONS.COGNITIVE_ABILITIES.TAB_EASE'),
                id: 'cognitive-abilities-ease',
                content: (
                    <CognitiveAbilitiesEaseUnderPressure
                        easeScore={cognitiveAbilitiesScores?.ease}
                        userRole={'recruiter'}
                        isWeb={true}
                    />
                ),
            });
        }

        if (expectedScores.cognitiveAbilities.categories) {
            sections.cognitiveAbilities.tabs.push({
                title: t(
                    'REPORT.SECTIONS.COGNITIVE_ABILITIES.TAB_COGNITIVE_SCORES',
                ),
                id: 'cognitive-abilities-cognitive-score',
                content: (
                    <>
                        <CognitiveAbilitiesExplanation
                            companyName={companyName}
                            mentalTestScores={cognitiveAbilitiesScores}
                        />
                        <CognitiveAbilitiesCategoriesExplanations />
                    </>
                ),
            });
        }
    }

    return sections;
};

export const getAllBaselineScoreRanges = (
    baseline: WorkPreferenceBaselineData | [],
): BaselineRange => {
    const getBaselineMinMax = (data: number[]): [number, number] => {
        const k = 1.28;
        const count = data.length;
        const mean = data.reduce((a, b) => a + b, 0) / count;

        const standardDeviation = Math.sqrt(
            data
                .map((score) => Math.pow(score - mean, 2))
                .reduce((a, b) => a + b, 0) /
                (count - 1),
        );

        return [mean - k * standardDeviation, mean + k * standardDeviation];
    };

    const baselineCategoryNames: BaselineCategoryNames[] = [
        'market',
        'adhocracy',
        'hierarchy',
        'family',
    ];
    const baselineRanges: Partial<BaselineRange> = {};
    const roundScore = (number: number | undefined) => {
        if (!number) {
            number = 0;
        }
        return Math.trunc(number);
    };

    baselineCategoryNames.forEach((categoryName: BaselineCategoryNames) => {
        const baselineScoreRange = getBaselineMinMax(
            baseline.map((data) => data[categoryName]),
        );
        baselineRanges[categoryName] = [
            roundScore((baselineScoreRange[0] / 16) * 100),
            roundScore((baselineScoreRange[1] / 16) * 100),
        ];
    });

    return baselineRanges as BaselineRange;
};
