import { authActionTypes } from './auth.actions';
// import { User } from '../../typings/user';

export interface AuthState {
  user: any; // todo
  isLoading: boolean;
  isAdmin: boolean;
  error: any;
}

export const authInitialState: AuthState = {
  // user: null,
  // user: {
  //   id: null,
  //   roles: [],
  //   email: null,
  //   email_verified: null,
  //   first_name: 'Local',
  //   last_name: 'admin',
  //   updated_at: '2021-07-19T18:28:50.349Z',
  //   permissions: [],
  //   target_group_permissions: null,
  //   company_id: null,
  // },
  user: null, // todo
  isLoading: true,
  isAdmin: false,
  error: null
};

const authReducer = (state: AuthState = authInitialState, action: {type: string; payload: any;}) => {
  switch (action.type) {
    case authActionTypes.INIT_AUTH0_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }

    case authActionTypes.INIT_AUTH0_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error,
      };
    }

    case authActionTypes.STORE_USER_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        isLoading: false,
        user,
      };
    }

    case authActionTypes.STORE_USER_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error,
      };
    }

    default:
      return state;
  }
};

export default authReducer;