import { NewCompanySchema } from 'typings/company';

export const actionsTypes = {
  GET_COMPANIES: 'COMPANIES/GET_COMPANIES',
  GET_COMPANIES_SUCCESS: 'COMPANIES/GET_COMPANIES_SUCCESS',
  GET_COMPANIES_FAILURE: 'COMPANIES/GET_COMPANIES_FAILURE',
  GET_COMPANY_BY_ID: 'COMPANIES/GET_COMPANY_BY_ID',
  GET_COMPANY_BY_ID_SUCCESS: 'COMPANIES/GET_COMPANY_BY_ID_SUCCESS',
  GET_COMPANY_BY_ID_FAILURE: 'COMPANIES/GET_COMPANY_BY_ID_FAILURE',
  CREATE_COMPANY: 'COMPANIES/CREATE_COMPANY',
  CREATE_COMPANY_SUCCESS: 'COMPANIES/CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILURE: 'COMPANIES/CREATE_COMPANY_FAILURE'
};

export const actions = {
  getCompanies: () => ({
    type: actionsTypes.GET_COMPANIES
  }),
  getCompanyById: (companyId: string) => ({
    type: actionsTypes.GET_COMPANY_BY_ID,
    payload: { companyId }
  }),
  createCompany: (company: NewCompanySchema) => ({
    type: actionsTypes.CREATE_COMPANY,
    payload: { company }
  })
};
