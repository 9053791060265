import { put, call } from 'redux-saga/effects';
import { actionsTypes } from './mediaFiles.actions';
import NeurolyticsAPI from 'services/NeurolyticsAPI';
import { MediaFile } from '../../typings/mediaFiles';

export function* getCompanyMediaFiles(action: { type: string; payload: any }) {
    try {
        const { companyId } = action.payload;

        const mediaFiles: MediaFile[] = yield call(
            [NeurolyticsAPI, 'fetchCompanyMediaFiles'],
            companyId,
        );
        yield put({
            type: actionsTypes.GET_MEDIA_FILES_SUCCESS,
            payload: { companyId, mediaFiles },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_MEDIA_FILES_FAILURE,
            payload: { error },
        });
    }
}
