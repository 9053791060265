import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initLogin } from 'store/auth/auth.actions';
import { useLocation } from 'react-router-dom';
import { selectAuthState } from 'store/auth/auth.selectors';
import { auth0Client } from 'Auth';

const Login = () => {
    const { user } = useSelector(selectAuthState);
    const dispatch = useDispatch();
    const query = new URLSearchParams(useLocation().search);
    const mode = query.get('mode');
    const returnUrl = query.get('return_url');

    useEffect(() => {
        if (auth0Client) {
            if (!user) {
                const loginParams: {
                    returnUrl?: string;
                    mode?: string;
                } = {};
                if (mode) {
                    loginParams.mode = mode;
                }
                if (returnUrl) {
                    loginParams.returnUrl = returnUrl;
                }
                dispatch(initLogin(loginParams));
            }
        }
    }, [dispatch, mode, returnUrl, user]);

    return <></>;
};

export default Login;
