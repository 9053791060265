import { combineReducers } from 'redux';
import { default as authReducer, authInitialState } from './auth/auth.reducers';
import {
    default as usersReducer,
    usersInitialState,
} from './users/users.reducers';
import {
    default as assessmentsReducer,
    assessmentsInitialState,
} from './assessments/assessments.reducers';
import {
    default as targetGroupsReducer,
    targetGroupsInitialState,
} from './targetGroups/targetGroups.reducers';
import {
    default as dashboardUsersReducer,
    dashboardUsersInitialState,
} from './dashboardUsers/dashboardUsers.reducer';
import {
    default as companiesReducer,
    companiesInitialState,
} from './companies/companies.reducer';
import {
    default as mediaFilesReducer,
    mediaFilesInitialState,
} from './mediaFiles/mediaFiles.reducers';

export const initialState = {
    auth: authInitialState,
    users: usersInitialState,
    assessments: assessmentsInitialState,
    targetGroups: targetGroupsInitialState,
    dashboardUsers: dashboardUsersInitialState,
    companies: companiesInitialState,
    mediaFiles: mediaFilesInitialState,
};

export const reducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    assessments: assessmentsReducer,
    targetGroups: targetGroupsReducer,
    dashboardUsers: dashboardUsersReducer,
    companies: companiesReducer,
    mediaFiles: mediaFilesReducer,
});

export type AppState = ReturnType<typeof reducer>;
