import styled from 'styled-components';

export const ScanSetupWrapper = styled('div')`
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 32px 56px 16px 56px;
    background-color: white;
    width: 100%;
`;
export const ScanSetupHeaderBackWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 156px;
    margin-bottom: 24px;
    color: #849cb1;
    &:hover {
        color: #435970;
        cursor: pointer;
    }
`;
export const ScanSetupHeaderTitleWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const ScanSetupHeaderTitle = styled('div')`
    color: #435970;
    font-weight: bold;
    font-size: 26px;
`;
export const ScanSetupFooterWrapper = styled('div')`
    border-top: 1px solid #435970;
`;
export const ButtonWrapper = styled('div')`
    padding: 24px 0;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
`;
export const PrevButton = styled('div')`
    display: block;
`;
export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    width: 100%;
    margin: 20px 0;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    color: #435970;
`;

export const Content = styled.div`
    margin: 25px;
    a {
        color: #435970;
        opacity: 0.8;
        &:hover {
            opacity: 0.6;
        }
    }
`;
