import styled from 'styled-components';

interface IProps {
    isActive?: boolean;
}

export const ContentHeaderWrapper = styled('div')<IProps>`
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    background-color: rgb(0, 165, 173);
    align-items: center;
    padding: 50px;
    grid-area: header;
`;
export const ContentHeaderTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 60%;
`;
export const ContentHeaderTitle = styled.div`
    color: white;
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 24px;
`;
export const ContentHeaderDescription = styled.h2`
    color: white;
    font-weight: 300;
    line-height: 1.4em;
    font-size: 14px;
`;
export const ContentHeaderPicture = styled.div`
    background-color: white;
    border-radius: 50%;
    height: 210px;
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 auto auto;
`;
export const Picture = styled.img`
    max-width: 225px;
`;
