import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticatedUserProps } from '../../hoc/WithAuthLayout';
import { useParams } from 'react-router-dom';
import {
    getExpectedScores,
    GlobalStateProvider, ReportData,
} from '@neurolytics/results-template';
import '@neurolytics/results-template/dist/esm/styles.css';
import { actions as userActions } from 'store/users/users.actions';
import { selectTargetGroupsById } from '../../store/users/users.selectors';
import { actions as assessmentActions } from '../../store/assessments/assessments.actions';
import { actions as targetGroupActions } from '../../store/targetGroups/targetGroups.actions';
import { selectCompaniesState } from '../../store/companies/companies.selectors';
import { selectAssessmentsById } from '../../store/assessments/assessments.selectors';
import { Company } from '../../store/companies/companies.reducer';
import ResultsTopBar from './components/ResultsTopBar';
import {
    selectTargetGroupDataById,
    selectTargetGroupWorkPreferenceBaseline,
} from '../../store/targetGroups/targetGroups.selectors';
import { ReportViewWrapper } from './Style';
import { ResultsNavBar } from './components/ResultsNavBar/ResultsNavBar';
import { SectionId } from '../../typings';
import ResultsSidebar from './components/ResultsSidebar';
import ResultsHeader from './components/ResultsHeader';
import { getAllBaselineScoreRanges, getResultSections } from './utils';
import ResultsContent from './components/ResultsContent';
import { useTranslation } from '../../i18n';

type ReportUrlParams = {
    assessmentId: string;
    targetGroupId: string;
    candidateId: string;
};

const ReportView: FunctionComponent<AuthenticatedUserProps> = () => {
    // const { userRoles } = props;
    const { assessmentId, targetGroupId, candidateId } =
        useParams<ReportUrlParams>();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const users = useSelector(selectTargetGroupsById(targetGroupId));
    const { companies } = useSelector(selectCompaniesState);
    const assessments = useSelector(selectAssessmentsById);
    const participantsList = useSelector(selectTargetGroupsById(targetGroupId));
    const workPreferenceBaseline =
        useSelector(selectTargetGroupWorkPreferenceBaseline(targetGroupId)) ||
        [];
    const targetGroup = useSelector(selectTargetGroupDataById(targetGroupId));
    const targetGroupName = targetGroup ? targetGroup.name : '';
    const participant =
        candidateId && participantsList && participantsList[candidateId];
    const participantReport =
        participant && participant.activity && participant.activity.report
            ? participant.activity.report
            : null;
    const [activeSection, setActiveSection] = useState<SectionId>('summary');

    const scores =
        candidateId &&
        users &&
        users[candidateId] &&
        users[candidateId].activity?.scores;
    const assessment =
        assessmentId &&
        assessments[assessmentId] &&
        assessments[assessmentId].data;

    const company = companies.find(
        (company: Company) =>
            assessment &&
            assessment.company_id &&
            company.id === assessment.company_id,
    );

    const candidate = users && candidateId && users[candidateId];

    const expectedScores =
        assessment &&
        assessment.modules &&
        getExpectedScores(
            assessment.modules,
            workPreferenceBaseline && !!workPreferenceBaseline.length,
        );

    const sections =
        expectedScores &&
        scores &&
        company &&
        getResultSections(
            expectedScores,
            scores,
            company.name,
            workPreferenceBaseline,
            t,
        );

    useEffect(() => {
        if (assessmentId && targetGroupId && candidateId) {
            dispatch(assessmentActions.getAssessment(assessmentId));
            dispatch(
                targetGroupActions.getTargetGroupById(
                    assessmentId,
                    targetGroupId,
                ),
            );
            dispatch(
                targetGroupActions.getWorkPreferenceBaseline(
                    assessmentId,
                    targetGroupId,
                ),
            );
            dispatch(userActions.getCandidate(candidateId, targetGroupId));
            dispatch(
                userActions.getUserActivity(targetGroupId, candidateId, true),
            );
        }
    }, [assessmentId, candidateId, dispatch, targetGroupId]);

    // check for query params
    if (!assessmentId || !targetGroupId || !candidateId) {
        return <div>Failed: No scan config!</div>;
    }

    // loading
    if (
        !assessment ||
        !assessment.modules ||
        !candidate ||
        !scores ||
        !company
    ) {
        return <div>Loading...</div>;
    }

    const downloadPdf = () => {
        participantReport && window.open(participantReport.report_url);
    };

    const baselineRange = getAllBaselineScoreRanges(workPreferenceBaseline);

    const initialReportData: ReportData = {
        baselineRange,
    };

    return (
        <>
            {sections && users && users[candidateId] && company && (
                <GlobalStateProvider initialData={initialReportData}>
                    <ReportViewWrapper
                        id="report_wrap"
                        aria-label="report view wrapper"
                        headerSize={activeSection === 'summary' ? 140 : 270}>
                        <ResultsTopBar
                            candidateFullName={`${candidate.first_name} ${candidate.last_name}`}
                            onDownload={downloadPdf}
                            targetGroupName={targetGroupName}
                            reportStatus={participantReport?.status}
                        />
                        <ResultsSidebar
                            sections={sections}
                            activeSection={activeSection}
                            handleSectionNavigation={setActiveSection}
                        />
                        <ResultsHeader
                            sections={sections}
                            activeSection={activeSection}
                        />
                        <ResultsNavBar
                            sections={sections}
                            activeSection={activeSection}
                        />
                        <ResultsContent
                            sections={sections}
                            activeSection={activeSection}
                        />
                    </ReportViewWrapper>
                </GlobalStateProvider>
            )}
        </>
    );
};

export default ReportView;
