export const actionsTypes = {
    GET_MEDIA_FILES: 'GET_MEDIA_FILES',
    GET_MEDIA_FILES_SUCCESS: 'GET_MEDIA_FILES_SUCCESS',
    GET_MEDIA_FILES_FAILURE: 'GET_MEDIA_FILES_FAILURE',
};

export const actions = {
    getCompanyMediaFiles: (companyId: string) => ({
        type: actionsTypes.GET_MEDIA_FILES,
        payload: { companyId },
    }),
};
