import React, { FunctionComponent, ReactNode } from "react";
import styles from './DashboardCard.module.css';
import Badge, { BadgeColors } from 'components/Badge/Badge';

export interface LabelObject {
  color: BadgeColors;
  text: string;
}

interface DashboardCardProps {
  children: ReactNode;
  labels: Array<LabelObject>;
}

const DashboardCard: FunctionComponent<DashboardCardProps> = (props) => {
  return (
    <div className={styles.card}>
      <div className={styles.card__header}>{props.children}</div>
      <div className={styles.card__badges}>
        {props.labels.map((label, i) => (
          <span key={`${i}-${label.color}-${label.text}`} className={styles.card__badge}>
            <Badge color={label.color}>{label.text}</Badge>
          </span>
        ))}
      </div>
    </div>
  );
};

export default DashboardCard;
