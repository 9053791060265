import React, { useState } from 'react';
import styles from 'views/ManageUsersView/AddUsersForm/AddUsersForm.module.css';
import AppButton from 'components/AppButton/AppButton';
import ManageUsersModals from '../ManageUsersModals/ManageUsersModals';

import Tooltip from '@material-ui/core/Tooltip';

import { AddIcon, DeleteIcon, TickCircleIcon, ErrorIcon, InfoSignIcon } from 'evergreen-ui';
import { Theme, withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { CreateDashboardUserSchema } from 'typings/dashboard_user';
import { actions as dashboardUsersActions } from 'store/dashboardUsers/dashboardUsers.actions';
import { AUTH0_COMPANY_ADMIN_ROLE_ID, AUTH0_RECRUITER_USER_ROLE_ID } from "utils/environment-variables";

interface AddUsersFormProps {
  cancelAddingUser: () => void;
  companyId: string;
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#435970',
    boxShadow: '0 0 3px 0 #435970',
    fontSize: 16,
    minWidth: 100,
    borderRadius: 9
  }
}))(Tooltip);

const AddUsersForm: React.FC<AddUsersFormProps> = (props) => {
  const { companyId, cancelAddingUser } = props;
  const dispatch = useDispatch();
  const basePermissions: string[] = [
    'read:company_assessments',
    'read:target_group',
    'read:assessment_target_groups',
    'read:email_info'
  ];

  const initialDashboardUserState = {
    email: '',
    first_name: '',
    last_name: '',
    role_id: '',
    permissions: basePermissions,
    app_metadata: {
      company_id: companyId,
      permissions: basePermissions,
      target_group_permissions: {
        all_target_groups: true,
        target_groups: []
      }
    }
  };

  const [dashboardUser, setDashboardUser] = useState<CreateDashboardUserSchema>(initialDashboardUserState);
  const [emailToValidate, setEmailToValidate] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean | null>(null);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean | null>(null);
  const [isRoleIdValid, setIsRoleIdValid] = useState<boolean | null>(null);
  const [shouldShowCreateUserModal, setShouldShowCreateUserModal] = useState<boolean>(false);

  const discardModals = () => {
    setShouldShowCreateUserModal(false);
  };

  const companyAdminRoleId = AUTH0_COMPANY_ADMIN_ROLE_ID || '';
  const recruiterUserRoleId = AUTH0_RECRUITER_USER_ROLE_ID || '';

  const handleUpdateUser = (
    key: 'email' | 'first_name' | 'last_name' | 'role_id' | 'permissions' | 'target_groups',
    value: any
  ) => {
    if (key === 'email') {
      setDashboardUser({ ...dashboardUser, email: value });
    }
    if (key === 'first_name') {
      setDashboardUser({ ...dashboardUser, first_name: value });
      if (value.length > 0) {
        setIsFirstNameValid(true);
      } else {
        setIsFirstNameValid(false);
      }
    }
    if (key === 'last_name') {
      setDashboardUser({ ...dashboardUser, last_name: value });
      if (value.length > 0) {
        setIsLastNameValid(true);
      } else {
        setIsLastNameValid(false);
      }
    }
    if (key === 'role_id') {
      if (value === companyAdminRoleId) {
        basePermissions.push('create:recruiter_user');
      }
      setDashboardUser({
        ...dashboardUser,
        role_id: value,
        permissions: basePermissions,
        app_metadata: { ...dashboardUser.app_metadata, permissions: basePermissions }
      });
      if (value.length > 0) {
        setIsRoleIdValid(true);
      } else {
        setIsRoleIdValid(false);
      }
    }
  };

  const onHandleEmail = (email: string) => {
    const lowerCaseEmail = email.toLowerCase();
    // General Email Regex (RFC 5322 Official Standard) for Javascript
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailValid = emailRegEx.test(lowerCaseEmail);

    if (isEmailValid) {
      setIsEmailValid(true);
      handleUpdateUser('email', lowerCaseEmail);
    } else if (!isEmailValid) {
      setIsEmailValid(false);
    }

    setEmailToValidate(lowerCaseEmail);
  };

  const onCreateUser = () => {
    if (companyId) {
      dispatch(dashboardUsersActions.createDashboardUser(companyId, dashboardUser));
      cancelAddingUser();
    }
  };

  return (
    <React.Fragment>
      <div className={styles.add_users_container}>
        <div className={styles.add_users_container__content}>
          <div className={styles.form}>
            <div className={styles.form__item__label}>First name*</div>
            <div className={styles.form__item__input_box}>
              <input
                type="text"
                value={dashboardUser['first_name']}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleUpdateUser('first_name', event.target.value)
                }
              />
              &nbsp;&nbsp;
              {isFirstNameValid !== null && isFirstNameValid && <TickCircleIcon size={15} color="success" />}
              {isFirstNameValid !== null && !isFirstNameValid && (
                <LightTooltip title={`Please type in a first name.`} placement="right">
                  <ErrorIcon size={15} color="danger" />
                </LightTooltip>
              )}
            </div>
            <div className={styles.form__item__label}>Last name*</div>
            <div className={styles.form__item__input_box}>
              <input
                type="text"
                value={dashboardUser['last_name']}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleUpdateUser('last_name', event.target.value)
                }
              />
              &nbsp;&nbsp;
              {isLastNameValid !== null && isLastNameValid && <TickCircleIcon size={15} color="success" />}
              {isLastNameValid !== null && !isLastNameValid && (
                <LightTooltip title={`Please type in a last name.`} placement="right">
                  <ErrorIcon size={15} color="danger" />
                </LightTooltip>
              )}
            </div>
            <div className={styles.form__item__label}>Email*</div>
            <div className={styles.form__item__input_box}>
              <input
                type="email"
                value={emailToValidate}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onHandleEmail(event.target.value)}
              />
              &nbsp;&nbsp;
              {isEmailValid !== null && isEmailValid && <TickCircleIcon size={15} color="success" />}
              {isEmailValid !== null && !isEmailValid && (
                <LightTooltip title={`Please type in a valid email address.`} placement="right">
                  <ErrorIcon size={15} color="danger" />
                </LightTooltip>
              )}
            </div>
            <div className={styles.form__item__label}>Role*</div>
            <div className={styles.form__item__input_box}>
              <select
                value={dashboardUser['role_id']}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  handleUpdateUser('role_id', event.target.value)
                }
              >
                <option value="" disabled>
                  Select user role
                </option>
                <option value={recruiterUserRoleId}>Recruiter User</option>
                <option value={companyAdminRoleId}>Company Admin</option>
              </select>
              &nbsp;&nbsp;
              {isRoleIdValid !== null && isRoleIdValid && (
                <LightTooltip
                  title={
                    <div>
                      <b>Company Admin Users:</b>
                      <ul>
                        <li>can manage team</li>
                        <li>can manage target groups</li>
                      </ul>
                      <br />
                      <b>Recruiter Users:</b>
                      <ul>
                        <li>can manage target groups</li>
                      </ul>
                    </div>
                  }
                  placement="right"
                >
                  <TickCircleIcon size={15} color="success" />
                </LightTooltip>
              )}
              {isRoleIdValid === null && (
                <LightTooltip
                  title={
                    <div>
                      Please select a role.
                      <br />
                      <br />
                      <b>Company Admin Users:</b>
                      <ul>
                        <li>can manage team</li>
                        <li>can manage target groups</li>
                      </ul>
                      <br />
                      <b>Recruiter Users:</b>
                      <ul>
                        <li>can manage target groups</li>
                      </ul>
                    </div>
                  }
                  placement="right"
                >
                  <InfoSignIcon size={15} color="info" />
                </LightTooltip>
              )}
            </div>
          </div>
          * Required fields
        </div>
      </div>

      <div className={styles.add_users_container}>
        <div className={styles.add_users_container__content}>
          <div className={styles.footer}>
            <div className={styles.button_left}>
              <AppButton icon={DeleteIcon} color={'gray'} onClick={cancelAddingUser}>
                Cancel
              </AppButton>
            </div>
            <div className={styles.button_right}>
              <AppButton
                isDisabled={!isRoleIdValid || !isEmailValid || !isFirstNameValid || !isLastNameValid}
                icon={AddIcon}
                onClick={() => setShouldShowCreateUserModal(true)}
              >
                Create User
              </AppButton>
            </div>
          </div>
        </div>
      </div>

      <ManageUsersModals
        shouldShowCreateUserModal={shouldShowCreateUserModal}
        onDiscardModal={() => discardModals()}
        userToCreate={dashboardUser}
        companyAdminRoleId={companyAdminRoleId}
        recruiterUserRoleId={recruiterUserRoleId}
        onConfirmCreateUser={() => onCreateUser()}
      />
    </React.Fragment>
  );
};

export default AddUsersForm;
