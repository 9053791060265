import React, { ChangeEventHandler, useState } from "react";
import { useDispatch } from 'react-redux';

import { actions as companiesActions } from 'store/companies/companies.actions';
import { NewCompanySchema } from 'typings/company';

import styles from 'views/AddCompanyView/AddCompanyForm/AddCompanyForm.module.css';

import AppButton from 'components/AppButton/AppButton';
import ManageCompaniesModals from 'views/AddCompanyView/CompaniesModals/CompaniesModals';

import { AddIcon, DeleteIcon, TickCircleIcon, ErrorIcon, InfoSignIcon } from 'evergreen-ui';
import { Theme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

interface AddCompanyFormProps {
  cancelAddingCompany: () => void;
  onCreateCompany: (companyName: string) => void;
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#435970',
    boxShadow: '0 0 3px 0 #435970',
    fontSize: 16,
    minWidth: 100,
    borderRadius: 9
  }
}))(Tooltip);

const AddCompanyForm: React.FC<AddCompanyFormProps> = (props) => {
  const dispatch = useDispatch();

  const initialCompanyState: NewCompanySchema = {
    name: '',
    country: 'NL',
    slug: '',
    logo: null
  };

  const [company, setCompany] = useState<NewCompanySchema>(initialCompanyState);
  const [isCompanyNameValid, setCompanyNameValid] = useState<boolean | null>(null);
  const [slugToValidate, setSlugToValidate] = useState<string>('');
  const [isSlugValid, setIsSlugValid] = useState<boolean | null>(null);
  const [shouldShowCreateCompanyModal, setShouldShowCreateCompanyModal] = useState<boolean>(false);
  const [selectedLogoFile, setSelectedLogoFile] = useState<File | null>(null);
  const [isLogoFilePicked, setIsLogoFilePicked] = useState(false);
  const [imgData, setImgData] = useState<any>(null);

  const onHandleSlug = (slug: string) => {
    const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
    const isSlugValid = slugRegex.test(slug);

    if (isSlugValid) {
      setIsSlugValid(true);
      handleUpdateCompany('slug', slug);
    } else if (!isSlugValid) {
      setIsSlugValid(false);
    }

    setSlugToValidate(slug);
  };

  const onSelectLogo: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setSelectedLogoFile(file);
      setIsLogoFilePicked(true);

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgData(reader.result);
      });
      handleUpdateCompany('logo', file);
      reader.readAsDataURL(file);
    }
  };

  const discardModals = () => {
    setShouldShowCreateCompanyModal(false);
  };

  const handleUpdateCompany = (key: 'company_name' | 'slug' | 'logo', value: any) => {
    if (key === 'company_name') {
      setCompany({ ...company, name: value });
      if (value.length > 0) {
        setCompanyNameValid(true);
      } else {
        setCompanyNameValid(false);
      }
    }
    if (key === 'slug') {
      setCompany({ ...company, slug: value });
    }
    if (key === 'logo') {
      setCompany({ ...company, logo: new File([value], 'logo.png') });
    }
  };

  const onCreateCompany = () => {
    discardModals();
    dispatch(companiesActions.createCompany(company));
    props.onCreateCompany(company.name);
  };

  return (
    <React.Fragment>
      <div className={styles.add_users_container}>
        <div className={styles.add_users_container__content}>
          <div className={styles.form}>
            <div className={styles.form__item__label}>Company name*</div>
            <div className={styles.form__item__input_box}>
              <input
                type="text"
                value={company['name']}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleUpdateCompany('company_name', event.target.value)
                }
              />
              &nbsp;&nbsp;
              {isCompanyNameValid !== null && isCompanyNameValid === true && (
                <TickCircleIcon size={15} color="success" />
              )}
              {isCompanyNameValid !== null && isCompanyNameValid === false && (
                <LightTooltip title={`Please type the name of the company`} placement="right">
                  <ErrorIcon size={15} color="danger" />
                </LightTooltip>
              )}
            </div>
            <div className={styles.form__item__label}>Slug*</div>
            <div className={styles.form__item__input_box}>
              <input
                type="text"
                value={slugToValidate}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onHandleSlug(event.target.value)}
              />
              &nbsp;&nbsp;
              {isSlugValid !== null && isSlugValid === true && <TickCircleIcon size={15} color="success" />}
              {isSlugValid !== null && isSlugValid === false && (
                <LightTooltip
                  title={`The slug will be part of the scan and dashboard url for this client. It should be the name of the company, but in lower case letters and preferably one word or with a hyphen.`}
                  placement="right"
                >
                  <ErrorIcon size={15} color="danger" />
                </LightTooltip>
              )}
            </div>
            <div className={styles.form__item__label}>Logo*</div>
            <div className={styles.form__item__input_box}>
              <label className={styles.customFileUpload}>
                <input type="file" name="file" accept="image/png" onChange={onSelectLogo} />
                {!isLogoFilePicked && 'Select logo'}
                {isLogoFilePicked && !!selectedLogoFile && selectedLogoFile.name}
              </label>
              &nbsp;&nbsp;
              <LightTooltip
                title={
                  <div>
                    Please select a logo for this company.
                    <br />
                    <br />
                    <b>Logo must be a png file</b>
                  </div>
                }
                placement="right"
              >
                {isLogoFilePicked ? (
                  <TickCircleIcon size={15} color="success" />
                ) : (
                  <InfoSignIcon size={15} color="info" />
                )}
              </LightTooltip>
              {isLogoFilePicked && imgData && (
                <img className={styles.form__item__logoPreview} src={imgData} alt="logo" />
              )}
            </div>
          </div>
          * Required fields
        </div>
      </div>

      <div className={styles.add_users_container}>
        <div className={styles.add_users_container__content}>
          <div className={styles.footer}>
            <div className={styles.button_left}>
              <AppButton icon={DeleteIcon} color={'gray'} onClick={props.cancelAddingCompany}>
                Cancel
              </AppButton>
            </div>
            <div className={styles.button_right}>
              <AppButton
                isDisabled={!isCompanyNameValid || !isLogoFilePicked || !isSlugValid}
                icon={AddIcon}
                onClick={() => setShouldShowCreateCompanyModal(true)}
              >
                Create client
              </AppButton>
            </div>
          </div>
        </div>
      </div>

      <ManageCompaniesModals
        shouldShowCreateCompanyModal={shouldShowCreateCompanyModal}
        onDiscardModal={() => discardModals()}
        companyToCreate={company}
        onConfirmCreateCompany={() => onCreateCompany()}
        logo={imgData}
      />
    </React.Fragment>
  );
};

export default AddCompanyForm;
