import React, { FunctionComponent, useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardsWrapper,
    CardTitle,
    OpenedSection,
    ScanModulesSection,
    ScanTitle,
    ScanTitleEditWrapper,
    ScanTitleSection,
    Tab,
    TabsWrapper,
    ScanModuleDescription,
    VideoSection,
    ScanModuleCheckboxWrapper,
    CheckboxContainer,
    Icon,
    StyledCheckbox,
    VideoPreviewWrapper,
    VideoTitle,
    Label,
    ScanDetailsModalWrapper,
    ScanDetailsModel,
    CloseIcon,
    AppButtonWrapper,
} from './Styles';
import { Assessment } from '../../typings/assessment';
import { CrossIcon } from 'evergreen-ui';
import AppButton from '../AppButton/AppButton';
interface ScanActiveModulesProps {
    scan: Assessment;
    closeScanDetails: () => void;
    handleSelectedScan?: (scanId: string) => void;
    isTargetGroupDeepDive?: boolean;
}
const ScanActiveModules: FunctionComponent<ScanActiveModulesProps> = (
    props,
) => {
    const {
        scan,
        closeScanDetails,
        handleSelectedScan,
        isTargetGroupDeepDive,
    } = props;

    const hasCultureFitVideo =
        scan &&
        scan.modules &&
        scan.modules.find((module) => {
            return module.id === 'culture-fit';
        });
    const hasCultureFitQuestionnaire =
        scan &&
        scan.modules &&
        scan.modules.find((module) => {
            return module.id === 'culture-fit-questionnaire';
        });
    const hasCultureFit = hasCultureFitVideo || hasCultureFitQuestionnaire;
    const hasSpeaking =
        scan &&
        scan.modules &&
        scan.modules.find((module) => {
            return module.id === 'interview';
        });
    const hasSpeakingVideo =
        hasSpeaking &&
        hasSpeaking.questions &&
        hasSpeaking.questions.find((question) => {
            return !!question.videos;
        });
    const hasSpeakingVideo1 =
        hasSpeaking && hasSpeaking.questions && hasSpeaking.questions[0].videos;
    const hasSpeakingVideo2 =
        hasSpeaking && hasSpeaking.questions && hasSpeaking.questions[1].videos;
    const hasCognitiveAbilities =
        scan &&
        scan.modules &&
        scan.modules.find((module) => {
            return module.id === 'mental-test';
        });
    const handleScan = (scanId: string) => {
        if (handleSelectedScan) {
            handleSelectedScan(scanId);
        }
        closeScanDetails();
    };

    useEffect(() => {
        const close = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                closeScanDetails();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, [closeScanDetails]);

    return (
        <ScanDetailsModel onClick={closeScanDetails}>
            <ScanDetailsModalWrapper onClick={(e) => e.stopPropagation()}>
                <CloseIcon>
                    <CrossIcon
                        style={{ cursor: 'pointer' }}
                        onClick={closeScanDetails}
                        size={24}
                    />
                </CloseIcon>
                <ScanTitleSection>
                    <ScanTitle>Scan details</ScanTitle>
                </ScanTitleSection>
                <ScanTitleSection>
                    <ScanTitle className={'secondary-title'}>
                        Language
                    </ScanTitle>
                    <ScanTitleEditWrapper>
                        {scan.language === 'en-US' ? 'English' : 'Dutch'}
                    </ScanTitleEditWrapper>
                </ScanTitleSection>
                <ScanModulesSection>
                    <CardsWrapper>
                        {!!hasCultureFit && (
                            <Card>
                                <CardHeader>
                                    <CardTitle>Cultural Fit</CardTitle>
                                </CardHeader>
                                <OpenedSection>
                                    <ScanModuleDescription>
                                        The cultural fit module gives insights
                                        on candidate’s match to the
                                        organizations values, culture and
                                        working environment.
                                        <ScanModuleCheckboxWrapper>
                                            <Label
                                                checked={!!hasCultureFitVideo}>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={
                                                            !!hasCultureFitVideo
                                                        }>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Engagement score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                        <ScanModuleCheckboxWrapper>
                                            <Label
                                                checked={
                                                    !!hasCultureFitQuestionnaire
                                                }>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={
                                                            !!hasCultureFitQuestionnaire
                                                        }>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Work preferences scores
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                    </ScanModuleDescription>
                                    <VideoPreviewWrapper>
                                        {!!hasCultureFitVideo &&
                                            hasCultureFitVideo.videos && (
                                                <video
                                                    width={'100%'}
                                                    height={'280px'}
                                                    controls
                                                    src={
                                                        hasCultureFitVideo
                                                            .videos.low_res
                                                    }>
                                                    Your browser does not
                                                    support the video tag.
                                                </video>
                                            )}
                                    </VideoPreviewWrapper>
                                </OpenedSection>
                            </Card>
                        )}
                        {!!hasSpeaking && (
                            <Card>
                                <CardHeader>
                                    <CardTitle>Speaking</CardTitle>
                                </CardHeader>
                                <OpenedSection>
                                    <ScanModuleDescription>
                                        The Speaking module gives insights on a
                                        candidate’s general motivation and
                                        overall confidence based on the
                                        candidates recording their answers to
                                        two pre-defined questions.
                                        <TabsWrapper>
                                            {!hasSpeakingVideo && (
                                                <Tab active={!hasSpeakingVideo}>
                                                    Written questions
                                                </Tab>
                                            )}
                                            {!!hasSpeakingVideo && (
                                                <Tab
                                                    active={!!hasSpeakingVideo}>
                                                    Video questions
                                                </Tab>
                                            )}
                                        </TabsWrapper>
                                        <ScanModuleCheckboxWrapper>
                                            <Label checked={!!hasSpeaking}>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={!!hasSpeaking}>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    General motivation score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                        <ScanModuleCheckboxWrapper>
                                            <Label checked={!!hasSpeaking}>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={!!hasSpeaking}>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Overall confidence score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                    </ScanModuleDescription>
                                    <VideoSection>
                                        {!!hasSpeakingVideo &&
                                            !!hasSpeakingVideo1 &&
                                            !!hasSpeakingVideo2 && (
                                                <>
                                                    <VideoTitle>
                                                        Question 1: - Why do you
                                                        want to work for this
                                                        company?
                                                    </VideoTitle>
                                                    <video
                                                        width={'100%'}
                                                        height={'280px'}
                                                        controls
                                                        src={
                                                            hasSpeakingVideo1.low_res
                                                        }>
                                                        Your browser does not
                                                        support the video tag.
                                                    </video>
                                                    <VideoTitle>
                                                        Question 2: - What can
                                                        you bring to the company
                                                        in your role?
                                                    </VideoTitle>
                                                    <video
                                                        width={'100%'}
                                                        height={'280px'}
                                                        controls
                                                        src={
                                                            hasSpeakingVideo2.low_res
                                                        }>
                                                        Your browser does not
                                                        support the video tag.
                                                    </video>
                                                </>
                                            )}
                                    </VideoSection>
                                </OpenedSection>
                            </Card>
                        )}
                        {!!hasCognitiveAbilities && (
                            <Card>
                                <CardHeader>
                                    <CardTitle>Cognitive Abilities</CardTitle>
                                </CardHeader>
                                <OpenedSection>
                                    <ScanModuleDescription>
                                        The Cognitive ability module gives
                                        insights on candidates mental
                                        capabilities and stress.
                                        <ScanModuleCheckboxWrapper>
                                            <Label
                                                checked={
                                                    !!hasCognitiveAbilities
                                                }>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={
                                                            !!hasCognitiveAbilities
                                                        }>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Stress resilience score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                        <ScanModuleCheckboxWrapper>
                                            <Label
                                                checked={
                                                    !!hasCognitiveAbilities
                                                }>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={
                                                            !!hasCognitiveAbilities
                                                        }>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Ease under pressure score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                        <ScanModuleCheckboxWrapper>
                                            <Label
                                                checked={
                                                    !!hasCognitiveAbilities
                                                }>
                                                <CheckboxContainer>
                                                    <StyledCheckbox
                                                        checked={
                                                            !!hasCognitiveAbilities
                                                        }>
                                                        <Icon viewBox="0 0 24 24">
                                                            <polyline points="20 6 9 17 4 12" />
                                                        </Icon>
                                                    </StyledCheckbox>
                                                </CheckboxContainer>
                                                <span style={{ marginLeft: 8 }}>
                                                    Cognitive abilities score
                                                </span>
                                            </Label>
                                        </ScanModuleCheckboxWrapper>
                                    </ScanModuleDescription>
                                </OpenedSection>
                            </Card>
                        )}
                    </CardsWrapper>
                </ScanModulesSection>
                {!isTargetGroupDeepDive && (
                    <AppButtonWrapper>
                        <AppButton onClick={() => handleScan(scan.id)}>
                            Select
                        </AppButton>
                    </AppButtonWrapper>
                )}
            </ScanDetailsModalWrapper>
        </ScanDetailsModel>
    );
};
export default ScanActiveModules;
