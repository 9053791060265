import React, { FunctionComponent, useRef, useState } from 'react';
import AppButton from 'components/AppButton/AppButton';
import { Participant, TargetGroupCsvDataRow } from 'typings/user';
import { TargetGroupScoreDownloadProps } from '../../../typings';
import { CSVLink } from 'react-csv';
import { DownloadIcon } from 'evergreen-ui';
import {
    CultureFitCategory,
    MentalTestCategory,
} from '@neurolytics/results-template';
import {
    isCultureFitCandidateScore,
    isInterviewCandidateScore,
    isMentalTestCandidateScore,
} from '../utils';

const TargetGroupScoreDownload: FunctionComponent<
    TargetGroupScoreDownloadProps
> = (props) => {
    const {
        assessmentName,
        targetGroup,
        targetGroupName,
        participants,
        participantsList,
        candidateCurrentPage,
        candidateTotalPages,
    } = props;
    const csvLink = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);
    const [csvData, setCsvData] = useState<TargetGroupCsvDataRow[]>([]);
    const millisToMinutesAndSeconds = (millis: number) => {
        let minutes = Math.floor(millis / 60000);
        let seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
        return seconds === 60
            ? minutes + 1 + ':00'
            : minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    };

    const formatScore = (score: number | undefined): number | 'N/A' => {
        if (score || score === 0) {
            return Math.round(score);
        }
        return 'N/A';
    };

    const getCategoryScore = (score: any, scoreCategory: string) => {
        return score.categories && score.categories.length
            ? score.categories.filter(
                  (category: CultureFitCategory | MentalTestCategory) =>
                      category.id === scoreCategory,
              )[0].candidate_score
            : 'NaN';
    };

    const formatData = (usersAndScores: { [p: string]: Participant }) => {
        if (
            assessmentName &&
            targetGroup &&
            Object.keys(usersAndScores).length > 0
        ) {
            const scoresArray: TargetGroupCsvDataRow[] = [];

            for (const userAndScore in usersAndScores) {
                const userActivity = usersAndScores[userAndScore].activity;
                if (!!userActivity) {
                    const scoreDataCurrentParticipant: TargetGroupCsvDataRow = {
                        Scan: assessmentName,
                        'Target Group': targetGroupName,
                        user_id: usersAndScores[userAndScore].id,
                        Email: usersAndScores[userAndScore].email,
                        'Candidate name':
                            usersAndScores[userAndScore].first_name +
                            ' ' +
                            usersAndScores[userAndScore].last_name,
                    };

                    const { scores } = userActivity;
                    if (scores) {
                        const cultureFitScores = scores.find(
                            isCultureFitCandidateScore,
                        );
                        const interviewScores = scores.find(
                            isInterviewCandidateScore,
                        );
                        const mentalScores = scores.find(
                            isMentalTestCandidateScore,
                        );

                        if (!!cultureFitScores) {
                            const cultureFitData =
                                cultureFitScores.data.culture_fit;
                            if (cultureFitData) {
                                scoreDataCurrentParticipant.Engagement =
                                    formatScore(
                                        cultureFitData.company_engagement,
                                    );
                                scoreDataCurrentParticipant['Culture Match'] =
                                    formatScore(cultureFitData.culture_match);
                                scoreDataCurrentParticipant.Family =
                                    formatScore(
                                        getCategoryScore(
                                            cultureFitData,
                                            'family',
                                        ),
                                    );
                                scoreDataCurrentParticipant.Adhocracy =
                                    formatScore(
                                        getCategoryScore(
                                            cultureFitData,
                                            'adhocracy',
                                        ),
                                    );
                                scoreDataCurrentParticipant.Market =
                                    formatScore(
                                        getCategoryScore(
                                            cultureFitData,
                                            'market',
                                        ),
                                    );
                                scoreDataCurrentParticipant.Hierarchy =
                                    formatScore(
                                        getCategoryScore(
                                            cultureFitData,
                                            'hierarchy',
                                        ),
                                    );
                            }
                        }

                        if (!!interviewScores) {
                            const interviewData =
                                interviewScores.data.interview;
                            if (interviewData) {
                                scoreDataCurrentParticipant.Motivation =
                                    formatScore(interviewData.work_motivation);
                                scoreDataCurrentParticipant.Confidence =
                                    formatScore(interviewData.confidence);
                            }
                        }

                        if (!!mentalScores) {
                            const mentalTestData =
                                mentalScores.data.mental_test;

                            if (mentalTestData) {
                                scoreDataCurrentParticipant[
                                    'Stress resilience'
                                ] = formatScore(
                                    mentalTestData.stress_resilience,
                                );
                                scoreDataCurrentParticipant[
                                    'Ease under pressure'
                                ] = formatScore(mentalTestData.ease);
                                // ak = general knowledge ('algemene kennis')
                                scoreDataCurrentParticipant[
                                    'General knowledge'
                                ] = formatScore(
                                    getCategoryScore(mentalTestData, 'ak'),
                                );
                                // lr = logical reasoning ('logisch redeneren')
                                scoreDataCurrentParticipant[
                                    'Logical reasoning'
                                ] = formatScore(
                                    getCategoryScore(mentalTestData, 'lr'),
                                );
                                // nr = numerical reasoning ('numeriek redeneren')
                                scoreDataCurrentParticipant[
                                    'Numerical reasoning'
                                ] = formatScore(
                                    getCategoryScore(mentalTestData, 'nr'),
                                );
                                // vr = verbal reasoning ('verbaal redeneren')
                                scoreDataCurrentParticipant[
                                    'Verbal reasoning'
                                ] = formatScore(
                                    getCategoryScore(mentalTestData, 'vr'),
                                );
                            }
                        }
                    }

                    if (userActivity.sessions) {
                        const { sessions } = userActivity;
                        if (sessions && sessions.length && sessions[0]) {
                            scoreDataCurrentParticipant['Date completed'] =
                                sessions[0].finishedAt;
                            scoreDataCurrentParticipant.OS = `${sessions[0].device.os.name} ${sessions[0].device.os.version}`;
                            scoreDataCurrentParticipant.Browser =
                                sessions[0].browser.name;
                            scoreDataCurrentParticipant[
                                'Total time to complete scan'
                            ] = `${
                                sessions[0].totalTime
                                    ? millisToMinutesAndSeconds(
                                          sessions[0].totalTime,
                                      )
                                    : null
                            }`;
                        }
                    }

                    scoresArray.push(scoreDataCurrentParticipant);
                }
            }

            setCsvData(
                scoresArray.filter((scoreDataOfCurrentParticipant) => {
                    return (
                        !!scoreDataOfCurrentParticipant &&
                        (!!scoreDataOfCurrentParticipant['Date completed'] ||
                            !!scoreDataOfCurrentParticipant.Engagement ||
                            !!scoreDataOfCurrentParticipant['Culture Match'] ||
                            !!scoreDataOfCurrentParticipant.Adhocracy ||
                            !!scoreDataOfCurrentParticipant.Hierarchy ||
                            !!scoreDataOfCurrentParticipant.Market ||
                            !!scoreDataOfCurrentParticipant.Family ||
                            !!scoreDataOfCurrentParticipant.Confidence ||
                            !!scoreDataOfCurrentParticipant.Motivation ||
                            !!scoreDataOfCurrentParticipant[
                                'Stress resilience'
                            ] ||
                            !!scoreDataOfCurrentParticipant[
                                'Ease under pressure'
                            ] ||
                            !!scoreDataOfCurrentParticipant[
                                'General knowledge'
                            ] ||
                            !!scoreDataOfCurrentParticipant[
                                'Verbal reasoning'
                            ] ||
                            !!scoreDataOfCurrentParticipant[
                                'Numerical reasoning'
                            ] ||
                            !!scoreDataOfCurrentParticipant[
                                'Logical reasoning'
                            ])
                    );
                }),
            );
            setTimeout(() => {
                csvLink?.current?.link.click();
            }, 1);
        }
    };

    return (
        <div aria-label="download score button">
            <AppButton
                justifyContent={'flex-start'}
                width={'auto'}
                icon={DownloadIcon}
                isDisabled={
                    !participants.length ||
                    (participantsList &&
                        !Object.keys(participantsList).find(
                            (userId) =>
                                (participantsList[userId].activity?.sessions &&
                                    participantsList[userId].activity
                                        ?.sessions[0]?.finishedAt) ||
                                participantsList[userId].activity?.scores
                                    ?.length,
                        ))
                }
                onClick={() => formatData(participantsList)}>
                {candidateTotalPages.length === 0
                    ? 'Download scores'
                    : `Download scores page ${candidateCurrentPage + 1}`}
            </AppButton>
            {csvData && csvData.length !== 0 && (
                <CSVLink
                    data={csvData}
                    filename={targetGroupName + '.csv'}
                    className="hidden"
                    ref={csvLink}
                    target={'_blank'}
                />
            )}
        </div>
    );
};

export default TargetGroupScoreDownload;
