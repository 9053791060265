import styled from 'styled-components';
interface IProps {
    completed?: boolean;
    active?: boolean;
}
export const ThirdStepWrapper = styled('div')`
    padding: 40px 0;
`;
export const ThirdStepDescription = styled('div')`
    color: #435970;
    font-size: 16px;
    padding-top: 8px;
    font-weight: normal;
    max-width: 53%;
    line-height: 28px;
    &.title {
        font-weight: bold;
        padding-bottom: 24px;
    }
`;
export const CardsWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 72px;
    padding: 32px 0;
`;
export const CheckMarkIcon = styled('div')<IProps>`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${(props) => (props.completed ? '#00a5ac' : 'white')};
    border: ${(props) => (props.completed ? 'none' : '1px dashed gray')};
    font-size: 20px;
    color: ${(props) => (props.completed ? 'white' : 'gray')};
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const CardTitle = styled('div')`
    color: #435970;
    font-size: 20px;
    font-weight: bold;
`;
export const IconsWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 7%;
`;
export const Card = styled('div')`
    width: 100%;
    border: 1px solid transparent;
    padding: 24px 32px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    }
`;
export const CardHeader = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    min-height: 56px;
`;
export const OpenedSection = styled('div')`
    width: 100%;
    min-height: 100px;
    padding-top: 16px;
`;
export const UploadSection = styled('div')`
    width: 100%;
    min-height: 350px;
    padding-top: 16px;
`;
export const TabsWrapper = styled('div')`
    border-bottom: 1px solid #435970;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    &.disabled {
        border-bottom: none;
    }
`;
export const Tab = styled('div')<IProps>`
    border: 1px solid #435970;
    border-bottom: none;
    width: 148px;
    height: 40px;
    color: #849cb1;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #435970;
        opacity: 0.9;
        color: white;
        cursor: pointer;
    }
    ${({ active }) =>
        active &&
        `
            background-color: #435970;
        color: white;
        cursor: pointer;
  `}
`;

export const UploadTitle = styled('div')`
    color: #435970;
    font-size: 16px;
    &.video-title {
        padding-left: 32px;
    }
    &.speaking-title {
        padding: 32px 0 16px 0;
        font-weight: bold;
        font-size: 18px;
    }
  &.speaking-title-video {
    padding: 24px 0 12px 0;
    font-weight: bold;
  }
`;

export const UploadContainer = styled('div')`
    padding: 0 64px;
`;
export const WrittenQuestion = styled('div')`
    margin: 24px 0;
    padding: 24px;
    line-height: 44px;
    font-weight: bold;
    color: #435970;
    width: 50%;
    background-color: #f0f6fb;
    .written-desc {
        font-weight: normal;
        padding-left: 24px;
    }
`;
