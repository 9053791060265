import React, { Fragment, useEffect, useState } from 'react';
import { Participant } from '../../../typings/user';
import styles from './TargetGroupTable.module.css';
import { ChevronDownIcon, ChevronUpIcon, Spinner } from 'evergreen-ui';
import { formatDate } from '../../../utils/time';
import CandidateActivityTable from './CandidateActivityTable';
import { getLastCompletedSession } from './TargetGroupTable';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Skeleton } from '@material-ui/lab';
import { Report } from 'typings/report';
import { LightTooltip } from './TargetGroupTable';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import CandidateInfoModal from './CandidateInfoModal/CandidateInfoModal';
import {
    isCultureFitCandidateScore,
    isInterviewCandidateScore,
    isMentalTestCandidateScore,
    participantHasErrors,
    participantReminderEmailDate,
} from '../utils';
import { Assessment } from '../../../typings/assessment';
import { findLatestError } from '../../../utils/ErrorHandling/findLatestError';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/Icons/3dots.svg';
import { ReactComponent as ActivePDFIcon } from '../../../assets/Icons/active-pdf.svg';
import { ReactComponent as DisabledPDFIcon } from '../../../assets/Icons/disabled-pdf.svg';

interface ParticipantRowProps {
    participant: Participant;
    participantsSelected: Participant[];
    usersShowingInfo: string[];
    isBeingRemoved: boolean;
    isResetScanLoading: boolean;
    onUserShowInfoChange: (userId: string) => void;
    isUsersDetailsLoading: boolean;
    updatingParticipants: boolean;
    onSelect: (userId: string, event: any) => void;
    isSuperAdmin: boolean;
    invitationId: string;
    groupId: string;
    scan?: Assessment;
}

export const getParticipantReport = (
    participant: Participant,
): Report | null => {
    if (participant.activity && participant.activity.report) {
        const { report } = participant.activity;
        return report;
    } else return null;
};

const hasReport = (participant: Participant): boolean => {
    return !!(participant.activity && participant.activity.report);
};

const hasParticipantFinished = (participant: Participant) => {
    if (!participant.activity) {
        return false;
    }
    const session = getLastCompletedSession(participant.activity);
    return !!(session && session.finishedAt);
};

const ParticipantRow = (props: ParticipantRowProps) => {
    const {
        participant,
        participantsSelected,
        usersShowingInfo,
        isBeingRemoved,
        isResetScanLoading,
        onUserShowInfoChange,
        isUsersDetailsLoading,
        updatingParticipants,
        onSelect,
        isSuperAdmin,
        invitationId,
        scan,
    } = props;
    const isSelected = participantsSelected.some(
        (selected) => selected.id === participant.id,
    );
    const showInfo = usersShowingInfo.some((id) => id === participant.id);

    const [isCandidateDetailsModalOpen, setIsCandidateDetailsModalOpen] =
        useState<boolean>(false);

    const participantReport = getParticipantReport(participant);
    const lastCompletedSession = participant.activity
        ? getLastCompletedSession(participant.activity)
        : null;

    const participantReminderDate = participantReminderEmailDate(participant);

    const analysisStatus = participant.activity?.analysis?.status;

    const hasErrorStatus = participantHasErrors(
        participant.status ? participant.status : {},
    );

    const mailSubject = `Analysis failed for user with invitation= ${invitationId}`;
    const encodedSubject = encodeURIComponent(mailSubject);

    const latestErrorMessage = findLatestError(participant);

    const cultureFitScore = participant.activity?.scores?.find(
        isCultureFitCandidateScore,
    );
    const cultureFitEngagementScore =
        cultureFitScore?.data.culture_fit.company_engagement;
    const cultureFitWorkPreferenceScore =
        cultureFitScore?.data.culture_fit.categories;

    const interviewScore = participant.activity?.scores?.find(
        isInterviewCandidateScore,
    );
    const interviewConfidenceScore = interviewScore?.data.interview.confidence;
    const interviewMotivationScore =
        interviewScore?.data.interview.work_motivation;

    const mentalTestScore = participant.activity?.scores?.find(
        isMentalTestCandidateScore,
    );
    const mentalTestCategoriesScore =
        mentalTestScore?.data.mental_test.categories;
    const mentalTestEaseScore = mentalTestScore?.data.mental_test.ease;
    const mentalTestStressScore =
        mentalTestScore?.data.mental_test.stress_resilience;

    const hasCultureFitEngagementModule = !!scan?.modules?.find((module) => {
        return module.id === 'culture-fit';
    });
    const hasCultureFitWorkPreferencesModule = !!scan?.modules?.find(
        (module) => {
            return module.id === 'culture-fit-questionnaire';
        },
    );
    const hasSpeakingModule = !!scan?.modules?.find((module) => {
        return module.id === 'interview';
    });
    const hasCognitiveAbilitiesModule = !!scan?.modules?.find((module) => {
        return module.id === 'mental-test';
    });
    const areAllScanModulesScored = () => {
        return (
            !!cultureFitEngagementScore === hasCultureFitEngagementModule &&
            !!cultureFitWorkPreferenceScore ===
                hasCultureFitWorkPreferencesModule &&
            !!interviewConfidenceScore === hasSpeakingModule &&
            !!interviewMotivationScore === hasSpeakingModule &&
            !!mentalTestCategoriesScore === hasCognitiveAbilitiesModule &&
            !!mentalTestEaseScore === hasCognitiveAbilitiesModule &&
            !!mentalTestStressScore === hasCognitiveAbilitiesModule
        );
    };
    const areAllModulesScored = areAllScanModulesScored();

    useEffect(() => {
        if (isCandidateDetailsModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isCandidateDetailsModalOpen]);

    return (
        <Fragment key={`row-user-${participant.id}`}>
            {(isBeingRemoved || isResetScanLoading) && (
                <Fragment>
                    <TableRow>
                        <TableCell colSpan={9} align="right">
                            <div className={styles.skeleton_container}>
                                <Skeleton
                                    variant="text"
                                    width="100%"
                                    height={40}
                                    animation="wave"
                                />
                            </div>
                        </TableCell>
                    </TableRow>
                </Fragment>
            )}
            {!isBeingRemoved && !isResetScanLoading && (
                <Fragment>
                    <TableRow hover selected={isSelected}>
                        <TableCell>
                            {showInfo && (
                                <ChevronUpIcon
                                    size={25}
                                    onClick={() => {
                                        onUserShowInfoChange(participant.id);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                            {!showInfo && (
                                <ChevronDownIcon
                                    size={25}
                                    onClick={() => {
                                        onUserShowInfoChange(participant.id);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                disabled={
                                    isUsersDetailsLoading ||
                                    updatingParticipants
                                }
                                checked={isSelected}
                                onChange={(event) =>
                                    onSelect(participant.id, event)
                                }
                                color="primary"
                                classes={{ checked: styles.checkedBox }}
                            />
                        </TableCell>
                        <TableCell>{participant.first_name}</TableCell>
                        <TableCell>{participant.last_name}</TableCell>
                        {isUsersDetailsLoading && (
                            <TableCell colSpan={5} align="right">
                                <div className={styles.skeleton_container}>
                                    <Skeleton
                                        variant="text"
                                        width="100%"
                                        height={20}
                                        animation="wave"
                                    />
                                </div>
                            </TableCell>
                        )}
                        {!isUsersDetailsLoading && (
                            <Fragment>
                                <TableCell align="center">
                                    {participantReminderDate ? (
                                        <LightTooltip
                                            title={`Registered on ${formatDate(
                                                participant.invitation_created_at
                                                    ? participant.invitation_created_at
                                                    : participant.created_at,
                                            )}`}
                                            placement="bottom">
                                            <div>
                                                Reminded on:
                                                <br />
                                                <br />
                                                <p
                                                    className={
                                                        styles.register_info
                                                    }>
                                                    {formatDate(
                                                        participantReminderDate,
                                                    )}
                                                </p>
                                            </div>
                                        </LightTooltip>
                                    ) : (
                                        <div>
                                            Registered on:
                                            <br />
                                            <br />
                                            <p className={styles.register_info}>
                                                {formatDate(
                                                    participant.invitation_created_at
                                                        ? participant.invitation_created_at
                                                        : participant.created_at,
                                                )}
                                            </p>
                                        </div>
                                    )}
                                </TableCell>
                                {participant.activity ? (
                                    <TableCell align="center">
                                        {!!participantReport &&
                                        !!participantReport.created_at &&
                                        areAllModulesScored ? (
                                            <span
                                                className={
                                                    styles.status_wrapper
                                                }
                                                style={{
                                                    backgroundColor: '#1AA277',
                                                }}>
                                                Completed
                                            </span>
                                        ) : !!participantReport &&
                                          !!participantReport.created_at &&
                                          !areAllModulesScored ? (
                                            <span
                                                className={
                                                    styles.status_wrapper
                                                }
                                                style={{
                                                    backgroundColor: '#1AA277',
                                                }}>
                                                Partially completed
                                            </span>
                                        ) : !!lastCompletedSession &&
                                          (!participantReport ||
                                              !participantReport.created_at) &&
                                          !hasErrorStatus &&
                                          analysisStatus === 'pending' ? (
                                            <span
                                                className={
                                                    styles.status_wrapper
                                                }
                                                style={{
                                                    backgroundColor:
                                                        'rgba(95, 145, 204, 1)',
                                                }}>
                                                Analysing
                                            </span>
                                        ) : !!lastCompletedSession &&
                                          (!participantReport ||
                                              !participantReport.created_at) &&
                                          hasErrorStatus &&
                                          analysisStatus === 'pending' ? (
                                            <span
                                                className={
                                                    styles.status_wrapper
                                                }
                                                style={{
                                                    backgroundColor: '#F6510E',
                                                }}>
                                                Failed
                                            </span>
                                        ) : (
                                            <span
                                                className={
                                                    styles.status_wrapper
                                                }
                                                style={{
                                                    backgroundColor: '#BBC9D8',
                                                }}>
                                                Not started
                                            </span>
                                        )}
                                    </TableCell>
                                ) : (
                                    <TableCell align="center">
                                        <div
                                            className={
                                                styles.skeleton_container
                                            }>
                                            <Skeleton
                                                variant="text"
                                                width="100%"
                                                height={20}
                                                animation="wave"
                                            />
                                        </div>
                                    </TableCell>
                                )}
                                <TableCell align="center">
                                    {hasReport(participant) &&
                                    !!participantReport ? (
                                        <LightTooltip
                                            title={`Report generated on ${formatDate(
                                                participantReport.created_at,
                                            )}`}
                                            placement="left">
                                            <Link
                                                to={`results/${participant.id}`}
                                                target="_blank">
                                                <ActivePDFIcon />
                                            </Link>
                                        </LightTooltip>
                                    ) : (
                                        <div>
                                            <DisabledPDFIcon />
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {hasParticipantFinished(participant) ? (
                                        <>
                                            {lastCompletedSession
                                                ? formatDate(
                                                      lastCompletedSession.finishedAt,
                                                  )
                                                : ''}
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <div
                                        className={styles.info_modal}
                                        onClick={() => {
                                            setIsCandidateDetailsModalOpen(
                                                true,
                                            );
                                        }}>
                                        <ThreeDotsIcon />
                                    </div>
                                </TableCell>
                            </Fragment>
                        )}
                    </TableRow>
                    <TableRow>
                        {participant &&
                        !!participant.activity &&
                        !participant.error ? (
                            <TableCell
                                style={{
                                    padding: 0,
                                    borderBottom: !showInfo ? 0 : undefined,
                                    background: '#e7f8f8',
                                }}
                                colSpan={9}>
                                <Collapse in={showInfo} timeout="auto">
                                    {!!participantReport &&
                                        !!participantReport.created_at &&
                                        areAllModulesScored && (
                                            <div
                                                className={
                                                    styles.result_notification__green
                                                }>
                                                The analysis was successful.
                                                Results are available
                                            </div>
                                        )}
                                    {!!participantReport &&
                                        !!participantReport.created_at &&
                                        !areAllModulesScored && (
                                            <div
                                                className={
                                                    styles.result_notification__green
                                                }>
                                                The analysis was partially
                                                successful. Results are
                                                available
                                            </div>
                                        )}
                                    {(!participantReport ||
                                        !participantReport.created_at) &&
                                        !hasErrorStatus &&
                                        analysisStatus !== 'pending' && (
                                            <div
                                                className={
                                                    styles.result_notification__gray
                                                }>
                                                The scan has not started yet.
                                            </div>
                                        )}
                                    {(!participantReport ||
                                        !participantReport.created_at) &&
                                        !hasErrorStatus &&
                                        analysisStatus === 'pending' && (
                                            <div
                                                className={
                                                    styles.result_notification__blue
                                                }>
                                                Analysis in progress. The report
                                                will be ready soon.
                                            </div>
                                        )}
                                    {(!participantReport ||
                                        !participantReport.created_at) &&
                                        hasErrorStatus &&
                                        analysisStatus === 'pending' && (
                                            <div
                                                className={
                                                    styles.result_notification__oranje
                                                }>
                                                <p>
                                                    {!!latestErrorMessage &&
                                                    isSuperAdmin
                                                        ? latestErrorMessage.adminMessage
                                                        : !!latestErrorMessage &&
                                                          !isSuperAdmin
                                                        ? latestErrorMessage.clientMessage
                                                        : 'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.'}
                                                </p>
                                                <p>
                                                    <a
                                                        href={`mailto:support@neurolytics.ai?subject=${encodedSubject}`}>
                                                        Contact support
                                                    </a>
                                                </p>
                                            </div>
                                        )}
                                    <Box>
                                        <CandidateActivityTable
                                            participant={participant}
                                            invitationId={invitationId}
                                            hasCultureFitEngagementModule={
                                                hasCultureFitEngagementModule
                                            }
                                            hasCultureFitWorkPreferencesModule={
                                                hasCultureFitWorkPreferencesModule
                                            }
                                            hasSpeakingModule={
                                                hasSpeakingModule
                                            }
                                            hasCognitiveAbilitiesModule={
                                                hasCognitiveAbilitiesModule
                                            }
                                        />
                                    </Box>
                                </Collapse>
                            </TableCell>
                        ) : participant.error ? (
                            <TableCell
                                style={{
                                    padding: 0,
                                    borderBottom: !showInfo ? 0 : undefined,
                                    background: '#e7f8f8',
                                }}
                                colSpan={9}>
                                <Collapse in={showInfo} timeout="auto">
                                    <Box>
                                        <div
                                            className={
                                                styles.user_activity__loading
                                            }>
                                            <span>
                                                Couldn't find activity
                                                information. Please reach out to
                                                support.
                                            </span>
                                        </div>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        ) : (
                            <TableCell
                                style={{
                                    padding: 0,
                                    borderBottom: !showInfo ? 0 : undefined,
                                    background: '#e7f8f8',
                                }}
                                colSpan={9}>
                                <Collapse in={showInfo} timeout="auto">
                                    <Box>
                                        <div
                                            className={
                                                styles.user_activity__loading
                                            }>
                                            <Spinner size={22} />
                                            <span>Loading...</span>
                                        </div>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        )}
                    </TableRow>
                    {!isUsersDetailsLoading && isCandidateDetailsModalOpen && (
                        <TableRow>
                            <TableCell>
                                <CandidateInfoModal
                                    participant={participant}
                                    isSuperAdmin={isSuperAdmin}
                                    invitationId={invitationId}
                                    areAllModulesScored={areAllModulesScored}
                                    closeCandidateDetailsModal={() =>
                                        setIsCandidateDetailsModalOpen(false)
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default ParticipantRow;
