import {
    WorkPreferenceBaselineData,
    WorkPreferenceBaselineScore,
} from '../../typings';
import {
    CandidateScore,
    CultureFitCandidateScore,
    InterviewCandidateScore,
    MentalTestCandidateScore,
    Participant,
} from '../../typings/user';
import { InvitationStatus } from '../../typings/target_group';

export const getWorkPreferencesFormattedData = (
    workPreferencesBaseline: WorkPreferenceBaselineData,
) => {
    const data: {
        Hierarchy: number;
        Market: number;
        Adhocracy: number;
        Family: number;
    }[] = [
        {
            Hierarchy: getBaselineScore(workPreferencesBaseline, 'hierarchy'),
            Market: getBaselineScore(workPreferencesBaseline, 'market'),
            Adhocracy: getBaselineScore(workPreferencesBaseline, 'adhocracy'),
            Family: getBaselineScore(workPreferencesBaseline, 'family'),
        },
    ];
    const baseLineCategoriesSum =
        data[0].Family + data[0].Adhocracy + data[0].Hierarchy + data[0].Market;

    const baseLineScore: WorkPreferenceBaselineScore = {
        Family: data[0].Family,
        Adhocracy: data[0].Adhocracy,
        Hierarchy: data[0].Hierarchy,
        Market: data[0].Market,
    };

    const getMaxScore = (scoreData: WorkPreferenceBaselineScore) => {
        return Object.keys(scoreData).filter((x) => {
            return (
                scoreData[x as keyof WorkPreferenceBaselineScore] ===
                Math.max.apply(null, Object.values(scoreData))
            );
        });
    };
    const getMinScore = (scoreData: WorkPreferenceBaselineScore) => {
        return Object.keys(scoreData).filter((x) => {
            return (
                scoreData[x as keyof WorkPreferenceBaselineScore] ===
                Math.min.apply(null, Object.values(scoreData))
            );
        });
    };
    if (baseLineCategoriesSum > 100) {
        if (getMinScore(baseLineScore)[0] === 'Market' && data[0].Market >= 1) {
            data[0].Market = data[0].Market - (baseLineCategoriesSum - 100);
        }
        if (
            getMinScore(baseLineScore)[0] === 'Adhocracy' &&
            data[0].Adhocracy >= 1
        ) {
            data[0].Adhocracy =
                data[0].Adhocracy - (baseLineCategoriesSum - 100);
        }
        if (getMinScore(baseLineScore)[0] === 'Family' && data[0].Family >= 1) {
            data[0].Family = data[0].Family - (baseLineCategoriesSum - 100);
        }
        if (
            getMinScore(baseLineScore)[0] === 'Hierarchy' &&
            data[0].Hierarchy >= 1
        ) {
            data[0].Hierarchy =
                data[0].Hierarchy - (baseLineCategoriesSum - 100);
        }
    }
    if (baseLineCategoriesSum < 100) {
        if (getMaxScore(baseLineScore)[0] === 'Market') {
            data[0].Market = data[0].Market + (100 - baseLineCategoriesSum);
        }
        if (getMaxScore(baseLineScore)[0] === 'Adhocracy') {
            data[0].Adhocracy =
                data[0].Adhocracy + (100 - baseLineCategoriesSum);
        }
        if (getMaxScore(baseLineScore)[0] === 'Family') {
            data[0].Family = data[0].Family + (100 - baseLineCategoriesSum);
        }
        if (getMaxScore(baseLineScore)[0] === 'Hierarchy') {
            data[0].Hierarchy =
                data[0].Hierarchy + (100 - baseLineCategoriesSum);
        }
    }

    return data;
};

const getPercentage = (value: number, base: number) =>
    Math.round((value / base) * 100);

const getBaselineScore = (
    baselineData: WorkPreferenceBaselineData,
    scoreName: 'hierarchy' | 'market' | 'adhocracy' | 'family',
): number => {
    const companyScoreSum = baselineData
        .map((scores) => scores[scoreName])
        .reduce((a, b) => a + b, 0);
    return getPercentage(companyScoreSum, baselineData.length * 16);
};

export const isCultureFitCandidateScore = (
    score: CandidateScore,
): score is CultureFitCandidateScore => {
    return score.module_id === 'culture_fit';
};
export const isInterviewCandidateScore = (
    score: CandidateScore,
): score is InterviewCandidateScore => {
    return score.module_id === 'interview';
};
export const isMentalTestCandidateScore = (
    score: CandidateScore,
): score is MentalTestCandidateScore => {
    return score.module_id === 'mental_test';
};

export const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const participantReminderEmailDate = (participant: Participant) => {
    if (participant.emails_info) {
        const reminderEmail = participant.emails_info.find(
            (email) => email.template === 'UserReminder',
        );
        if(reminderEmail?.delivered_at){
            return reminderEmail.delivered_at
        }
        return reminderEmail?.sent_at
    }
};

export const participantHasErrors = (status: InvitationStatus): boolean => {
    return Object.keys(status).length > 0;
};

export const getPaginationRange = (
    currentPage: number,
    totalPages: number,
    siblingCount = 1,
): (number | string)[] => {
    let pages: (number | string)[] = [];

    const startPage = Math.max(2, currentPage - siblingCount);
    const endPage = Math.min(totalPages - 1, currentPage + siblingCount);
    pages.push(1);
    const shouldShowLeftEllipsis = startPage > 2;
    if (shouldShowLeftEllipsis) {
        pages.push('...');
    } else {
        if (totalPages >= 3 && startPage === 2) {
            pages.push(2);
        }
    }
    for (let page = Math.max(startPage, 3); page <= endPage; page++) {
        pages.push(page);
    }
    const shouldShowRightEllipsis = endPage < totalPages - 1;
    if (shouldShowRightEllipsis) {
        pages.push('...');
    }
    if (totalPages >= 2) {
        pages.push(totalPages);
    }
    return pages;
};
