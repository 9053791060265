import React from 'react';
import './styles.css';
import { useTranslation } from '../../i18n';

interface WorkPreferenceBarChartProps {
    data: {
        Hierarchy: number;
        Market: number;
        Adhocracy: number;
        Family: number;
    }[];
}
const WorkPreferenceBaseLineBarChart = (props: WorkPreferenceBarChartProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const chartData = [
        {
            name: t('CULTURAL_FIT.WORK_PREFERENCES.CATEGORIES.ADHOCRACY'),
            value: data[0].Adhocracy,
            color: 'rgb(51, 183, 189)'
        },
        {
            name: t('CULTURAL_FIT.WORK_PREFERENCES.CATEGORIES.FAMILY'),
            value: data[0].Family,
            color: 'rgb(26, 162, 119)'
        },
        {
            name: t('CULTURAL_FIT.WORK_PREFERENCES.CATEGORIES.MARKET'),
            value: data[0].Market,
            color: 'rgb(74, 98, 173)'
        },
        {
            name: t('CULTURAL_FIT.WORK_PREFERENCES.CATEGORIES.HIERARCHY'),
            value: data[0].Hierarchy,
            color: 'rgb(174, 80, 157)'
        }
    ];

    const bars = chartData && chartData.length && chartData.map((item, i)=>{
            return (
                <div className="bar" style={{'backgroundColor': item.color, 'width': item.value + '%'}}  key={i}>
                    <div className="value" style={{'color': item.color, 'width': item.value + '%'}}  key={i}>
                        <span>{item.value}%</span>
                    </div>
                </div>
            )
    });

    const legends = chartData && chartData.length && chartData.map((item, i)=>{
            return (
                <div className="legend" key={i}>
                    <span className="square" style={{'backgroundColor': item.color}}></span>
                    <span className="label">{item.name}</span>
                </div>
            )
    });

    return (
        <div className="baseline-bar">
            <div className="legends">
                {legends}
            </div>
            <div className="bars">
                {bars}
            </div>
        </div>
    );
};

export default WorkPreferenceBaseLineBarChart;
