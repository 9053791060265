import React, { FunctionComponent } from 'react';
import styles from './ErrorPage.module.css';

export type IconColors = 'default' | 'danger' | 'warning' | 'info';

interface ErrorPageProps {
  icon?: any;
  iconColor?: IconColors;
  message?: string;
}

const ErrorPage: FunctionComponent<ErrorPageProps> = (props) => {
  return (
    <div className={styles.errorMessage}>
      <props.icon size={25} color={props.iconColor} /> &nbsp;
      <span>{props.message}</span>
    </div>
  );
};

export default ErrorPage;
