import React, { FunctionComponent, useEffect } from 'react';
import { auth0Client } from 'Auth';
import { useSelector, useDispatch } from 'react-redux';
import styles from './App.module.css';
import TopbarNavigation from '../../components/TopbarNavigation/TopbarNavigation';
import { DASHBOARD_URL } from '../../utils/environment-variables';
import Authenticator from '../Authenticator';
import CookieConsent from '../../components/CookieConsent/CookieConsent';
import { AuthenticatedUser } from '../../typings/auth';
import { selectAuthState } from '../../store/auth/auth.selectors';
import { actions as companiesActions } from '../../store/companies/companies.actions';
import { UserRole } from 'typings/user';

interface AuthenticatorProps {
    children: React.ReactElement;
    addLayout?: boolean;
}

export interface AuthenticatedUserProps {
    userRoles: UserRole[];
    hasMultipleCompanies: boolean;
}

const WithAuthLayout: FunctionComponent<AuthenticatorProps> = (props) => {
    const { children, addLayout } = props;

    const { user } = useSelector(selectAuthState);
    const dispatch = useDispatch();

    const getRoles = (user: AuthenticatedUser): UserRole[] => {
        const hasRole = (roleName: string): boolean =>
            user &&
            user.roles &&
            !!user.roles.length &&
            (user.roles.includes(roleName) ||
                user.roles.includes(`Prod-${roleName}`) ||
                user.roles.includes(`Dev-${roleName}`)); // ToDo: Remove the prefixed role names

        const userRoles: UserRole[] = [];
        if (hasRole('Admin') && user.company_id === 'ALL') {
            userRoles.push('super-admin');
        }
        if (hasRole('Company-Admin') && user.company_id) {
            userRoles.push('company-admin');
        }
        if (hasRole('Recruiter-User') && user.company_id) {
            userRoles.push('recruiter-user');
        }
        if (hasRole('Candidate')) {
            userRoles.push('candidate');
        }
        return userRoles;
    };

    const userRoles: UserRole[] = getRoles(user);

    const hasMultipleCompanies =
        user &&
        (user.company_id === 'ALL' ||
            (Array.isArray(user.company_id) && user.company_id.length > 1));

    useEffect(() => {
        if (user && user.company_id) {
            if (!userRoles.includes('super-admin')) {
                if (typeof user.company_id === 'string') {
                    dispatch(companiesActions.getCompanyById(user.company_id));
                } else if (Array.isArray(user.company_id)) {
                    for (const companyId of user.company_id) {
                        dispatch(companiesActions.getCompanyById(companyId));
                    }
                }
            } else {
                dispatch(companiesActions.getCompanies());
            }
        }
    }, [dispatch, userRoles, user]);

    const dashboardUrl = DASHBOARD_URL || '';
    const onLogout = () => {
        // todo: move to redux-saga
        if (auth0Client) {
            auth0Client.logout({ returnTo: dashboardUrl });
        }
    };

    if (addLayout !== false) {
        require('./style.css');
        return (
            <div className={styles.layout}>
                {/* @ts-ignore*/}
                <TopbarNavigation
                    userRoles={userRoles ? userRoles : []}
                    onLogout={onLogout}
                />
                <div className={styles.content}>
                    <Authenticator>
                        {React.cloneElement(children, {
                            userRoles: userRoles ? userRoles : [],
                            hasMultipleCompanies: hasMultipleCompanies,
                        })}
                    </Authenticator>
                    <CookieConsent />
                </div>
            </div>
        );
    } else {
        return (
            <>
                <Authenticator>
                    {React.cloneElement(children, {
                        userRoles: userRoles ? userRoles : [],
                        hasMultipleCompanies: hasMultipleCompanies,
                    })}
                </Authenticator>
                <CookieConsent />
            </>
        );
    }
};

export default WithAuthLayout;
