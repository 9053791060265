export function msToMinutesString(millis: number): string {
  const minutes: number = Math.floor(millis / 60000);
  const seconds: any = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

export function ISODateToString(isoDate: string): string {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  let day: any = date.getDate();
  let month: any = date.getMonth() + 1;

  if (day < 10) {
    day = `0${day}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  let seconds: any = date.getSeconds();

  if (hours < 10) {
    hours = `0${hours}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${day}/${month}/${year} at ${hours}:${minutes}:${seconds}`;
}

export function formatDate(isoDate: string): string {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  let day: any = date.getDate();
  let month: any = date.getMonth() + 1;

  if (day < 10) {
    day = `0${day}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${day}/${month}/${year}`;
}

export const TimeUtils = {
  msToMinutesString,
  ISODateToString,
};
