import { errorConfig } from './errorMessageConfig';
import { ErrorMessage } from '../../typings/error';

export const getErrorMessageByCode = (
    errorCode: string,
): ErrorMessage | undefined => {
    const prefix = errorCode.charAt(0);
    const moduleCode = errorCode.substring(1, 3);
    const specificError = errorCode.substring(3);

    const prefixDescription = errorConfig.error_code_prefix[prefix];
    const moduleName = errorConfig.modules[moduleCode];

    const errorGroupKey = `errors_${prefix}`;
    const errorsInGroup = errorConfig.errorGroups[errorGroupKey];

    if (!prefixDescription || !moduleName || !errorsInGroup) {
        return undefined;
    }

    const errorDetails = errorsInGroup[specificError];

    if (!errorDetails) {
        return undefined;
    }

    const formattedAdminMessage = `Failed in service: ${prefixDescription} in module: ${moduleName} with error: ${errorDetails.adminMessage}`;
    return {
        adminMessage: formattedAdminMessage,
        clientMessage: errorDetails.clientMessage,
    };
};
