module.exports = {
  textColor: '#425A70',
  secondaryTextColor: '#66788A',
  contrastTextColor: '#F9F9FB',
  titleTextColor: '#435970',
  mainColor: '#1070CA',
  borderColor: '#bbc9d9',
  red: '#EC4C47',
  blue: '#1070CA',
  green: '#47B881',
  yellow: '#F7D154',
  orange: '#D9822B',
  purple: '#735DD0',
  backgroundColor: '#fff',
  lightGrayBackgroundColor: '#F6F6F6',
  darkBackgroundColor: '#234361',
  tealblue: '#00a5ad'
};
