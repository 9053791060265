import { put, call } from 'redux-saga/effects';
import { actionsTypes } from './assessments.actions';
import NeurolyticsAPI from 'services/NeurolyticsAPI';
import { Assessment, AssessmentCreationConfig } from '../../typings/assessment';
import { actions as targetGroupActions } from '../targetGroups/targetGroups.actions';
import {CreateTargetGroupSchema} from '../../typings/target_group';
import { MediaLibraryVideo } from '../../typings';
import {NavigateFunction} from "react-router-dom";

export function* getCompanyAssessments(action: { type: string; payload: any }) {
    try {
        const { companyId, include_modules } = action.payload;
        const assessments: Assessment[] = yield call(
            [NeurolyticsAPI, 'fetchCompanyAssessments'],
            companyId,
            include_modules,
        );
        yield put({
            type: actionsTypes.GET_COMPANY_ASSESSMENTS_SUCCESS,
            payload: {
                companyId,
                assessments,
            },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_COMPANY_ASSESSMENTS_FAILURE,
            payload: { error },
        });
    }
}

export function* getAssessment(action: { type: string; payload: any }) {
    try {
        const { assessmentId } = action.payload;
        const response: Assessment = yield call(
            [NeurolyticsAPI, 'getAssessmentApi'],
            assessmentId,
        );
        yield put({
            type: actionsTypes.GET_ASSESSMENT_SUCCESS,
            payload: { assessment: response },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_ASSESSMENT_FAILURE,
            payload: { error },
        });
    }
}

export function* deleteEmptyScan(action: { type: string; payload: any }) {
    try {
        const { assessmentId, navigate, currentCompanySlug } = action.payload;

        yield call(NeurolyticsAPI.deleteEmptyScan, assessmentId);
        yield put({
            type: actionsTypes.DELETE_SCAN_SUCCESS,
            payload: { assessmentId },
        });
        navigate(`/${currentCompanySlug}/scans`);
    } catch (error) {
        yield put({
            type: actionsTypes.DELETE_SCAN_FAILURE,
            payload: { error },
        });
    }
}

export function* updateAssessmentConfig(action: {
    type: string;
    payload: any;
}) {
    const { assessmentId, data } = action.payload;

    try {
        const response: { assessment: Assessment } = yield call(
            NeurolyticsAPI.updateAssessmentConfig,
            assessmentId,
            data,
        );
        yield put({
            type: actionsTypes.UPDATE_ASSESSMENT_CONFIG_SUCCESS,
            payload: {
                assessmentId,
                assessment: response.assessment,
            },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.UPDATE_ASSESSMENT_CONFIG_FAILURE,
            payload: { error, assessmentId },
        });
    }
}

export function* createAssessment(action: {
    type: string;
    payload: {
        companyId: string;
        assessmentCreationConfig: AssessmentCreationConfig;
    };
}) {
    try {
        const { companyId, assessmentCreationConfig } = action.payload;

        const creationConfig: AssessmentCreationConfig = {
            ...assessmentCreationConfig,
        };

        // upload culture fit video
        if (
            creationConfig.cultureFit &&
            creationConfig.cultureFit.video &&
            !creationConfig.cultureFit.video.id &&
            creationConfig.cultureFit.video.file &&
            (creationConfig.cultureFit.type === 'FULL' ||
                creationConfig.cultureFit.type === 'VIDEO') &&
            creationConfig.cultureFit.videoType === 'CUSTOM'
        ) {
            const uploadedVideo: MediaLibraryVideo = yield call(
                [NeurolyticsAPI, 'uploadVideoForScan'],
                companyId,
                {
                    type: 'culture-fit-video',
                    file_name: creationConfig.cultureFit.video.file.name,
                },
                creationConfig.cultureFit.video.file,
            );
            creationConfig.cultureFit.video.id = uploadedVideo.id;
            creationConfig.cultureFit.video.url = uploadedVideo.url;
            creationConfig.cultureFit.video.file = undefined;
        }

        // upload interview videos
        if (
            creationConfig.speaking &&
            creationConfig.speaking.videos &&
            creationConfig.speaking.videos.length &&
            creationConfig.speaking.videoType === 'CUSTOM'
        ) {
            if (
                creationConfig.speaking.videos[0] &&
                !creationConfig.speaking.videos[0].id &&
                creationConfig.speaking.videos[0].file
            ) {
                const uploadedVideo1: MediaLibraryVideo = yield call(
                    [NeurolyticsAPI, 'uploadVideoForScan'],
                    companyId,
                    {
                        type: 'interview-video',
                        file_name: creationConfig.speaking.videos[0].file.name,
                        params: { interview_video_number: 1 },
                    },
                    creationConfig.speaking.videos[0].file,
                );

                creationConfig.speaking.videos[0].id = uploadedVideo1.id;
                creationConfig.speaking.videos[0].url = uploadedVideo1.url;
                creationConfig.speaking.videos[0].file = undefined;
            }

            if (
                creationConfig.speaking.videos[1] &&
                !creationConfig.speaking.videos[1].id &&
                creationConfig.speaking.videos[1].file
            ) {
                const uploadedVideo2: MediaLibraryVideo = yield call(
                    [NeurolyticsAPI, 'uploadVideoForScan'],
                    companyId,
                    {
                        type: 'interview-video',
                        file_name: creationConfig.speaking.videos[1].file.name,
                        params: { interview_video_number: 2 },
                    },
                    creationConfig.speaking.videos[1].file,
                );
                creationConfig.speaking.videos[1].id = uploadedVideo2.id;
                creationConfig.speaking.videos[1].url = uploadedVideo2.url;
                creationConfig.speaking.videos[1].file = undefined;
            }
        }

        // create assessment
        const createdAssessment: Assessment = yield call(
            [NeurolyticsAPI, 'requestCreateAssessment'],
            companyId,
            assessmentCreationConfig,
        );
        yield put({
            type: actionsTypes.CREATE_ASSESSMENT_SUCCESS,
            payload: { assessment: createdAssessment },
        });

        return createdAssessment.id;
    } catch (error) {
        yield put({
            type: actionsTypes.CREATE_ASSESSMENT_FAILURE,
            payload: { error },
        });
    }
}

export function* createScan(action: {
    type: string;
    payload: {
        companyId: string;
        assessmentCreationConfig: AssessmentCreationConfig;
        createTargetGroupSchema: CreateTargetGroupSchema;
        existingAssessmentId: string;
        navigate: NavigateFunction,
        companySlug: string,
    };
}) {
    const {
        companyId,
        assessmentCreationConfig,
        createTargetGroupSchema,
        existingAssessmentId,
        navigate,
        companySlug,
    } = action.payload;

    try {
        const assessmentId: string = existingAssessmentId
            ? existingAssessmentId
            : yield createAssessment({
                  type: actionsTypes.CREATE_ASSESSMENT,
                  payload: {
                      companyId,
                      assessmentCreationConfig,
                  },
              });
        if (assessmentId && createTargetGroupSchema) {
            yield put(
                targetGroupActions.createTargetGroup(
                    assessmentId,
                    createTargetGroupSchema,
                ),
            );
            yield put({
                type: actionsTypes.CREATE_SCAN_SUCCESS,
            });
        }
        else if(assessmentId && !createTargetGroupSchema) {
            navigate(`/${companySlug}/scans/${assessmentId}`);
        }
        else {
            const error = new Error('Failed to create assessment.');
            yield put({
                type: actionsTypes.CREATE_SCAN_FAILURE,
                payload: { error },
            });
        }
    } catch (error) {
        yield put({
            type: actionsTypes.CREATE_SCAN_FAILURE,
            payload: { error },
        });
    }
}
