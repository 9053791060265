import React, { useRef, useState } from 'react';
import PlayButton from './PlayButton';
import {
    CheckboxWrapper,
    FileCardWrapper,
    HiddenCheckBox,
    Icon,
    PlayButtonWrapper,
    StyledCheckbox,
} from './styles';
import { MediaFile } from '../../typings/mediaFiles';
interface FileCardProps {
    mediaFile: MediaFile;
    checked: string;
    handleSelectedVideo: (fileId: string) => void;
}
const FileCard = (props: FileCardProps) => {
    const { mediaFile, checked, handleSelectedVideo } = props;
    const videoRef = useRef<HTMLVideoElement>(null);
    const [displayPlayButton, setDisplayPlayButton] = useState<boolean>(true);

    return (
        <FileCardWrapper>
            {displayPlayButton && (
                <PlayButtonWrapper
                    onClick={() => {
                        if (videoRef.current) {
                            videoRef.current.play();
                            videoRef.current.controls = true;
                            setDisplayPlayButton(false);
                        }
                    }}>
                    <PlayButton />
                </PlayButtonWrapper>
            )}
            {mediaFile.url && (
                <>
                    <label>
                        <CheckboxWrapper>
                            <HiddenCheckBox
                                checked={checked === mediaFile.id}
                                onChange={() => {
                                    handleSelectedVideo(mediaFile.id);
                                }}
                            />
                            <StyledCheckbox checked={checked === mediaFile.id}>
                                <Icon viewBox="0 0 24 24">
                                    <polyline points="20 6 9 17 4 12" />
                                </Icon>
                            </StyledCheckbox>
                        </CheckboxWrapper>
                    </label>
                    <video
                        src={mediaFile.url}
                        className="file-card-video"
                        controls={false}
                        ref={videoRef}
                        onPause={() => {
                            if (videoRef.current) {
                                videoRef.current.controls = false;
                                setDisplayPlayButton(true);
                            }
                        }}
                    />
                    <div className="file-card-video-sub">
                        <span>
                            {mediaFile.url.substring(
                                mediaFile.url.lastIndexOf('/') + 1,
                            )}
                        </span>
                    </div>
                </>
            )}
        </FileCardWrapper>
    );
};

export default FileCard;
