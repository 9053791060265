import { NavigateFunction } from 'react-router-dom';
import {
    TargetGroupParticipant,
    TargetGroupUpdate,
    CreateTargetGroupSchema,
    GroupType,
} from 'typings/target_group';
import { CreateUserSchema } from 'typings/user';

export const actionsTypes = {
    GET_COMPANY_GROUPS: 'GET_COMPANY_GROUPS',
    GET_COMPANY_GROUPS_SUCCESS: 'GET_COMPANY_GROUPS_SUCCESS',
    GET_COMPANY_GROUPS_FAILURE: 'GET_COMPANY_GROUPS_FAILURE',
    GET_ASSESSMENT_TARGET_GROUPS: 'GET_ASSESSMENT_TARGET_GROUPS',
    GET_ASSESSMENT_TARGET_GROUPS_SUCCESS:
        'GET_ASSESSMENT_TARGET_GROUPS_SUCCESS',
    GET_ASSESSMENT_TARGET_GROUPS_FAILURE:
        'GET_ASSESSMENT_TARGET_GROUPS_FAILURE',
    GET_TARGET_GROUP_BY_ID: 'GET_TARGET_GROUP_BY_ID',
    GET_TARGET_GROUP_BY_ID_SUCCESS: 'GET_TARGET_GROUP_BY_ID_SUCCESS',
    GET_TARGET_GROUP_BY_ID_FAILURE: 'GET_TARGET_GROUP_BY_ID_FAILURE',
    GET_WORK_PREFERENCE_BASELINE: 'GET_WORK_PREFERENCE_BASELINE',
    GET_WORK_PREFERENCE_BASELINE_SUCCESS:
        'GET_WORK_PREFERENCE_BASELINE_SUCCESS',
    GET_WORK_PREFERENCE_BASELINE_FAILURE:
        'GET_WORK_PREFERENCE_BASELINE_FAILURE',
    ADD_PARTICIPANTS: 'ADD_PARTICIPANTS',
    ADD_PARTICIPANTS_SUCCESS: 'ADD_PARTICIPANTS_SUCCESS',
    ADD_PARTICIPANTS_FAILURE: 'ADD_PARTICIPANTS_FAILURE',
    REMOVE_PARTICIPANTS: 'REMOVE_PARTICIPANTS',
    REMOVE_PARTICIPANTS_SUCCESS: 'REMOVE_PARTICIPANTS_SUCCESS',
    REMOVE_PARTICIPANTS_FAILURE: 'REMOVE_PARTICIPANTS_FAILURE',
    FULL_DELETE_PARTICIPANTS: 'FULL_DELETE_PARTICIPANTS',
    FULL_DELETE_PARTICIPANTS_SUCCESS: 'FULL_DELETE_PARTICIPANTS_SUCCESS',
    FULL_DELETE_PARTICIPANTS_FAILURE: 'FULL_DELETE_PARTICIPANTS_FAILURE',
    UPDATE_TARGET_GROUP_CONFIG: 'UPDATE_TARGET_GROUP_CONFIG',
    UPDATE_TARGET_GROUP_CONFIG_SUCCESS: 'UPDATE_TARGET_GROUP_CONFIG_SUCCESS',
    UPDATE_TARGET_GROUP_CONFIG_FAILURE: 'UPDATE_TARGET_GROUP_CONFIG_FAILURE',
    CREATE_TARGET_GROUP: 'CREATE_TARGET_GROUP',
    CREATE_TARGET_GROUP_SUCCESS: 'CREATE_TARGET_GROUP_SUCCESS',
    CREATE_TARGET_GROUP_FAILURE: 'CREATE_TARGET_GROUP_FAILURE',
    DELETE_TARGET_GROUP: 'DELETE_TARGET_GROUP',
    DELETE_TARGET_GROUP_SUCCESS: 'DELETE_TARGET_GROUP_SUCCESS',
    DELETE_TARGET_GROUP_FAILURE: 'DELETE_TARGET_GROUP_FAILURE',
    GET_TARGET_GROUP_FEEDBACK: 'GET_TARGET_GROUP_FEEDBACK',
    GET_TARGET_GROUP_FEEDBACK_SUCCESS: 'GET_TARGET_GROUP_FEEDBACK_SUCCESS',
    GET_TARGET_GROUP_FEEDBACK_FAILURE: 'GET_TARGET_GROUP_FEEDBACK_FAILURE',
    RESET_CANDIDATE_SCAN: 'RESET_CANDIDATE_SCAN',
    RESET_CANDIDATE_SCAN_SUCCESS: 'RESET_CANDIDATE_SCAN_SUCCESS',
    RESET_CANDIDATE_SCAN_FAILURE: 'RESET_CANDIDATE_SCAN_FAILURE',
};

export const actions = {
    getCompanyGroups: (companyId: string, type: GroupType) => ({
        type: actionsTypes.GET_COMPANY_GROUPS,
        payload: { companyId, type },
    }),
    getAssessmentsTargetGroups: (assessmentId: string) => ({
        type: actionsTypes.GET_ASSESSMENT_TARGET_GROUPS,
        payload: { assessmentId },
    }),
    getTargetGroupById: (assessmentId: string, targetGroupId: string) => ({
        type: actionsTypes.GET_TARGET_GROUP_BY_ID,
        payload: { assessmentId, targetGroupId },
    }),
    getWorkPreferenceBaseline: (
        assessmentId: string,
        targetGroupId: string,
    ) => ({
        type: actionsTypes.GET_WORK_PREFERENCE_BASELINE,
        payload: { assessmentId, targetGroupId },
    }),
    deleteEmptyTargetGroup: (
        assessmentId: string,
        targetGroupId: string,
        navigate: NavigateFunction,
        currentCompanySlug?: string,
    ) => ({
        type: actionsTypes.DELETE_TARGET_GROUP,
        payload: { assessmentId, targetGroupId, navigate, currentCompanySlug },
    }),
    addParticipants: (
        assessmentId: string,
        targetGroupId: string,
        participants: CreateUserSchema[],
        companyId: string,
    ) => ({
        type: actionsTypes.ADD_PARTICIPANTS,
        payload: { assessmentId, targetGroupId, participants, companyId },
    }),
    removeParticipants: (
        assessmentId: string,
        targetGroupId: string,
        participants: TargetGroupParticipant[],
    ) => ({
        type: actionsTypes.REMOVE_PARTICIPANTS,
        payload: { assessmentId, targetGroupId, participants },
    }),
    fullDeleteParticipants: (
        assessmentId: string,
        targetGroupId: string,
        participants: TargetGroupParticipant[],
    ) => ({
        type: actionsTypes.FULL_DELETE_PARTICIPANTS,
        payload: { assessmentId, targetGroupId, participants },
    }),
    updateTargetGroupConfig: (
        assessmentId: string,
        targetGroupId: string,
        data: TargetGroupUpdate,
    ) => ({
        type: actionsTypes.UPDATE_TARGET_GROUP_CONFIG,
        payload: { assessmentId, targetGroupId, data },
    }),
    createTargetGroup: (
        assessmentId: string,
        data: CreateTargetGroupSchema,
    ) => ({
        type: actionsTypes.CREATE_TARGET_GROUP,
        payload: { assessmentId, data },
    }),
    getTargetGroupFeedback: (companyId: string, targetGroupId: string) => ({
        type: actionsTypes.GET_TARGET_GROUP_FEEDBACK,
        payload: { companyId, targetGroupId },
    }),
    resetCandidateScan: (
        companyId: string,
        targetGroupId: string,
        userId: string,
    ) => ({
        type: actionsTypes.RESET_CANDIDATE_SCAN,
        payload: { companyId, targetGroupId, userId },
    }),
};
