import { useNavigate } from 'react-router-dom';
import {
    Img,
    SideMenu,
    SideMenuBottom,
    SideMenuInfo,
    SideMenuInfoWrapper,
    SideMenuLogoWrapper,
    SideMenuReturnWrapper,
    SideMenuSection,
    SideMenuSectionIconWrapper,
    SideMenuSectionsWrapper,
    SideMenuTop,
} from './styles';
import { ArrowLeftIcon, EyeOpenIcon } from 'evergreen-ui';
import { ResultsSectionProps, SectionId } from '../../../../typings';

interface SidebarProps extends ResultsSectionProps {
    handleSectionNavigation: (sectionId: SectionId) => void;
}

const ResultsSidebar = (props: SidebarProps) => {
    const { sections, activeSection, handleSectionNavigation } = props;
    const navigate = useNavigate();
    const neurolyticsLogo = '/neurolytics-logo-192.png';

    const sectionsToRender = [];
    for (const id in sections) {
        const sectionId = id as SectionId;
        // const section = sections[sectionId] || sections.summary;
        sectionsToRender.push(
            <SideMenuSection
                key={sectionId}
                isActive={sectionId === activeSection}
                data-testid="side-menu"
                onClick={() => {
                    handleSectionNavigation(sectionId);
                    if (sectionId !== activeSection) {
                        // handleTabNavigation(section.tabs[0].id);
                    }
                }}>
                <SideMenuSectionIconWrapper
                    isActive={sectionId === activeSection}>
                    <EyeOpenIcon />
                </SideMenuSectionIconWrapper>
                {sections[sectionId]?.title}
            </SideMenuSection>,
        );
    }
    return (
        <SideMenu>
            <SideMenuTop>
                <SideMenuLogoWrapper>
                    <Img src={neurolyticsLogo} alt="Neurolytics Logo" />
                </SideMenuLogoWrapper>
                <SideMenuSectionsWrapper>
                    {sectionsToRender}
                </SideMenuSectionsWrapper>
            </SideMenuTop>
            <SideMenuBottom>
                <SideMenuReturnWrapper onClick={() => navigate('/')}>
                    <ArrowLeftIcon />
                    Dashboard
                </SideMenuReturnWrapper>
                <SideMenuInfoWrapper>
                    <SideMenuInfo>General info</SideMenuInfo>
                    <SideMenuInfo>Candidate info</SideMenuInfo>
                </SideMenuInfoWrapper>
            </SideMenuBottom>
        </SideMenu>
    );
};

export default ResultsSidebar;
