import { actionsTypes } from './mediaFiles.actions';
import { MediaFile } from '../../typings/mediaFiles';

export interface MediaFilesState {
    files: {
        [companyId: string]: MediaFile[];
    };
    loading: boolean;
    error: any;
}

export const mediaFilesInitialState: MediaFilesState = {
    files: {},
    loading: false,
    error: null,
};

const mediaFilesReducer = (
    state: MediaFilesState = mediaFilesInitialState,
    action: { type: string; payload?: any },
): MediaFilesState => {
    switch (action.type) {
        case actionsTypes.GET_MEDIA_FILES:
            return {
                ...state,
                loading: true,
            };
        case actionsTypes.GET_MEDIA_FILES_SUCCESS:
            const { mediaFiles, companyId } = action.payload;
            return {
                ...state,
                files: {
                    [companyId]: mediaFiles,
                },
                loading: false,
            };
        case actionsTypes.GET_MEDIA_FILES_FAILURE:
            const { error } = action.payload;
            return {
                ...state,
                error,
                loading: false,
            };
        default:
            return state;
    }
};

export default mediaFilesReducer;
