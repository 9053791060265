import {
    FirstStepWrapper,
    InputSection,
    InputLabel,
    InputWrapper,
    Input,
    InfoIcon,
    InfoTooltip,
    InputLabelSpan,
    InfoIconWrapper,
} from './style';
import {
    ButtonWrapper,
    PrevButton,
    ScanSetupFooterWrapper,
    ScanSetupHeaderTitle,
    ScanSetupHeaderTitleWrapper,
} from '../../../style';
import AppButton from '../../../../../components/AppButton/AppButton';
import React, {useEffect} from 'react';
import ScanStepsFlow from '../../ScanStepsFlow';
import { CreateScanFirstStepProps, ScanLanguage } from '../../../../../typings';
import { Select } from '../SecondStep/style';

const FirstStep = (props: CreateScanFirstStepProps) => {
    const {
        goToNextStep,
        targetGroupName,
        setTargetGroupName,
        description,
        setDescription,
        language,
        setLanguage,
        scanName,
        setScanName,
        isGroupSetupWizard,
        existingAssessmentSection,
    } = props;

    useEffect(()=>{
        if(isGroupSetupWizard && targetGroupName){
            setScanName(`Scan for ${targetGroupName}`)
        }
    },[isGroupSetupWizard, setScanName, targetGroupName])

    return (
        <>
            <ScanSetupHeaderTitleWrapper>
                <ScanSetupHeaderTitle>
                    Create a new {isGroupSetupWizard ? 'scan' : 'group'}
                </ScanSetupHeaderTitle>
                <AppButton
                    aria-label="first step next button"
                    isDisabled={
                        (isGroupSetupWizard && !targetGroupName) ||
                        (!isGroupSetupWizard && !scanName)
                    }
                    onClick={goToNextStep}>
                    Next
                </AppButton>
            </ScanSetupHeaderTitleWrapper>
            <ScanStepsFlow
                isScanSetupWizard={isGroupSetupWizard}
                active={['first']}
                existingAssessmentSection={existingAssessmentSection}
            />
            {isGroupSetupWizard && (
                <FirstStepWrapper aria-label="first step">
                    <InputSection>
                        <InputLabel>Name your group</InputLabel>
                        <InputWrapper>
                            <Input
                                id="target-group-name-input"
                                placeholder={
                                    'e.g. Sales vacancy - February 2022'
                                }
                                value={targetGroupName}
                                onChange={(event) =>
                                    setTargetGroupName(event.target.value)
                                }
                            />
                            <InfoIconWrapper>
                                <InfoIcon>?</InfoIcon>
                                <InfoTooltip>
                                    <p>Give your group a name.</p>
                                    <strong>
                                        Note: Group names are visible to
                                        candidates.
                                    </strong>
                                </InfoTooltip>
                            </InfoIconWrapper>
                        </InputWrapper>
                    </InputSection>
                    <InputSection>
                        <InputLabel>
                            Description
                            <InputLabelSpan>(optional)</InputLabelSpan>
                        </InputLabel>
                        <InputWrapper>
                            <Input
                                id="target-group-description-input"
                                placeholder={
                                    'Describe for which role this scan is or which candidates will be added'
                                }
                                value={description}
                                onChange={(event) =>
                                    setDescription(event.target.value)
                                }
                            />
                            <InfoIconWrapper>
                                <InfoIcon>?</InfoIcon>
                                <InfoTooltip>
                                    Give a more in-depth description of the
                                    candidates in this group.
                                </InfoTooltip>
                            </InfoIconWrapper>
                        </InputWrapper>
                    </InputSection>
                </FirstStepWrapper>
            )}
            {!isGroupSetupWizard && (
                <FirstStepWrapper aria-label="first step">
                    <InputSection>
                        <InputLabel>Name your scan</InputLabel>
                        <InputWrapper>
                            <Input
                                id="scan-name-input"
                                placeholder={'Scan name'}
                                value={scanName}
                                onChange={(event) =>
                                    setScanName(event.target.value)
                                }
                            />
                            <InfoIconWrapper>
                                <InfoIcon>?</InfoIcon>
                                <InfoTooltip>
                                    <p>Give your scan a name</p>
                                </InfoTooltip>
                            </InfoIconWrapper>
                        </InputWrapper>
                    </InputSection>
                    <InputSection>
                        <InputLabel>Language</InputLabel>
                        <InputWrapper>
                            <Select
                                aria-label="select-language"
                                value={language}
                                onChange={(
                                    event: React.ChangeEvent<HTMLSelectElement>,
                                ) =>
                                    setLanguage(
                                        event.target.value as ScanLanguage,
                                    )
                                }>
                                <option value="nl-NL">Dutch</option>
                                <option value="en-US">English</option>
                            </Select>
                            <InfoIconWrapper>
                                <InfoIcon className="with-input">?</InfoIcon>
                                <InfoTooltip>
                                    Select scan language for your candidate
                                    group.
                                </InfoTooltip>
                            </InfoIconWrapper>
                        </InputWrapper>
                    </InputSection>
                </FirstStepWrapper>
            )}
            <ScanSetupFooterWrapper>
                <ButtonWrapper>
                    <PrevButton />
                    <AppButton
                        aria-label="first step next button"
                        isDisabled={
                            (isGroupSetupWizard && !targetGroupName) ||
                            (!isGroupSetupWizard && !scanName)
                        }
                        onClick={goToNextStep}>
                        Next
                    </AppButton>
                </ButtonWrapper>
            </ScanSetupFooterWrapper>
        </>
    );
};

export default FirstStep;
