import React, { FunctionComponent } from 'react';
import styles from './AppButton.module.css';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme } from '@material-ui/core/styles';

interface AppButtonProps {
    children?: any;
    icon?: any;
    color?:
        | 'red'
        | 'green'
        | 'blue'
        | 'teal'
        | 'purple'
        | 'violet'
        | 'tealBlue'
        | 'gray'
        | 'grayWhite'
        | 'grayDisabled';
    size?: 'small' | 'medium' | 'large';
    cursor?: 'disabled' | 'pointer';
    borderRadius?: string;
    isDisabled?: boolean;
    onClick?: () => void;
    width?: string;
    justifyContent?: string;
    tooltipText?: string;
    fontSize?: string;
    fontWeight?: string;
}

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: '#435970',
        boxShadow: '0 0 3px 0 #435970',
        fontSize: 16,
        minWidth: 100,
        borderRadius: 9,
    },
}))(Tooltip);

const AppButton: FunctionComponent<AppButtonProps> = (props) => {
    const colors = {
        red: {
            primary: '#E96B76',
            secondary: '#F8E5E9',
        },
        green: {
            primary: '#00783E',
            secondary: '#D4EEE2',
        },
        blue: {
            primary: '#5F80F5',
            secondary: '#85A0F5',
        },
        teal: {
            primary: '#007489',
            secondary: '#D2EEF3',
        },
        violet: {
            primary: '#37238F',
            secondary: '#EAE7F8',
        },
        purple: {
            primary: '#EAE7F8',
            secondary: '#993B8B',
        },
        tealBlue: {
            primary: '#fff',
            secondary: '#00a5ad',
        },
        gray: {
            primary: '#fff',
            secondary: '#435970',
        },
        grayWhite: {
            primary: '#474d66',
            secondary: '#fff',
        },
        grayDisabled: {
            primary: '#fff',
            secondary: '#bbc9d9',
        },
    };

    const cursors = {
        disabled: 'default',
        pointer: 'pointer',
    };

    const color:
        | 'red'
        | 'green'
        | 'blue'
        | 'teal'
        | 'purple'
        | 'violet'
        | 'tealBlue'
        | 'gray'
        | 'grayDisabled'
        | 'grayWhite' = props.color ? props.color : 'tealBlue';
    const cursor: 'disabled' | 'pointer' = props.cursor
        ? props.cursor
        : 'pointer';
    const borderRadius: string = props.borderRadius
        ? props.borderRadius
        : '7px';
    const width: string = props.width ? props.width : 'fit-content';
    const justifyContent: string = props.justifyContent ? props.justifyContent : 'center';
    const hasTooltip: boolean = !!props.tooltipText;
    const tooltipText: string = props.tooltipText ? props.tooltipText : '';
    const fontSize: string = props.fontSize ? props.fontSize : '16px';
    const fontWeight: string = props.fontWeight ? props.fontWeight : 'bold';

    const primaryColor = (): string => {
        if (props.isDisabled) {
            return colors.grayDisabled.primary;
        } else {
            return colors[color].primary;
        }
    };

    const secondaryColor = () => {
        if (props.isDisabled) {
            return colors.grayDisabled.secondary;
        } else {
            return colors[color].secondary;
        }
    };

    const cursorType = () => {
        if (props.isDisabled) {
            return 'default';
        } else {
            return cursors[cursor];
        }
    };

    return (
        <>
            {hasTooltip && (
                <LightTooltip title={tooltipText} placement="bottom">
                    <div
                        className={styles.button}
                        style={{
                            width: width,
                            justifyContent: justifyContent,
                            background: secondaryColor(),
                            cursor: cursorType(),
                            borderRadius: borderRadius,
                        }}
                        onClick={
                            props.isDisabled
                                ? undefined
                                : () => props.onClick && props.onClick()
                        }>
                        {props.icon && (
                            <props.icon size={20} color={primaryColor()} />
                        )}
                        <span
                            style={{
                                color: primaryColor(),
                                marginLeft: props.icon ? '10px' : '0',
                                fontSize: fontSize,
                                fontWeight: fontWeight,
                            }}>
                            {props.children}
                        </span>
                    </div>
                </LightTooltip>
            )}
            {!hasTooltip && (
                <div
                    className={styles.button}
                    style={{
                        width: width,
                        justifyContent: justifyContent,
                        background: secondaryColor(),
                        cursor: cursorType(),
                        borderRadius: borderRadius,
                    }}
                    onClick={
                        props.isDisabled
                            ? undefined
                            : () => props.onClick && props.onClick()
                    }>
                    {props.icon && (
                        <props.icon size={20} color={primaryColor()} />
                    )}
                    <span
                        style={{
                            color: primaryColor(),
                            marginLeft: props.icon ? '10px' : '0',
                            fontSize: fontSize,
                            fontWeight: fontWeight,
                        }}>
                        {props.children}
                    </span>
                </div>
            )}
        </>
    );
};

export default AppButton;
