import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ThemeProvider } from '@material-ui/core/styles';

import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FunctionComponent, ReactNode } from "react";
import AppButton from 'components/AppButton/AppButton';
import { EnvelopeIcon, DeleteIcon } from 'evergreen-ui';
import styles from './Dialog.module.css';
import { theme } from 'components/Dialog/theme';

interface DialogProps {
  children: ReactNode;
  isShown: boolean;
  onClose: () => void;
  closeButtonText?: string;
  onConfirm?: (event?: any) => void;
  title?: string;
  titleBackgroundColor?: 'white' | null;
  confirmButtonText?: string;
  confirmIcon?: object;
  isConfirmButtonDisabled?: boolean;
  buttonColor?: 'red' | 'green' | 'blue' | 'teal' | 'purple' | 'tealBlue' | 'gray' | 'grayDisabled' | null;
}

const DialogComponent: FunctionComponent<DialogProps> = (props) => {
  const isShown = props.isShown ? props.isShown : false;
  const confirmIcon = props.confirmIcon ? props.confirmIcon : EnvelopeIcon;
  const buttonColor = props.buttonColor || undefined;
  const titleBackgroundColor = props.titleBackgroundColor || undefined;
  const isDisabled = props.isConfirmButtonDisabled || false;

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isShown} onClose={() => props.onClose()}>
        <DialogTitle id="alert-dialog-title" className={styles['MuiDialogTitle-root']} style={{backgroundColor:titleBackgroundColor}}>
          {!!titleBackgroundColor && <span style={{color:"#435970"}}>{props.title}</span>}
          {!titleBackgroundColor && props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={'div'}>
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={styles.appButton}>
            <AppButton onClick={() => props.onClose()} icon={DeleteIcon} color="gray">
              {props.closeButtonText || 'Cancel'}
            </AppButton>
          </div>

          {props.onConfirm && (
            <AppButton onClick={() => props.onConfirm && props.onConfirm()} icon={confirmIcon} color={buttonColor} isDisabled={isDisabled}>
              {props.confirmButtonText}
            </AppButton>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogComponent;
