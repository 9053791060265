import { ErrorConfig } from '../../typings/error';

export const errorConfig: ErrorConfig = {
    error_code_prefix: {
        A: 'Video converter listener',
        B: 'Video converter',
        C: 'Analysis/OPENFACE',
        D: 'Analysis/HR',
        E: 'Analysis prediction',
        F: 'Questionnaire listener',
        G: 'Questionnaire analysis',
    },
    modules: {
        '00': 'Culture fit',
        '01': 'Interview',
        '02': 'Mental test',
    },
    errorGroups: {
        errors_A: {
            '00': {
                adminMessage: 'Default error - Unknown',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
        },
        errors_B: {
            '00': {
                adminMessage: 'Default error - Unknown',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
        },
        errors_C: {
            '00': {
                adminMessage:
                    'Failed during main server method - Unknown error',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '01': {
                adminMessage: 'No user input found on env variables.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '02': {
                adminMessage: 'Failed to get analysis JSON.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '03': {
                adminMessage: 'Problem downloading video file.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '04': {
                adminMessage: 'NO face on video.',
                clientMessage:
                    'The analysis couldn’t be completed because the person is not visible in the video. Please reach out to support. ',
            },
            '05': {
                adminMessage: 'More than 30% of frames with NO face.',
                clientMessage:
                    'The analysis couldn’t be completed because the person is not visible in the video. Please reach out to support. ',
            },
            '06': {
                adminMessage: 'Failed to send request for predictions.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '07': {
                adminMessage: 'Open face analysis not successful.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
        },
        errors_D: {
            '00': {
                adminMessage:
                    'Failed during main server method - Unknown error',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '01': {
                adminMessage: 'No user input found on env variables.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '02': {
                adminMessage: 'Failed to get analysis JSON.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '03': {
                adminMessage: 'Problem downloading video file.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '04': {
                adminMessage: 'NO face on video.',
                clientMessage:
                    'The analysis couldn’t be completed because the person is not visible in the video. Please reach out to support. ',
            },
            '05': {
                adminMessage: 'More than 30% of frames with NO face.',
                clientMessage:
                    'The analysis couldn’t be completed because the person is not visible in the video. Please reach out to support. ',
            },
            '06': {
                adminMessage: 'Failed to send request for predictions.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '07': {
                adminMessage: 'Open face analysis not successful.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
        },
        errors_E: {
            '00': {
                adminMessage:
                    'Failed during make prediction method - Unknown error',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '01': {
                adminMessage: 'No user input found on event for lambda.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '02': {
                adminMessage: 'Failed to get analysis JSON.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '03': {
                adminMessage: 'Failed trying to get buckets and modules.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '04': {
                adminMessage: 'No video module found in json.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '05': {
                adminMessage: 'Module does not correspond to video.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '06': {
                adminMessage:
                    'Failed downloading files of openface and HR from S3.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '07': {
                adminMessage: 'Video is probably corrupted or all black.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '08': {
                adminMessage: 'Failed to send score.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '09': {
                adminMessage: 'Error uploading file to S3.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '10': {
                adminMessage: 'Failed prepare emotion data.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '11': {
                adminMessage: 'Failed prepare HR data.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
        },
        errors_F: {
            '00': {
                adminMessage: 'Default error - Unknown',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
        },
        errors_G: {
            '00': {
                adminMessage: 'Default error - Unknown',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '02': {
                adminMessage: 'Failed to get analysis JSON.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '03': {
                adminMessage: 'Failed to fill JSON file with scores.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
            '04': {
                adminMessage: 'Failed to send score.',
                clientMessage:
                    'Something went wrong in the analysis. Please reach out to support. Apologies for the inconvenience.',
            },
        },
    },
};
