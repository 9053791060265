import React, { useEffect, useState } from 'react';
import {
    AssessmentHeaderDescription,
    AssessmentHeaderTitle,
    AssessmentSelectCard,
    AssessmentSelectCardWrapper,
    Card,
    CardIcon,
    CardsWrapper,
    CardTitle,
    CheckboxContainer,
    HiddenCheckbox,
    Icon,
    InfoIcon,
    InfoIconSign,
    InfoIconWrapper,
    InfoTooltip,
    InfoTooltipSign,
    InfoWrapper,
    InputLabel,
    InputSection,
    InputWrapper,
    AssessmentSection,
    SecondStepWrapper,
    Select,
    SelectCardCheckboxWrapper,
    SelectCardImage,
    SelectCardTitleWrapper,
    StyledCheckbox,
} from './style';
import {
    ButtonWrapper,
    PrevButton,
    ScanSetupFooterWrapper,
    ScanSetupHeaderTitle,
    ScanSetupHeaderTitleWrapper,
} from '../../../style';
import AppButton from '../../../../../components/AppButton/AppButton';
import ScanStepsFlow from '../../ScanStepsFlow';
import { MultiSelectIcon, PlusIcon } from 'evergreen-ui';
import {
    CultureFitImage,
    SpeakingImage,
    CognitiveAbilitiesImage,
} from '@neurolytics/results-template';
import {
    CreateScanSecondStepProps,
    ScanLanguage,
} from '../../../../../typings';
import ScansCardsList from '../../../../../components/ScansCardsList/ScansCardsList';
import { Assessment } from '../../../../../typings/assessment';
import ScanActiveModules from '../../../../../components/ScanActiveModules/ScanActiveModules';

interface IModules {
    cultureFitEngagement: boolean;
    cultureFitWorkPreferences: boolean;
    speaking: boolean;
    cognitiveAbilities: boolean;
}
const SecondStep = (props: CreateScanSecondStepProps) => {
    const {
        goToNextStep,
        goToPrevStep,
        isCultureFitEnabled,
        setIsCultureFitEnabled,
        isCultureFitQuestionnaireEnabled,
        setIsCultureFitQuestionnaireEnabled,
        isCultureFitVideoEnabled,
        setIsCultureFitVideoEnabled,
        isSpeakingEnabled,
        setIsSpeakingEnabled,
        isCognitiveAbilitiesEnabled,
        setIsCognitiveAbilitiesEnabled,
        language,
        setLanguage,
        setCultureFitIsCompleted,
        setSpeakingIsCompleted,
        setCultureFitVideo,
        setSpeakingVideo1,
        setSpeakingVideo2,
        setSpeakingWrittenQuestions,
        setNewAssessmentSection,
        newAssessmentSection,
        existingAssessmentSection,
        setExistingAssessmentSection,
        companySlug,
        assessment,
        setAssessment,
        isScanSetupWizard,
        existingScansAndGroups,
    } = props;
    const [checked, setChecked] = useState<IModules>({
        cultureFitEngagement: isCultureFitVideoEnabled,
        cultureFitWorkPreferences: isCultureFitQuestionnaireEnabled,
        speaking: isSpeakingEnabled,
        cognitiveAbilities: isCognitiveAbilitiesEnabled,
    });
    const [selectedScanId, setSelectedScanID] = useState<string>(assessment.id);
    const [openScanDetails, setOpenScanDetails] = useState<boolean>(false);
    const [selectedScan, setSelectedScan] = useState<Assessment>(assessment);

    const handleCheckboxChange = (chosen: string) => {
        if (chosen === 'engagement') {
            setChecked((state) => ({
                ...state,
                cultureFitEngagement: !checked.cultureFitEngagement,
            }));
            setIsCultureFitVideoEnabled(!checked.cultureFitEngagement);
        }
        if (chosen === 'work-preferences') {
            setChecked((state) => ({
                ...state,
                cultureFitWorkPreferences: !checked.cultureFitWorkPreferences,
            }));
            setIsCultureFitQuestionnaireEnabled(
                !checked.cultureFitWorkPreferences,
            );
        }
        if (chosen === 'speaking') {
            setChecked((state) => ({
                ...state,
                speaking: !checked.speaking,
            }));
            setIsSpeakingEnabled(!checked.speaking);
        }
        if (chosen === 'cognitive-abilities') {
            setChecked((state) => ({
                ...state,
                cognitiveAbilities: !checked.cognitiveAbilities,
            }));
            setIsCognitiveAbilitiesEnabled(!checked.cognitiveAbilities);
        }
    };
    const handleSelectedScan = (scanId: string) => {
        const chosenScan = existingScansAndGroups.find(
            (scanAndGroup) => scanAndGroup.scan.id === scanId,
        )?.scan;
        if (chosenScan) {
            setSelectedScanID(chosenScan.id);
            setSelectedScan(chosenScan);
            setAssessment(chosenScan);
        }
    };
    const handleOpenScanDetails = (scan: Assessment) => {
        setOpenScanDetails(true);
        setSelectedScan(scan);
    };
    const onConfirmation = (step: string) => {
        if (newAssessmentSection || !isScanSetupWizard) {
            setIsCultureFitVideoEnabled(checked.cultureFitEngagement);
            setIsCultureFitQuestionnaireEnabled(
                checked.cultureFitWorkPreferences,
            );
            if (
                checked.cultureFitEngagement ||
                checked.cultureFitWorkPreferences
            )
                setIsCultureFitEnabled(true);
            setIsSpeakingEnabled(checked.speaking);
            setIsCognitiveAbilitiesEnabled(checked.cognitiveAbilities);
            setCultureFitIsCompleted(false);
            setSpeakingIsCompleted(false);
            setCultureFitVideo(undefined);
            setSpeakingVideo1(undefined);
            setSpeakingVideo2(undefined);
            setSpeakingWrittenQuestions(true);
        }
        if (step === 'next')
            goToNextStep(existingAssessmentSection ? 'FORTH' : 'THIRD');
        else goToPrevStep();
    };
    useEffect(() => {
        if (isCultureFitVideoEnabled || isCultureFitQuestionnaireEnabled) {
            setIsCultureFitEnabled(true);
        }
    }, [
        isCultureFitVideoEnabled,
        isCultureFitQuestionnaireEnabled,
        setIsCultureFitEnabled,
    ]);
    useEffect(() => {
        if (existingAssessmentSection) {
            setChecked((state) => ({
                ...state,
                cultureFitEngagement: false,
                cultureFitWorkPreferences: false,
                speaking: false,
                cognitiveAbilities: false,
            }));
            setIsCultureFitVideoEnabled(false);
            setIsCultureFitQuestionnaireEnabled(false);
            setIsCultureFitEnabled(false);
            setIsSpeakingEnabled(false);
            setIsCognitiveAbilitiesEnabled(false);
            setCultureFitIsCompleted(false);
            setSpeakingIsCompleted(false);
            setCultureFitVideo(undefined);
            setSpeakingVideo1(undefined);
            setSpeakingVideo2(undefined);
            setSpeakingWrittenQuestions(false);
        }
    }, [
        existingAssessmentSection,
        setCultureFitIsCompleted,
        setCultureFitVideo,
        setIsCognitiveAbilitiesEnabled,
        setIsCultureFitEnabled,
        setIsCultureFitQuestionnaireEnabled,
        setIsCultureFitVideoEnabled,
        setIsSpeakingEnabled,
        setSpeakingIsCompleted,
        setSpeakingVideo1,
        setSpeakingVideo2,
        setSpeakingWrittenQuestions,
    ]);

    return (
        <>
            <ScanSetupHeaderTitleWrapper>
                <ScanSetupHeaderTitle>Create a new scan</ScanSetupHeaderTitle>
                <AppButton
                    isDisabled={
                        (isScanSetupWizard &&
                            ((newAssessmentSection &&
                                ((!isCultureFitEnabled &&
                                    !isSpeakingEnabled &&
                                    !isCognitiveAbilitiesEnabled) ||
                                    (!checked.cultureFitEngagement &&
                                        !checked.cultureFitWorkPreferences &&
                                        !checked.speaking &&
                                        !checked.cognitiveAbilities))) ||
                                (existingAssessmentSection &&
                                    assessment.id === '') ||
                                (!newAssessmentSection &&
                                    !existingAssessmentSection))) ||
                        (!isScanSetupWizard &&
                            ((!isCultureFitEnabled &&
                                !isSpeakingEnabled &&
                                !isCognitiveAbilitiesEnabled) ||
                                (!checked.cultureFitEngagement &&
                                    !checked.cultureFitWorkPreferences &&
                                    !checked.speaking &&
                                    !checked.cognitiveAbilities)))
                    }
                    onClick={() => onConfirmation('next')}>
                    Next
                </AppButton>
            </ScanSetupHeaderTitleWrapper>
            <ScanStepsFlow
                isScanSetupWizard={isScanSetupWizard}
                active={['first', 'second']}
                existingAssessmentSection={existingAssessmentSection}
            />
            {isScanSetupWizard && (
                <SecondStepWrapper aria-label="second step">
                    <CardsWrapper>
                        <Card
                            aria-label="existing scan section"
                            active={existingAssessmentSection}
                            onClick={() => {
                                setExistingAssessmentSection(true);
                                setNewAssessmentSection(false);
                            }}>
                            <CardIcon>
                                <MultiSelectIcon size={38} />
                            </CardIcon>
                            <CardTitle>Select an existing scan</CardTitle>
                        </Card>
                        <Card
                            aria-label="new scan section"
                            active={newAssessmentSection}
                            onClick={() => {
                                setNewAssessmentSection(true);
                                setExistingAssessmentSection(false);
                            }}>
                            <CardIcon>
                                <PlusIcon size={48} />
                            </CardIcon>
                            <CardTitle>Create a new scan</CardTitle>
                        </Card>
                    </CardsWrapper>
                    {newAssessmentSection && (
                        <AssessmentSection aria-label="new scan content">
                            <AssessmentHeaderTitle>
                                Select your modules
                            </AssessmentHeaderTitle>
                            <AssessmentHeaderDescription>
                                Select the desired scores.
                            </AssessmentHeaderDescription>
                            <AssessmentHeaderDescription>
                                For more information about each module, hover
                                over the info icon.
                            </AssessmentHeaderDescription>
                            <AssessmentSelectCardWrapper>
                                {/******************CULTURAL FIT CARD***********************/}
                                <AssessmentSelectCard>
                                    <SelectCardTitleWrapper>
                                        Cultural Fit
                                        <InfoIconSign>i</InfoIconSign>
                                        <InfoTooltipSign>
                                            The cultural fit module gives
                                            insights on candidate’s match to the
                                            organizations values, culture and
                                            working environment based on company
                                            video and questionnaire.
                                            <br />
                                            Note: A video must be provided!
                                        </InfoTooltipSign>
                                    </SelectCardTitleWrapper>
                                    <SelectCardImage>
                                        <img
                                            src={CultureFitImage}
                                            alt="CultureFitImage"
                                        />
                                    </SelectCardImage>
                                    <SelectCardCheckboxWrapper>
                                        <label>
                                            <CheckboxContainer>
                                                <HiddenCheckbox
                                                    aria-label="engagement check box"
                                                    checked={
                                                        checked.cultureFitEngagement
                                                    }
                                                    onChange={() => {
                                                        handleCheckboxChange(
                                                            'engagement',
                                                        );
                                                    }}
                                                />
                                                <StyledCheckbox
                                                    checked={
                                                        checked.cultureFitEngagement
                                                    }>
                                                    <Icon viewBox="0 0 24 24">
                                                        <polyline points="20 6 9 17 4 12" />
                                                    </Icon>
                                                </StyledCheckbox>
                                            </CheckboxContainer>
                                            <span style={{ marginLeft: 8 }}>
                                                Engagement score
                                            </span>
                                        </label>
                                    </SelectCardCheckboxWrapper>
                                    <SelectCardCheckboxWrapper>
                                        <label>
                                            <CheckboxContainer>
                                                <HiddenCheckbox
                                                    aria-label="work preferences check box"
                                                    checked={
                                                        checked.cultureFitWorkPreferences
                                                    }
                                                    onChange={() => {
                                                        handleCheckboxChange(
                                                            'work-preferences',
                                                        );
                                                    }}
                                                />
                                                <StyledCheckbox
                                                    checked={
                                                        checked.cultureFitWorkPreferences
                                                    }>
                                                    <Icon viewBox="0 0 24 24">
                                                        <polyline points="20 6 9 17 4 12" />
                                                    </Icon>
                                                </StyledCheckbox>
                                            </CheckboxContainer>
                                            <span style={{ marginLeft: 8 }}>
                                                Work preferences scores
                                            </span>
                                        </label>
                                    </SelectCardCheckboxWrapper>
                                </AssessmentSelectCard>
                                {/******************SPEAKING CARD***********************/}
                                <AssessmentSelectCard>
                                    <SelectCardTitleWrapper>
                                        Speaking
                                        <InfoIconSign>i</InfoIconSign>
                                        <InfoTooltipSign>
                                            The Speaking module gives insights
                                            on a candidate’s general motivation
                                            and overall confidence based on the
                                            candidates recording their answers
                                            to two pre-defined questions.
                                        </InfoTooltipSign>
                                    </SelectCardTitleWrapper>
                                    <SelectCardImage>
                                        <img
                                            src={SpeakingImage}
                                            alt="SpeakingImage"
                                        />
                                    </SelectCardImage>
                                    <SelectCardCheckboxWrapper>
                                        <label>
                                            <CheckboxContainer>
                                                <HiddenCheckbox
                                                    aria-label="motivation check box"
                                                    checked={checked.speaking}
                                                    onChange={() => {
                                                        handleCheckboxChange(
                                                            'speaking',
                                                        );
                                                    }}
                                                />
                                                <StyledCheckbox
                                                    checked={checked.speaking}>
                                                    <Icon viewBox="0 0 24 24">
                                                        <polyline points="20 6 9 17 4 12" />
                                                    </Icon>
                                                </StyledCheckbox>
                                            </CheckboxContainer>
                                            <span style={{ marginLeft: 8 }}>
                                                General motivation scores
                                            </span>
                                        </label>
                                    </SelectCardCheckboxWrapper>
                                    <SelectCardCheckboxWrapper>
                                        <label>
                                            <CheckboxContainer>
                                                <HiddenCheckbox
                                                    aria-label="confidence check box"
                                                    checked={checked.speaking}
                                                    onChange={() => {
                                                        handleCheckboxChange(
                                                            'speaking',
                                                        );
                                                    }}
                                                />
                                                <StyledCheckbox
                                                    checked={checked.speaking}>
                                                    <Icon viewBox="0 0 24 24">
                                                        <polyline points="20 6 9 17 4 12" />
                                                    </Icon>
                                                </StyledCheckbox>
                                            </CheckboxContainer>
                                            <span style={{ marginLeft: 8 }}>
                                                Overall confidence scores
                                            </span>
                                        </label>
                                    </SelectCardCheckboxWrapper>
                                </AssessmentSelectCard>
                                {/******************COGNITIVE ABILITIES CARD***********************/}
                                <AssessmentSelectCard>
                                    <SelectCardTitleWrapper>
                                        Cognitive abilities
                                        <InfoIconSign>i</InfoIconSign>
                                        <InfoTooltipSign>
                                            The Cognitive ability module gives
                                            insights on candidates mental
                                            capabilities and stress based on a
                                            timed questionnaire.
                                        </InfoTooltipSign>
                                    </SelectCardTitleWrapper>
                                    <SelectCardImage>
                                        <img
                                            src={CognitiveAbilitiesImage}
                                            alt="CognitiveAbilitiesImage"
                                        />
                                    </SelectCardImage>
                                    <SelectCardCheckboxWrapper>
                                        <label>
                                            <CheckboxContainer>
                                                <HiddenCheckbox
                                                    checked={
                                                        checked.cognitiveAbilities
                                                    }
                                                    onChange={() => {
                                                        handleCheckboxChange(
                                                            'cognitive-abilities',
                                                        );
                                                    }}
                                                />
                                                <StyledCheckbox
                                                    checked={
                                                        checked.cognitiveAbilities
                                                    }>
                                                    <Icon viewBox="0 0 24 24">
                                                        <polyline points="20 6 9 17 4 12" />
                                                    </Icon>
                                                </StyledCheckbox>
                                            </CheckboxContainer>
                                            <span style={{ marginLeft: 8 }}>
                                                Stress resilience scores
                                            </span>
                                        </label>
                                    </SelectCardCheckboxWrapper>
                                    <SelectCardCheckboxWrapper>
                                        <label>
                                            <CheckboxContainer>
                                                <HiddenCheckbox
                                                    checked={
                                                        checked.cognitiveAbilities
                                                    }
                                                    onChange={() => {
                                                        handleCheckboxChange(
                                                            'cognitive-abilities',
                                                        );
                                                    }}
                                                />
                                                <StyledCheckbox
                                                    checked={
                                                        checked.cognitiveAbilities
                                                    }>
                                                    <Icon viewBox="0 0 24 24">
                                                        <polyline points="20 6 9 17 4 12" />
                                                    </Icon>
                                                </StyledCheckbox>
                                            </CheckboxContainer>
                                            <span style={{ marginLeft: 8 }}>
                                                Ease under pressure scores
                                            </span>
                                        </label>
                                    </SelectCardCheckboxWrapper>
                                    <SelectCardCheckboxWrapper>
                                        <label>
                                            <CheckboxContainer>
                                                <HiddenCheckbox
                                                    checked={
                                                        checked.cognitiveAbilities
                                                    }
                                                    onChange={() => {
                                                        handleCheckboxChange(
                                                            'cognitive-abilities',
                                                        );
                                                    }}
                                                />
                                                <StyledCheckbox
                                                    checked={
                                                        checked.cognitiveAbilities
                                                    }>
                                                    <Icon viewBox="0 0 24 24">
                                                        <polyline points="20 6 9 17 4 12" />
                                                    </Icon>
                                                </StyledCheckbox>
                                            </CheckboxContainer>
                                            <span style={{ marginLeft: 8 }}>
                                                Cognitive abilities scores
                                            </span>
                                        </label>
                                    </SelectCardCheckboxWrapper>
                                </AssessmentSelectCard>
                            </AssessmentSelectCardWrapper>
                            {/* ToDo */}
                            <InfoWrapper style={{ display: 'none' }}>
                                <InfoIcon className="information-sign">
                                    !
                                </InfoIcon>
                                <AssessmentHeaderDescription>
                                    You can reuse assessments that you create in
                                    multiple groups. We recommend giving your
                                    assessments a name formula (such as "job
                                    offer + month" or "selected modules | month
                                    + year") to make sure you'll be able to
                                    easily find already existing assessments
                                    quickly.
                                </AssessmentHeaderDescription>
                            </InfoWrapper>
                            <InputSection>
                                <InputLabel>Language</InputLabel>
                                <InputWrapper>
                                    <Select
                                        aria-label="select-language"
                                        value={language}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLSelectElement>,
                                        ) =>
                                            setLanguage(
                                                event.target
                                                    .value as ScanLanguage,
                                            )
                                        }>
                                        <option value="nl-NL">Dutch</option>
                                        <option value="en-US">English</option>
                                    </Select>
                                    <InfoIconWrapper>
                                        <InfoIcon className="with-input">
                                            ?
                                        </InfoIcon>
                                        <InfoTooltip>
                                            Select scan language for your
                                            candidate group.
                                        </InfoTooltip>
                                    </InfoIconWrapper>
                                </InputWrapper>
                            </InputSection>
                        </AssessmentSection>
                    )}
                    {existingAssessmentSection && (
                        <AssessmentSection aria-label="existing scan content">
                            <AssessmentHeaderTitle>
                                Select an existing scan
                            </AssessmentHeaderTitle>
                            <AssessmentHeaderDescription>
                                Below are all the existing scans and which
                                groups are also using that scan.
                                <br />
                                Click on any scan card to review the details.
                                Select one to apply to your new group.
                                <br />
                                If you do not see any fitting existing scans
                                click on "Create new scan" above.
                            </AssessmentHeaderDescription>
                            {companySlug && !openScanDetails && (
                                <ScansCardsList
                                    handleScanDetails={handleOpenScanDetails}
                                    handleSelectedScan={handleSelectedScan}
                                    isScanSetupFlow={true}
                                    checked={selectedScanId}
                                    companySlug={companySlug}
                                    scansAndGroups={existingScansAndGroups}
                                />
                            )}
                            {openScanDetails && (
                                <ScanActiveModules
                                    handleSelectedScan={handleSelectedScan}
                                    closeScanDetails={() =>
                                        setOpenScanDetails(false)
                                    }
                                    scan={selectedScan}
                                />
                            )}
                        </AssessmentSection>
                    )}
                </SecondStepWrapper>
            )}
            {!isScanSetupWizard && (
                <SecondStepWrapper aria-label="second step">
                    <AssessmentSection aria-label="new scan content">
                        <AssessmentHeaderTitle>
                            Select your modules
                        </AssessmentHeaderTitle>
                        <AssessmentHeaderDescription>
                            Select the desired scores.
                        </AssessmentHeaderDescription>
                        <AssessmentHeaderDescription>
                            For more information about each module, hover over
                            the info icon.
                        </AssessmentHeaderDescription>
                        <AssessmentSelectCardWrapper>
                            {/******************CULTURAL FIT CARD***********************/}
                            <AssessmentSelectCard>
                                <SelectCardTitleWrapper>
                                    Cultural Fit
                                    <InfoIconSign>i</InfoIconSign>
                                    <InfoTooltipSign>
                                        The cultural fit module gives insights
                                        on candidate’s match to the
                                        organizations values, culture and
                                        working environment based on company
                                        video and questionnaire.
                                        <br />
                                        Note: A video must be provided!
                                    </InfoTooltipSign>
                                </SelectCardTitleWrapper>
                                <SelectCardImage>
                                    <img
                                        src={CultureFitImage}
                                        alt="CultureFitImage"
                                    />
                                </SelectCardImage>
                                <SelectCardCheckboxWrapper>
                                    <label>
                                        <CheckboxContainer>
                                            <HiddenCheckbox
                                                aria-label="engagement check box"
                                                checked={
                                                    checked.cultureFitEngagement
                                                }
                                                onChange={() => {
                                                    handleCheckboxChange(
                                                        'engagement',
                                                    );
                                                }}
                                            />
                                            <StyledCheckbox
                                                checked={
                                                    checked.cultureFitEngagement
                                                }>
                                                <Icon viewBox="0 0 24 24">
                                                    <polyline points="20 6 9 17 4 12" />
                                                </Icon>
                                            </StyledCheckbox>
                                        </CheckboxContainer>
                                        <span style={{ marginLeft: 8 }}>
                                            Engagement score
                                        </span>
                                    </label>
                                </SelectCardCheckboxWrapper>
                                <SelectCardCheckboxWrapper>
                                    <label>
                                        <CheckboxContainer>
                                            <HiddenCheckbox
                                                aria-label="work preferences check box"
                                                checked={
                                                    checked.cultureFitWorkPreferences
                                                }
                                                onChange={() => {
                                                    handleCheckboxChange(
                                                        'work-preferences',
                                                    );
                                                }}
                                            />
                                            <StyledCheckbox
                                                checked={
                                                    checked.cultureFitWorkPreferences
                                                }>
                                                <Icon viewBox="0 0 24 24">
                                                    <polyline points="20 6 9 17 4 12" />
                                                </Icon>
                                            </StyledCheckbox>
                                        </CheckboxContainer>
                                        <span style={{ marginLeft: 8 }}>
                                            Work preferences scores
                                        </span>
                                    </label>
                                </SelectCardCheckboxWrapper>
                            </AssessmentSelectCard>
                            {/******************SPEAKING CARD***********************/}
                            <AssessmentSelectCard>
                                <SelectCardTitleWrapper>
                                    Speaking
                                    <InfoIconSign>i</InfoIconSign>
                                    <InfoTooltipSign>
                                        The Speaking module gives insights on a
                                        candidate’s general motivation and
                                        overall confidence based on the
                                        candidates recording their answers to
                                        two pre-defined questions.
                                    </InfoTooltipSign>
                                </SelectCardTitleWrapper>
                                <SelectCardImage>
                                    <img
                                        src={SpeakingImage}
                                        alt="SpeakingImage"
                                    />
                                </SelectCardImage>
                                <SelectCardCheckboxWrapper>
                                    <label>
                                        <CheckboxContainer>
                                            <HiddenCheckbox
                                                aria-label="motivation check box"
                                                checked={checked.speaking}
                                                onChange={() => {
                                                    handleCheckboxChange(
                                                        'speaking',
                                                    );
                                                }}
                                            />
                                            <StyledCheckbox
                                                checked={checked.speaking}>
                                                <Icon viewBox="0 0 24 24">
                                                    <polyline points="20 6 9 17 4 12" />
                                                </Icon>
                                            </StyledCheckbox>
                                        </CheckboxContainer>
                                        <span style={{ marginLeft: 8 }}>
                                            General motivation scores
                                        </span>
                                    </label>
                                </SelectCardCheckboxWrapper>
                                <SelectCardCheckboxWrapper>
                                    <label>
                                        <CheckboxContainer>
                                            <HiddenCheckbox
                                                aria-label="confidence check box"
                                                checked={checked.speaking}
                                                onChange={() => {
                                                    handleCheckboxChange(
                                                        'speaking',
                                                    );
                                                }}
                                            />
                                            <StyledCheckbox
                                                checked={checked.speaking}>
                                                <Icon viewBox="0 0 24 24">
                                                    <polyline points="20 6 9 17 4 12" />
                                                </Icon>
                                            </StyledCheckbox>
                                        </CheckboxContainer>
                                        <span style={{ marginLeft: 8 }}>
                                            Overall confidence scores
                                        </span>
                                    </label>
                                </SelectCardCheckboxWrapper>
                            </AssessmentSelectCard>
                            {/******************COGNITIVE ABILITIES CARD***********************/}
                            <AssessmentSelectCard>
                                <SelectCardTitleWrapper>
                                    Cognitive abilities
                                    <InfoIconSign>i</InfoIconSign>
                                    <InfoTooltipSign>
                                        The Cognitive ability module gives
                                        insights on candidates mental
                                        capabilities and stress based on a timed
                                        questionnaire.
                                    </InfoTooltipSign>
                                </SelectCardTitleWrapper>
                                <SelectCardImage>
                                    <img
                                        src={CognitiveAbilitiesImage}
                                        alt="CognitiveAbilitiesImage"
                                    />
                                </SelectCardImage>
                                <SelectCardCheckboxWrapper>
                                    <label>
                                        <CheckboxContainer>
                                            <HiddenCheckbox
                                                checked={
                                                    checked.cognitiveAbilities
                                                }
                                                onChange={() => {
                                                    handleCheckboxChange(
                                                        'cognitive-abilities',
                                                    );
                                                }}
                                            />
                                            <StyledCheckbox
                                                checked={
                                                    checked.cognitiveAbilities
                                                }>
                                                <Icon viewBox="0 0 24 24">
                                                    <polyline points="20 6 9 17 4 12" />
                                                </Icon>
                                            </StyledCheckbox>
                                        </CheckboxContainer>
                                        <span style={{ marginLeft: 8 }}>
                                            Stress resilience scores
                                        </span>
                                    </label>
                                </SelectCardCheckboxWrapper>
                                <SelectCardCheckboxWrapper>
                                    <label>
                                        <CheckboxContainer>
                                            <HiddenCheckbox
                                                checked={
                                                    checked.cognitiveAbilities
                                                }
                                                onChange={() => {
                                                    handleCheckboxChange(
                                                        'cognitive-abilities',
                                                    );
                                                }}
                                            />
                                            <StyledCheckbox
                                                checked={
                                                    checked.cognitiveAbilities
                                                }>
                                                <Icon viewBox="0 0 24 24">
                                                    <polyline points="20 6 9 17 4 12" />
                                                </Icon>
                                            </StyledCheckbox>
                                        </CheckboxContainer>
                                        <span style={{ marginLeft: 8 }}>
                                            Ease under pressure scores
                                        </span>
                                    </label>
                                </SelectCardCheckboxWrapper>
                                <SelectCardCheckboxWrapper>
                                    <label>
                                        <CheckboxContainer>
                                            <HiddenCheckbox
                                                checked={
                                                    checked.cognitiveAbilities
                                                }
                                                onChange={() => {
                                                    handleCheckboxChange(
                                                        'cognitive-abilities',
                                                    );
                                                }}
                                            />
                                            <StyledCheckbox
                                                checked={
                                                    checked.cognitiveAbilities
                                                }>
                                                <Icon viewBox="0 0 24 24">
                                                    <polyline points="20 6 9 17 4 12" />
                                                </Icon>
                                            </StyledCheckbox>
                                        </CheckboxContainer>
                                        <span style={{ marginLeft: 8 }}>
                                            Cognitive abilities scores
                                        </span>
                                    </label>
                                </SelectCardCheckboxWrapper>
                            </AssessmentSelectCard>
                        </AssessmentSelectCardWrapper>
                    </AssessmentSection>
                </SecondStepWrapper>
            )}
            <ScanSetupFooterWrapper>
                <ButtonWrapper>
                    <PrevButton>
                        <AppButton
                            color={'gray'}
                            onClick={() => onConfirmation('previous')}>
                            Previous
                        </AppButton>
                    </PrevButton>
                    <AppButton
                        isDisabled={
                            (isScanSetupWizard &&
                                ((newAssessmentSection &&
                                    ((!isCultureFitEnabled &&
                                        !isSpeakingEnabled &&
                                        !isCognitiveAbilitiesEnabled) ||
                                        (!checked.cultureFitEngagement &&
                                            !checked.cultureFitWorkPreferences &&
                                            !checked.speaking &&
                                            !checked.cognitiveAbilities))) ||
                                    (existingAssessmentSection &&
                                        assessment.id === '') ||
                                    (!newAssessmentSection &&
                                        !existingAssessmentSection))) ||
                            (!isScanSetupWizard &&
                                ((!isCultureFitEnabled &&
                                    !isSpeakingEnabled &&
                                    !isCognitiveAbilitiesEnabled) ||
                                    (!checked.cultureFitEngagement &&
                                        !checked.cultureFitWorkPreferences &&
                                        !checked.speaking &&
                                        !checked.cognitiveAbilities)))
                        }
                        onClick={() => onConfirmation('next')}>
                        Next
                    </AppButton>
                </ButtonWrapper>
            </ScanSetupFooterWrapper>
        </>
    );
};

export default SecondStep;
