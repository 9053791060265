import { Participant } from '../../../typings/user';
import styles from './TargetGroupTable.module.css';
import CandidateScoresTable from './CandidateScoresTable';

interface CandidateActivityTableProps {
    participant: Participant;
    invitationId: string;
    hasCultureFitEngagementModule: boolean;
    hasCultureFitWorkPreferencesModule: boolean;
    hasSpeakingModule: boolean;
    hasCognitiveAbilitiesModule: boolean;
}

const CandidateActivityTable = (props: CandidateActivityTableProps) => {
    const {
        participant,
        hasCultureFitEngagementModule,
        hasCultureFitWorkPreferencesModule,
        hasSpeakingModule,
        hasCognitiveAbilitiesModule,
    } = props;

    const { activity } = participant;

    const { scores } = activity || {};

    return (
        <div className={styles.user_activity}>
            <CandidateScoresTable
                scores={scores}
                hasCultureFitEngagementModule={hasCultureFitEngagementModule}
                hasCultureFitWorkPreferencesModule={
                    hasCultureFitWorkPreferencesModule
                }
                hasSpeakingModule={hasSpeakingModule}
                hasCognitiveAbilitiesModule={hasCognitiveAbilitiesModule}
            />
        </div>
    );
};

export default CandidateActivityTable;
