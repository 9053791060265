import React, { useEffect, useState } from 'react';
import styles from './ManageUsersModals.module.css';
import DialogComponent from 'components/Dialog/Dialog';
import { AddIcon, TrashIcon } from 'evergreen-ui';

import {
    CreateDashboardUserSchema,
    DashboardUser,
} from 'typings/dashboard_user';

interface ManageUsersModalsProps {
    onDiscardModal: () => void;
    shouldShowCreateUserModal?: boolean;
    showDeleteRecruiterModal?: boolean;
    userToCreate?: CreateDashboardUserSchema;
    companyAdminRoleId?: string;
    recruiterUserRoleId?: string;
    onConfirmCreateUser?: () => void;
    handleDeleteRecruiterUser?: () => void;
    selectedUsers?: DashboardUser[];
}

const ManageUsersModals: React.FC<ManageUsersModalsProps> = (
    props: ManageUsersModalsProps,
) => {
    const {
        onDiscardModal,
        shouldShowCreateUserModal,
        showDeleteRecruiterModal,
        userToCreate,
        companyAdminRoleId,
        onConfirmCreateUser,
        selectedUsers,
        handleDeleteRecruiterUser,
    } = props;

    const [showCreateUserModal, setShowCreateUserModal] = useState<
        boolean | undefined
    >(false);

    useEffect(() => {
        if (shouldShowCreateUserModal !== showCreateUserModal) {
            setShowCreateUserModal(shouldShowCreateUserModal);
        }
    }, [shouldShowCreateUserModal, showCreateUserModal]);

    const discardModals = () => {
        setShowCreateUserModal(false);
        onDiscardModal();
    };

    return (
        <React.Fragment>
            {showCreateUserModal && userToCreate && (
                <DialogComponent
                    title="Confirm to add new user to your team"
                    confirmIcon={AddIcon}
                    confirmButtonText="Create user"
                    isShown={showCreateUserModal}
                    onClose={discardModals}
                    onConfirm={onConfirmCreateUser}>
                    <div className={styles.dialog__content}>
                        Are you sure you want to add the following user to your
                        team?
                        <br />
                        <i>
                            <b>Please note:</b> once you confirm, this user will
                            automatically receive an email to finish setting up
                            their account by creating a new password, and they
                            will be able to log into the dashboard right away.
                        </i>
                        <ul className={styles.dialog__list}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <li>
                                                <b>Name:</b>{' '}
                                                {`${userToCreate.first_name} ${userToCreate.last_name}`}
                                            </li>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <li>
                                                <b>Email:</b>{' '}
                                                {userToCreate.email}
                                            </li>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <li>
                                                <b>Role:</b>{' '}
                                                {userToCreate.role_id ===
                                                companyAdminRoleId
                                                    ? 'Company Admin'
                                                    : 'Recruiter User'}
                                            </li>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>{' '}
                        </ul>
                    </div>
                </DialogComponent>
            )}
            {showDeleteRecruiterModal && !!selectedUsers && (
                <DialogComponent
                    title={
                        selectedUsers.length > 1
                            ? 'Confirm to remove the users'
                            : 'Confirm to remove the user'
                    }
                    confirmButtonText={
                        selectedUsers.length > 1
                            ? 'Remove users'
                            : 'Remove user'
                    }
                    isShown={showDeleteRecruiterModal}
                    onClose={onDiscardModal}
                    onConfirm={handleDeleteRecruiterUser}
                    confirmIcon={TrashIcon}>
                    <div className={styles.dialog__content}>
                        Are you sure you want to remove the following{' '}
                        {selectedUsers.length > 1 ? 'users' : 'user'} from your
                        team?
                        <ul className={styles.dialog__list}>
                            <table>
                                <tbody>
                                    {selectedUsers.map((user, index) => {
                                        return (
                                            <React.Fragment
                                                key={'table__' + user + index}>
                                                <tr>
                                                    <td>
                                                        <li>
                                                            <b>Name:</b>{' '}
                                                            {user.name
                                                                ? `${user.name}`
                                                                : `${user.first_name} ${user.last_name}`}
                                                        </li>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <li>
                                                            <b>Email:</b>{' '}
                                                            {user.email}
                                                        </li>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <li>
                                                            <b>
                                                                {!!user.roles &&
                                                                user.roles
                                                                    .length > 1
                                                                    ? 'Roles'
                                                                    : 'Role'}
                                                                :
                                                            </b>{' '}
                                                            {user.roles?.map(
                                                                (
                                                                    role,
                                                                    index,
                                                                ) => (
                                                                    <span
                                                                        key={
                                                                            index
                                                                        }>
                                                                        {role.name.includes(
                                                                            'Recruiter',
                                                                        )
                                                                            ? 'Recruiter user'
                                                                            : role.name.includes(
                                                                                  'Company',
                                                                              )
                                                                            ? 'Company admin'
                                                                            : role.name.includes(
                                                                                  'Super',
                                                                              )
                                                                            ? 'Super admin user'
                                                                            : 'Candidate user'}
                                                                        {!!user.roles &&
                                                                            user
                                                                                .roles
                                                                                .length >
                                                                                1 &&
                                                                            index <
                                                                                user
                                                                                    .roles
                                                                                    .length -
                                                                                    1 &&
                                                                            ' - '}
                                                                    </span>
                                                                ),
                                                            )}
                                                        </li>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {selectedUsers.length >
                                                            1 &&
                                                            index <
                                                                selectedUsers.length -
                                                                    1 && (
                                                                <li
                                                                    className={
                                                                        styles.border
                                                                    }
                                                                />
                                                            )}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </ul>
                    </div>
                </DialogComponent>
            )}
        </React.Fragment>
    );
};

export default ManageUsersModals;
