import { ResultsSectionProps, SectionId } from '../../../../typings';
import { Content, ContentResult, ReportTabContent } from './styles';

const ResultsContent = (props: ResultsSectionProps) => {
    const { sections, activeSection } = props;

    const contentsToRender = [];
    for (const id in sections) {
        const sectionId = id as SectionId;
        const section = sections[sectionId] || sections.summary;
        contentsToRender.push(
            <Content active={sectionId === activeSection} key={sectionId}>
                {section.tabs.map((tab) => {
                    return (
                        <ReportTabContent id={tab.id} key={tab.id}>
                            <ContentResult id={tab.id}>
                                {tab.content}
                            </ContentResult>
                        </ReportTabContent>
                    );
                })}
            </Content>,
        );
    }

    return <>{contentsToRender}</>;
};

export default ResultsContent;
