import styled from "styled-components";

interface IProps {
    active?: boolean;
    activeTab?: boolean;
}
export const TabNavigationWrapper=styled('div')<IProps>`
  grid-area: navbar;
  font-family: 'Roboto', sans-serif;
  position: sticky;
  top: 90px;
  z-index: 50;
  display: ${({ active }) => active ? 'block': 'none'};
`;
export const Tabs=styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 32px;
  font-size: 14px;
  text-align: center;
  background-color: rgb(0, 165, 173);
  width: 100%;
  height: 100%;
  z-index: 99;
`;
export const TabsItemWrapper=styled('div')<IProps>`
  padding: 8px;
  margin-right: 56px;
  ${({ active }) => active && `
    border-bottom: 4px solid white;
  `}
`;
export const Item=styled.li`
  cursor: pointer;
`;
export const ItemLink=styled('a')<IProps>`
  color: #e9e9e9;
  font-size: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  ${({ activeTab }) => activeTab && `
    color: white;
  `}
`;