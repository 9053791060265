import styled from 'styled-components';

interface IProps {
    completed?: boolean;
    active?: boolean;
}

export const ScanDetailsModel = styled.div`
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 100vh;
    padding: 102px 0;
    width: 99vw;
    background: rgba(67, 89, 112, 0.87);
`;
export const CloseIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;
export const ScanDetailsModalWrapper = styled('div')`
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 32px 60px;
    width: 80%;
    outline: none;
    border: 1px solid #849cb1;
    background-color: white;
    overflow-y: auto;
`;

export const ScanTitleSection = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 56px;
    margin-bottom: 40px;
`;
export const ScanTitle = styled('div')`
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #40596f;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.secondary-title {
        font-size: 20px;
        line-height: 28px;
    }
`;
export const ScanTitleEditWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 6px;
`;
export const ScanModulesSection = styled('div')`
    margin-top: 8px;
`;

export const ScanModuleDescription = styled('div')`
    color: #435970;
    font-size: 16px;
    padding-top: 8px;
    font-weight: normal;
    max-width: 40%;
    line-height: 28px;
    &.secondary-desc {
        max-width: 70%;
    }
`;
export const CardsWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #bbc9d8;
`;
export const CardTitle = styled('div')`
    color: #435970;
    font-size: 20px;
    font-weight: bold;
`;
export const Card = styled('div')`
    width: 100%;
    border: 1px solid transparent;
    padding: 24px 0;
    border-radius: 4px;
    border-bottom: 1px solid #bbc9d8;
`;
export const CardHeader = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    min-height: 56px;
`;
export const OpenedSection = styled('div')`
    width: 100%;
    min-height: 100px;
    padding: 16px 24px 16px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;
export const VideoSection = styled('div')`
    max-width: 500px;
`;
export const TabsWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 0;
`;
export const Tab = styled('div')<IProps>`
    border: 1px solid #a0abb7;
    width: 148px;
    height: 40px;
    color: #a0abb7;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${({ active }) =>
        active &&
        `
        border: 1px solid #a0abb7;
        background-color: #a0abb7;
        color: white;
  `}
`;

export const VideoTitle = styled('div')`
    color: #435970;
    font-size: 16px;
    padding: 16px;
`;

export const WrittenQuestion = styled('div')`
    line-height: 44px;
    color: #435970;
    padding-right: 60px;
`;
export const ScanModuleCheckboxWrapper = styled('div')`
    padding-top: 24px;
    font-size: 16px;
`;
export const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`;
export const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;
export const StyledCheckbox = styled.div<{ checked: boolean }>`
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: ${(props) => (props.checked ? '#a0abb7' : 'white')};
    border-radius: 3px;
    border: ${(props) =>
        props.checked ? '1px solid #a0abb7' : '1px solid #435970'};
    transition: all 150ms;
    ${Icon} {
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    }
`;
export const Label = styled.label<{ checked: boolean }>`
    text-decoration: ${(props) => (props.checked ? 'none' : 'line-through')};
`;
export const VideoPreviewWrapper = styled('div')`
    max-width: 500px;
`;
export const AppButtonWrapper = styled('div')`
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;
