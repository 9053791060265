import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CompaniesListView from 'views/CompaniesListView/CompaniesListView';
import { selectCompaniesState } from 'store/companies/companies.selectors';
import { selectAuthState } from 'store/auth/auth.selectors';
import { Company } from 'store/companies/companies.reducer';
import { AuthenticatedUserProps } from '../../hoc/WithAuthLayout';

interface DashboardProps extends AuthenticatedUserProps {}

const Dashboard: FunctionComponent<DashboardProps> = (props) => {
    const { userRoles, hasMultipleCompanies } = props;
    const { user } = useSelector(selectAuthState);
    const navigate = useNavigate();
    const { companies } = useSelector(selectCompaniesState);

    useEffect(() => {
        if (!hasMultipleCompanies && companies && companies.length) {
            const companyId = Array.isArray(user.company_id)
                ? user.company_id[0]
                : user.company_id;
            const company = companies.find(
                (company: Company) => company.id === companyId,
            );
            if (company && company.slug) {
                navigate(`/${company.slug}`);
            }
        }
    }, [companies, hasMultipleCompanies, navigate, user.company_id]);

    return hasMultipleCompanies ? (
        <CompaniesListView userRoles={userRoles} />
    ) : (
        <></>
    );
};

export default Dashboard;
