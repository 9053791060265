import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    text: {
      secondary: '#435970'
    }
  },
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 800,
        width: 800
      }
    },
    MuiDialogTitle: {
      root: {
        background: '#435970',
        padding: 20
      }
    },
    MuiDialogContent: {
      root: {
        paddingTop: 25,
        paddingRight: 25,
        paddingBottom: 0,
        paddingLeft: 25
      }
    },
    MuiTypography: {
      h6: {
        color: '#fff',
        fontWeight: 700
      }
    },
    MuiDialogActions: {
      root: {
        margin: 0,
        padding: 25
      }
    }
  },

  shape: {
    borderRadius: 15
  }
});
