import styled from 'styled-components';
interface IProps {
    active?: boolean;
}
export const SecondStepWrapper = styled('div')`
    padding: 40px 0;
`;
export const CardsWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 72px;
    padding-bottom: 64px;
`;
export const CardIcon = styled('div')`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgb(187, 201, 217);
    font-size: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const CardTitle = styled('div')`
    color: #435970;
    font-size: 20px;
    padding-top: 32px;
    text-align: center;
    font-weight: bold;
`;
export const Card = styled('div')<IProps>`
    width: 300px;
    height: 260px;
    border: 1px solid transparent;
    padding: 32px;
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    &:hover {
        background-color: #435970;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
        ${CardIcon} {
            color: #435970;
            background-color: white;
        }
        ${CardTitle} {
            color: white;
        }
    }
    ${({ active }) =>
        active &&
        `
        background-color: #435970;
        box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
        ${CardIcon} {
            color: #435970;
            background-color: white;
        }
        ${CardTitle} {
            color: white;
        }
  `}
`;
export const AssessmentSection = styled('div')`
    color: #435970;
    font-size: 20px;
    padding: 32px 0;
    border-top: 1px solid #435970;
`;
export const AssessmentHeaderTitle = styled('div')`
    color: #435970;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 12px;
`;
export const AssessmentHeaderDescription = styled('div')`
    color: #435970;
    font-size: 16px;
    padding-top: 8px;
    font-weight: normal;
    max-width: 53%;
    line-height: 28px;
    &.full-width {
        max-width: 80%;
        padding-top: 0;
    }
`;
export const AssessmentSelectCardWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 40px 0;
`;
export const AssessmentSelectCard = styled('div')`
    width: 28%;
    height: 500px;
    border: 1px solid #435970;
    padding: 16px;
    @media only screen and (min-width: 2000px) {
        height: 650px;
    }
`;
export const SelectCardImage = styled('div')`
    width: 70%;
    padding-bottom: 32px;
`;
export const InfoIconSign = styled('div')`
    font-size: 14px;
    font-weight: bold;
    padding-top: 5px;
    color: white;
    width: 26px;
    height: 26px;
    background-color: #435970;
    border-radius: 50%;
    margin-left: 24px;
    text-align: center;
    &:hover {
        background-color: #849cb1;
        cursor: pointer;
    }
`;
export const InfoTooltipSign = styled('div')`
    font-size: 13px;
    position: absolute;
    top: calc(100% - 34px);
    left: 64px;
    visibility: hidden;
    color: transparent;
    background-color: transparent;
    width: 250px;
    padding: 20px;
    border-radius: 18px;
    transition: visibility 0.5s, color 0.5s, background-color 0.5s,
        padding 0.5s ease-in-out;
`;
export const SelectCardTitleWrapper = styled('div')`
    color: #435970;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & ${InfoIconSign}:hover + ${InfoTooltipSign} {
        visibility: visible;
        color: #435970;
        border: 1px solid #849cb1;
        line-height: 26px;
        width: 250px;
        padding: 20px;
        border-radius: 18px;
        background-color: #f0f6fb;
        cursor: pointer;
    }
`;
export const SelectCardCheckboxWrapper = styled('div')`
    padding-top: 24px;
    font-size: 16px;
`;

export const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
`;
export const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;
export const StyledCheckbox = styled.div<{ checked: boolean }>`
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: ${(props) => (props.checked ? '#00A5AC' : 'white')};
    border-radius: 3px;
    border: ${(props) =>
        props.checked ? '1px solid #00A5AC' : '1px solid #435970'};
    transition: all 150ms;
    &:hover {
        background-color: #00a5ac;
    }
    ${Icon} {
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    }
`;
export const InputSection = styled('div')`
    margin: 40px 0;
`;
export const InputLabel = styled('div')`
    color: #435970;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;
export const Select = styled('select')`
    width: 20%;
    height: 40px;
    padding: 0 12px;
    outline: none;
    background-color: white;
    border: 1px solid #849cb1;
    &::placeholder {
        color: #849cb1;
    }
    &:hover {
        border: 1px solid #435970;
    }
    &:focus {
        border: 1px solid #435970;
    }
    &.modal-select {
        width: 50%;
    }
`;
export const InfoTooltip = styled('div')`
    font-size: 13px;
    position: absolute;
    top: calc(100% - 44px);
    left: 64px;
    visibility: hidden;
    color: transparent;
    background-color: transparent;
    width: 250px;
    padding: 20px;
    border-radius: 18px;
    transition: visibility 0.5s, color 0.5s, background-color 0.5s,
        padding 0.5s ease-in-out;
`;
export const InfoIcon = styled('div')`
    font-size: 14px;
    font-weight: bold;
    padding-top: 5px;
    color: white;
    width: 26px;
    height: 26px;
    background-color: #435970;
    border-radius: 50%;
    margin: 0 0 11px 24px;
    text-align: center;
    &:hover {
        background-color: #849cb1;
        cursor: pointer;
    }
    &.with-input {
        margin-bottom: 0;
    }
    &.information-sign {
        font-size: 24px;
        margin: 0;
        padding-top: 7px;
        width: 40px;
        height: 40px;
        background-color: #00a5ac;
        cursor: auto;
    }
    &.secondary {
        background-color: #a0abb7;
    }
`;

export const InputWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;
export const InfoIconWrapper = styled('div')`
    position: relative;
    & ${InfoIcon}:hover + ${InfoTooltip} {
        visibility: visible;
        color: #435970;
        border: 1px solid #849cb1;
        line-height: 26px;
        width: 250px;
        padding: 20px;
        border-radius: 18px;
        background-color: #f0f6fb;
        cursor: pointer;
    }
`;
export const InfoWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
`;
