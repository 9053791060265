import React, {
    FunctionComponent,
    useState,
    ChangeEvent,
    ChangeEventHandler,
} from 'react';
import styles from './AddParticipantsTable.module.css';
import AppButton from '../../../components/AppButton/AppButton';
import DialogComponent from 'components/Dialog/Dialog';
import ProgressSpinner from 'components/ProgressSpinner/ProgressSpinner';

import {
    TextInput,
    toaster,
    AddIcon,
    InsertIcon,
    DeleteIcon,
    UploadIcon,
    DownloadIcon,
} from 'evergreen-ui';
import _ from 'lodash';
import { CreateUserSchema } from 'typings/user';
import * as XLSX from 'xlsx';

interface UsersSchema extends CreateUserSchema {
    id?: number;
}

interface AddParticipantsTableProps {
    onSubmit: (users: UsersSchema[]) => void;
    onCancel: () => void;
    showConfirmModal: (arg: boolean) => void;
    shouldShowConfirmModal: boolean;
    onAddParticipants: (participants: CreateUserSchema[]) => void;
    assessmentId: string;
}

const AddParticipantsTable: FunctionComponent<AddParticipantsTableProps> = (
    props: AddParticipantsTableProps,
) => {
    const columns = [
        {
            id: 'email',
            label: 'Email',
        },
        {
            id: 'first_name',
            label: 'First Name',
        },
        {
            id: 'last_name',
            label: 'Last Name',
        },
    ];

    const initialUserState = [
        { id: 1, email: '', first_name: '', last_name: '' },
        { id: 2, email: '', first_name: '', last_name: '' },
        { id: 3, email: '', first_name: '', last_name: '' },
        { id: 4, email: '', first_name: '', last_name: '' },
        { id: 5, email: '', first_name: '', last_name: '' },
    ];

    const [users, setUsers] =
        useState<
            { id: any; email: string; first_name: string; last_name: string }[]
        >(initialUserState);
    const [isCsvDataLoading, setIsCsvDataLoading] = useState<boolean>(false);
    const emailRegEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const showWarnings = (
        key: 'email' | 'first_name' | 'last_name',
        value: string,
    ) => {
        const keyNames = {
            email: 'Email',
            first_name: 'First name',
            last_name: 'Last name',
        };

        if (value === '') {
            toaster.warning(keyNames[key] + ' cannot be empty');
        }
    };
    const showWarningsDebouncer = _.debounce(showWarnings, 500);

    const handleUpdateUser = (
        userId: number,
        key: 'email' | 'first_name' | 'last_name',
        value: string,
    ) => {
        if (key === 'email') {
            setUsers((prevState) => {
                return prevState.map((user, index) =>
                    index === userId
                        ? { ...user, id: user.id, email: value.toLowerCase() }
                        : user,
                );
            });
        }
        if (key === 'first_name') {
            setUsers((prevState) => {
                return prevState.map((user, index) =>
                    index === userId
                        ? { ...user, id: user.id, first_name: value }
                        : user,
                );
            });
        }
        if (key === 'last_name') {
            setUsers((prevState) => {
                return prevState.map((user, index) =>
                    index === userId
                        ? { ...user, id: user.id, last_name: value }
                        : user,
                );
            });
        }
        showWarningsDebouncer(key, value);
    };

    const addThreeRowsToBottomOfTable = () => {
        const ids = Object.keys(users);
        const lastId = ids[ids.length - 1];
        const nextIds = [1, 2, 3].map((x) => {
            return +lastId + x;
        });
        const newUserRows = nextIds.map((newId) => {
            return {
                id: newId,
                email: '',
                first_name: '',
                last_name: '',
            };
        });
        setUsers((prevState) => {
            return [...prevState, ...newUserRows];
        });
    };

    const renderTableHeader = () => {
        return columns.map((column) => (
            <div className={styles.cell} key={'column-' + column.id}>
                <span>{column.label}</span>
            </div>
        ));
    };

    const getEmailInputStyle = (
        userEmail: string,
        userFirstName: string,
        userLastName: string,
    ) => {
        const isEmailValid = emailRegEx.test(userEmail);
        if (
            userEmail.length === 0 &&
            userFirstName.length === 0 &&
            userLastName.length === 0
        ) {
            return styles.cell;
        } else if (userEmail.length > 0 && !isEmailValid) {
            return styles.cell__invalid;
        } else if (
            userEmail.length === 0 &&
            userFirstName.length > 0 &&
            userLastName.length > 0
        ) {
            return styles.cell__invalid;
        } else if (userEmail.length > 0 && isEmailValid) {
            return styles.cell__valid;
        } else if (
            userEmail.length > 0 &&
            !isEmailValid &&
            userFirstName.length > 0 &&
            userLastName.length > 0
        ) {
            return styles.cell__invalid;
        } else if (
            userEmail.length === 0 &&
            (userFirstName.length > 0 || userLastName.length > 0)
        ) {
            return styles.cell__invalid;
        }
    };

    const getNameInputStyle = (
        key: string,
        userEmail: string,
        userFirstName: string,
        userLastName: string,
    ) => {
        const nameToValidate =
            key === 'firstName' ? userFirstName : userLastName;
        const otherName = key === 'firstName' ? userLastName : userFirstName;

        if (
            userEmail.length === 0 &&
            nameToValidate.length === 0 &&
            otherName.length === 0
        ) {
            return styles.cell;
        } else if (
            nameToValidate.length === 0 &&
            otherName.length > 0 &&
            userEmail.length > 0
        ) {
            return styles.cell__invalid;
        } else if (nameToValidate.length > 0) {
            return styles.cell__valid;
        } else if (
            nameToValidate.length === 0 &&
            (userEmail.length > 0 || otherName.length > 0)
        ) {
            return styles.cell__invalid;
        }
    };

    const renderRows = () => {
        return Object.keys(users).map((userIn) => {
            const userIndex = parseInt(userIn);
            const userEmail = users[userIndex].email;
            const userFirstName = users[userIndex].first_name;
            const userLastName = users[userIndex].last_name;

            return (
                <div className={styles.row} key={userIn}>
                    <div
                        className={getEmailInputStyle(
                            userEmail,
                            userFirstName,
                            userLastName,
                        )}>
                        <TextInput
                            width="100%"
                            placeholder="Email"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleUpdateUser(
                                    userIndex,
                                    'email',
                                    e.target.value,
                                )
                            }
                            value={userEmail}
                        />
                    </div>
                    <div
                        className={getNameInputStyle(
                            'firstName',
                            userEmail,
                            userFirstName,
                            userLastName,
                        )}>
                        <TextInput
                            width="100%"
                            placeholder="First name"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleUpdateUser(
                                    userIndex,
                                    'first_name',
                                    e.target.value,
                                )
                            }
                            value={userFirstName}
                        />
                    </div>
                    <div
                        className={getNameInputStyle(
                            'lastName',
                            userEmail,
                            userFirstName,
                            userLastName,
                        )}>
                        <TextInput
                            width="100%"
                            placeholder="Last name"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleUpdateUser(
                                    userIndex,
                                    'last_name',
                                    e.target.value,
                                )
                            }
                            value={userLastName}
                        />
                    </div>
                </div>
            );
        });
    };

    const usersInArray = Object.keys(users)
        .map((userIndex) => {
            const user = users[parseInt(userIndex)];
            return {
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
            };
        })
        .filter((user) => {
            return !!(
                user &&
                user.email &&
                user.email !== '' &&
                emailRegEx.test(user.email) &&
                user.first_name &&
                user.first_name !== '' &&
                user.last_name &&
                user.last_name !== ''
            );
        });

    const rowsWithData = Object.keys(users)
        .map((userIndex) => {
            const user = users[parseInt(userIndex)];
            return {
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
            };
        })
        .filter((user) => {
            return !!(
                (user && user.email && user.email !== '') ||
                (user.first_name && user.first_name !== '') ||
                (user.last_name && user.last_name !== '')
            );
        });

    const isUserDataMissing =
        usersInArray.length === 0 ||
        usersInArray.length !== rowsWithData.length;

    const renderUserListForModal = () => {
        return (
            <div className={styles.dialog__content}>
                {usersInArray.length > 0 ? (
                    <div>
                        Do you really want to create the following candidate(s)?
                        <br />
                        When you confirm, the candidate(s) will{' '}
                        <b>automatically</b> receive an email to verify their
                        account and set a new password. Then they will receive
                        an invitation email to complete the scan.
                        <table>
                            <tbody>
                                {usersInArray.map((user) => {
                                    return (
                                        <tr key={`confirm+${user.email}`}>
                                            <td>
                                                <li
                                                    className={
                                                        styles.dialog__list
                                                    }>
                                                    {user.first_name}{' '}
                                                    {user.last_name}
                                                </li>
                                            </td>
                                            <td>{user.email}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    'Candidate names and/or email addresses are missing. '
                )}
            </div>
        );
    };

    const onSubmit = () => {
        props.onSubmit(usersInArray);
    };

    const onCancel = () => {
        props.onCancel();
    };

    const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.files) {
            const file = event.target.files[0];

            // FileReader(): a constructor that provides the onload() methods and the result
            const reader = new FileReader();
            reader.onload = (event) => {
                /* Parse data */
                // data as it is in a CSV
                if (event.target) {
                    const userDataCSVResult = event.target.result;

                    // spreadsheetAllData: data formatted as an object with two objects: SheetNames (array with spreadsheet names),
                    // and Sheets (object of objects with Excel cell names - like A1 - as keys)
                    const spreadsheetAllData = XLSX.read(userDataCSVResult, {
                        type: 'binary',
                    });

                    /* Get first worksheet */
                    const spreadsheetName = spreadsheetAllData.SheetNames[0];
                    const spreadsheetUserDataOnly =
                        spreadsheetAllData.Sheets[spreadsheetName];

                    /* Convert first worksheet back to csv format */
                    const data = XLSX.utils.sheet_to_csv(
                        spreadsheetUserDataOnly,
                    );
                    processData(data);
                }
            };
            reader.onloadstart = () => setIsCsvDataLoading(true);
            reader.onloadend = () => setIsCsvDataLoading(false);
            reader.readAsBinaryString(file);
        }
    };

    // for csv upload
    const processData = (dataString: string) => {
        const dataStringRows = dataString.split(/\r\n|\n/);
        const userDataCategories = dataStringRows[0].split(
            /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
        );
        userDataCategories.unshift('id'); // add id category

        const arrayOfUsers = [];
        for (let i = 1; i < dataStringRows.length; i++) {
            const userDataRow = dataStringRows[i].split(
                /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
            );
            userDataRow.unshift(i.toString()); // add id number

            if (
                userDataCategories &&
                userDataRow.length === userDataCategories.length
            ) {
                const objectOneUserEmailAndName = {};

                for (let i = 0; i < userDataCategories.length; i++) {
                    let cellData = userDataRow[i];
                    if (cellData.length > 0) {
                        if (cellData[0] === '"')
                            cellData = cellData.substring(
                                1,
                                cellData.length - 1,
                            ); // removes potential space from beginning of cell
                        if (cellData[cellData.length - 1] === '"')
                            cellData = cellData.substring(
                                cellData.length - 2,
                                1,
                            ); // removes potential space from end of cell
                    }
                    if (userDataCategories[i]) {
                        // @ts-ignore
                        objectOneUserEmailAndName[userDataCategories[i]] =
                            cellData;
                    }
                }

                // remove the blank rows
                const rowsWithUserData = Object.values(
                    objectOneUserEmailAndName,
                    // @ts-ignore
                ).filter((cell: string) => cell.length > 0);
                if (rowsWithUserData.length > 0) {
                    arrayOfUsers.push(objectOneUserEmailAndName);
                }
            }
        }
        // @ts-ignore
        setUsers(arrayOfUsers);
    };

    return (
        <div className={styles.container}>
            <div className={styles.csv_buttons}>
                <div className={styles.button}>
                    <label className="button" htmlFor="upload">
                        <AppButton icon={UploadIcon}>Upload CSV</AppButton>{' '}
                    </label>
                    <input
                        id="upload"
                        type="file"
                        accept=".csv,.xlsx,.xls"
                        onChange={handleFileUpload}
                    />
                </div>
                <div className={styles.button}>
                    <a href="https://s3-eu-west-1.amazonaws.com/back-office.neurolytics.ai/Neurolytics_Add_Candidates_template.xlsx">
                        <AppButton icon={DownloadIcon}>
                            Download template
                        </AppButton>
                    </a>
                </div>
            </div>

            <div className={styles.table}>
                {isCsvDataLoading ? (
                    <ProgressSpinner />
                ) : (
                    <>
                        <div className={styles.header_row}>
                            {renderTableHeader()}
                        </div>
                        {renderRows()}
                        <div
                            className={styles.add_row}
                            onClick={() => {
                                addThreeRowsToBottomOfTable();
                            }}>
                            <InsertIcon size={24} />
                        </div>
                    </>
                )}
            </div>
            <div className={styles.footer}>
                <div className={styles.button_left}>
                    <AppButton
                        icon={DeleteIcon}
                        color={'gray'}
                        onClick={() => {
                            onCancel();
                        }}>
                        Cancel
                    </AppButton>
                </div>
                <div className={styles.button_right}>
                    <AppButton
                        icon={AddIcon}
                        onClick={() => props.showConfirmModal(true)}
                        isDisabled={isUserDataMissing}
                        tooltipText={
                            isUserDataMissing
                                ? 'Some data is missing or incomplete. Make sure each new candidate has a valid email address and name.'
                                : ''
                        }>
                        Create candidates
                    </AppButton>
                </div>
            </div>
            {props.shouldShowConfirmModal && (
                <DialogComponent
                    title="Confirm to create candidates"
                    confirmButtonText="Create candidates"
                    isShown={props.shouldShowConfirmModal}
                    onClose={() => props.showConfirmModal(false)}
                    onConfirm={() => onSubmit()}
                    confirmIcon={AddIcon}
                    buttonColor={
                        isUserDataMissing ? 'grayDisabled' : 'tealBlue'
                    }
                    isConfirmButtonDisabled={isUserDataMissing}>
                    {renderUserListForModal()}
                </DialogComponent>
            )}
        </div>
    );
};

export default AddParticipantsTable;
