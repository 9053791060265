import { takeEvery, all } from 'redux-saga/effects';
import { authActionTypes } from './auth/auth.actions';
import * as authEffects from './auth/auth.effects';
import { actionsTypes as assessmentsActions } from './assessments/assessments.actions';
import * as assessmentsEffects from './assessments/assessments.effects';
import { actionsTypes as targetGroupsActions } from './targetGroups/targetGroups.actions';
import * as targetGroupsEffects from './targetGroups/targetGroups.effects';
import { actionsTypes as usersActions } from './users/users.actions';
import * as usersEffects from './users/users.effects';
import { actionsTypes as dashboardUsersActions } from './dashboardUsers/dashboardUsers.actions';
import * as dashboardUserEffects from './dashboardUsers/dashboardUsers.effects';
import { actionsTypes as companiesActions } from './companies/companies.actions';
import * as companiesEffects from './companies/companies.effects';
import { actionsTypes as mediaFilesActions } from './mediaFiles/mediaFiles.actions';
import * as mediaFilesEffects from './mediaFiles/mediaFiles.effects';

function* rootSaga() {
    //auth effects
    yield all([takeEvery(authActionTypes.INIT_AUTH0, authEffects.initAuth0)]);
    yield all([
        takeEvery(authActionTypes.INIT_AUTH0_SUCCESS, authEffects.storeUser),
    ]);
    yield all([takeEvery(authActionTypes.INIT_LOGIN, authEffects.initLogin)]);
    yield all([takeEvery(authActionTypes.STORE_USER, authEffects.storeUser)]);

    // scan effects
    yield all([
        takeEvery(
            assessmentsActions.GET_COMPANY_ASSESSMENTS,
            assessmentsEffects.getCompanyAssessments,
        ),
    ]);
    yield all([
        takeEvery(
            assessmentsActions.GET_ASSESSMENT,
            assessmentsEffects.getAssessment,
        ),
    ]);
    yield all([
        takeEvery(
            assessmentsActions.CREATE_ASSESSMENT,
            assessmentsEffects.createAssessment,
        ),
    ]);
    yield all([
        takeEvery(
            assessmentsActions.UPDATE_ASSESSMENT_CONFIG,
            assessmentsEffects.updateAssessmentConfig,
        ),
    ]);
    yield all([
        takeEvery(
            assessmentsActions.CREATE_SCAN,
            assessmentsEffects.createScan,
        ),
    ]);
    yield all([
        takeEvery(
            assessmentsActions.DELETE_SCAN,
            assessmentsEffects.deleteEmptyScan,
        ),
    ]);

    // target group effects
    yield all([
        takeEvery(
            targetGroupsActions.GET_COMPANY_GROUPS,
            targetGroupsEffects.getCompanyGroups,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.GET_ASSESSMENT_TARGET_GROUPS,
            targetGroupsEffects.getAssessmentTargetGroups,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.GET_TARGET_GROUP_BY_ID,
            targetGroupsEffects.getTargetGroupById,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.GET_WORK_PREFERENCE_BASELINE,
            targetGroupsEffects.getWorkPreferenceBaselineEffect,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.GET_TARGET_GROUP_FEEDBACK,
            targetGroupsEffects.getTargetGroupFeedbackEffect,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.RESET_CANDIDATE_SCAN,
            targetGroupsEffects.resetCandidateScanEffect,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.DELETE_TARGET_GROUP,
            targetGroupsEffects.deleteEmptyTargetGroup,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.ADD_PARTICIPANTS,
            targetGroupsEffects.addParticipant,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.REMOVE_PARTICIPANTS,
            targetGroupsEffects.removeParticipant,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.FULL_DELETE_PARTICIPANTS,
            targetGroupsEffects.fullDeleteParticipant,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.UPDATE_TARGET_GROUP_CONFIG,
            targetGroupsEffects.updateTargetGroupConfig,
        ),
    ]);
    yield all([
        takeEvery(
            targetGroupsActions.CREATE_TARGET_GROUP,
            targetGroupsEffects.createTargetGroup,
        ),
    ]);

    // user effects
    yield all([
        takeEvery(
            usersActions.GET_TARGET_GROUP_USERS,
            usersEffects.getTargetGroupUsers,
        ),
    ]);
    yield all([
        takeEvery(
            usersActions.GET_ALL_USERS_INVITATIONS_IN_GROUP,
            usersEffects.getAllUsersInvitationsInGroup,
        ),
    ]);
    yield all([
        takeEvery(
            usersActions.GET_TARGET_GROUP_USERS_SUCCESS,
            usersEffects.getTargetGroupUsersSuccess,
        ),
    ]);
    yield all([
        takeEvery(usersActions.GET_USER_ACTIVITY, usersEffects.getUserActivity),
    ]);
    yield all([
        takeEvery(
            usersActions.GET_USER_EMAIL_INFO,
            usersEffects.getUserEmailInfo,
        ),
    ]);
    yield all([
        takeEvery(
            usersActions.CONVERT_USER_VIDEOS,
            usersEffects.convertUserVideos,
        ),
    ]);
    yield all([takeEvery(usersActions.SEND_EMAIL, usersEffects.sendEmail)]);
    yield all([
        takeEvery(usersActions.GET_CANDIDATE, usersEffects.getCandidate),
    ]);

    // dashboard user effects
    yield all([
        takeEvery(
            dashboardUsersActions.CREATE_DASHBOARD_USER,
            dashboardUserEffects.createDashboardUser,
        ),
    ]);
    yield all([
        takeEvery(
            dashboardUsersActions.GET_ALL_COMPANY_DASHBOARD_USERS,
            dashboardUserEffects.getAllDashboardUsers,
        ),
    ]);
    yield all([
        takeEvery(
            dashboardUsersActions.REMOVE_RECRUITERS,
            dashboardUserEffects.deleteRecruiterUsersEffect,
        ),
    ]);

    // companies effects
    yield all([
        takeEvery(
            companiesActions.GET_COMPANIES,
            companiesEffects.getCompanies,
        ),
    ]);
    yield all([
        takeEvery(
            companiesActions.GET_COMPANY_BY_ID,
            companiesEffects.getCompanyById,
        ),
    ]);
    yield all([
        takeEvery(
            companiesActions.CREATE_COMPANY,
            companiesEffects.createCompany,
        ),
    ]);

    // media files
    yield all([
        takeEvery(
            mediaFilesActions.GET_MEDIA_FILES,
            mediaFilesEffects.getCompanyMediaFiles,
        ),
    ]);
}

export default rootSaga;
