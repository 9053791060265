import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'evergreen-ui';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ProgressSpinner from 'components/ProgressSpinner/ProgressSpinner';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import styles from './UsersTable.module.css';
import { DashboardUser } from '../../../typings/dashboard_user';
import Checkbox from '@material-ui/core/Checkbox';
import UsersTableFooter from './UsersTableFooter/UsersTableFooter';

interface UsersTableProps {
    companyUsers: DashboardUser[];
    selectedUsers: DashboardUser[];
    loading: boolean;
    isSuperAdmin: boolean;
    hasCompanyAdminRole: boolean;
    onDeleteRecruiterUser: () => void;
    onSelect: (
        user: DashboardUser,
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void;
    onSelectAll: () => void;
}

const theme = createTheme({
    overrides: {
        MuiTableHead: {
            root: {
                border: 'solid 1px #bbc9d9',
            },
        },
        MuiTableRow: {
            root: {
                '&$selected, &$selected:hover': {
                    backgroundColor: '#F6F9FD',
                },
            },
            head: { border: 'solid 1px #bbc9d9' },
        },
        MuiTableCell: {
            root: {
                '&$head': {
                    fontWeight: '700',
                    color: '#445870',
                    backgroundColor: '#f5f6fa',
                    borderRadius: 0,
                },
                lineHeight: '0.7',
                verticalAlign: 'middle',
            },
            sizeSmall: { padding: '5px' },
        },
        MuiTableContainer: {
            root: {
                maxHeight: 440,
            },
        },
    },
});

const UsersTable: React.FC<UsersTableProps> = (props) => {
    const {
        loading,
        companyUsers,
        selectedUsers,
        isSuperAdmin,
        hasCompanyAdminRole,
        onDeleteRecruiterUser,
        onSelect,
        onSelectAll,
    } = props;
    const [usersShowingInfo, updateUsersShowingInfo] = useState<string[]>([]);
    // const error = useSelector(selectDashboardUserError);

    const onUserShowInfoChange = (userId: string) => {
        const alreadyShowingInfo = usersShowingInfo.some((id) => id === userId);
        if (alreadyShowingInfo) {
            const update = [...usersShowingInfo].filter((id) => id !== userId);
            updateUsersShowingInfo(update);
        } else {
            const update = [...usersShowingInfo];
            update.push(userId);
            updateUsersShowingInfo(update);
        }
    };

    const renderOneUsersRoles = (roles: any[]) => {
        const roleNamesWithSpaces = roles.map((role) =>
            role.name.match(/[A-Z][a-z]+/g).join(' '),
        );

        return roleNamesWithSpaces.map((role, index) => {
            if (role.startsWith('Prod')) {
                const roleWithoutEnvironment = role.replace('Prod', '');
                return (
                    <li key={role + index} className={styles.role}>
                        {roleWithoutEnvironment}
                    </li>
                );
            } else {
                return (
                    <li key={role + index} className={styles.role}>
                        {role}
                    </li>
                );
            }
        });
    };

    const renderUserRow = (dashboardUser: DashboardUser, index: number) => {
        const showInfo = usersShowingInfo.some((id) => id === dashboardUser.id);
        return (
            <React.Fragment key={`row-user-${index}`}>
                <TableRow>
                    <TableCell>
                        {showInfo && (
                            <ChevronUpIcon
                                size={25}
                                onClick={() => {
                                    onUserShowInfoChange(dashboardUser.id);
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        )}
                        {!showInfo && (
                            <ChevronDownIcon
                                size={25}
                                onClick={() => {
                                    onUserShowInfoChange(dashboardUser.id);
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        )}
                    </TableCell>
                    <TableCell>
                        <Checkbox
                            disabled={loading}
                            checked={selectedUsers.some(
                                (selected) => selected.id === dashboardUser.id,
                            )}
                            onChange={(event) => onSelect(dashboardUser, event)}
                            color="primary"
                            classes={{ checked: styles.checkedBox }}
                        />
                    </TableCell>
                    <TableCell>
                        {dashboardUser.name ||
                            dashboardUser.first_name +
                                ' ' +
                                dashboardUser.last_name}
                    </TableCell>
                    <TableCell>{dashboardUser.email}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderBottom: !showInfo ? 0 : undefined,
                            background: '#e7f8f8',
                        }}
                        colSpan={1}
                    />
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderBottom: !showInfo ? 0 : undefined,
                            background: '#e7f8f8',
                        }}
                        colSpan={9}>
                        <Collapse in={showInfo} timeout="auto">
                            <Box
                                marginTop={3}
                                marginBottom={3}
                                marginLeft={1}
                                marginRight={2}
                                className={styles.info_row}>
                                <div>
                                    <p>
                                        Role
                                        {dashboardUser.roles &&
                                            dashboardUser.roles.length > 1 &&
                                            's'}
                                    </p>
                                    <ul>
                                        {dashboardUser.roles &&
                                        dashboardUser.roles &&
                                        dashboardUser.roles.length > 0 ? (
                                            renderOneUsersRoles(
                                                dashboardUser.roles,
                                            )
                                        ) : (
                                            <li className={styles.role}>
                                                This user doesn't have any
                                                roles.
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    return (
        <div className={styles.users_table}>
            <div className={styles.users_table__content}>
                <ThemeProvider theme={theme}>
                    <TableContainer>
                        <Table stickyHeader size="small">
                            {!!companyUsers && !!companyUsers.length && (
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={1} />
                                        <TableCell>
                                            <Checkbox
                                                disabled={loading}
                                                indeterminate={
                                                    selectedUsers.length > 0 &&
                                                    selectedUsers.length <
                                                        companyUsers.length
                                                }
                                                checked={
                                                    companyUsers.length > 0 &&
                                                    companyUsers.length ===
                                                        selectedUsers.length
                                                }
                                                onChange={onSelectAll}
                                                classes={{
                                                    checked: styles.checkedBox,
                                                    indeterminate:
                                                        styles.checkedBox,
                                                }}
                                                color="primary"
                                            />
                                        </TableCell>

                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="left">
                                            Email
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            )}
                            <TableBody>
                                {loading && (
                                    <TableRow>
                                        <TableCell align="center" colSpan={9}>
                                            <ProgressSpinner />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {!loading &&
                                    !!companyUsers &&
                                    companyUsers.length === 0 && (
                                        <TableRow>
                                            <TableCell align="left" colSpan={9}>
                                                <div
                                                    className={
                                                        styles.no_users_yet
                                                    }>
                                                    <em>
                                                        There are no users yet.
                                                    </em>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                {!loading &&
                                    !!companyUsers &&
                                    companyUsers.length > 0 &&
                                    companyUsers.map((dashboardUser, index) =>
                                        renderUserRow(dashboardUser, index),
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>

                {!!companyUsers && companyUsers.length >= 10 && (
                    <div className={styles.line_break} />
                )}
            </div>
            {!loading && !!companyUsers && !!companyUsers.length && (
                <UsersTableFooter
                    isSuperAdmin={isSuperAdmin}
                    hasCompanyAdminRole={hasCompanyAdminRole}
                    onDeleteRecruiterUsersEvent={onDeleteRecruiterUser}
                    selectedUsers={selectedUsers}
                />
            )}
        </div>
    );
};

export default UsersTable;
