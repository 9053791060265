import {
    ContentHeaderDescription,
    ContentHeaderTitle,
    ContentHeaderTitleWrapper,
    ContentHeaderPicture,
    ContentHeaderWrapper,
    Picture,
} from './styles';
import { ResultsSectionProps, SectionId } from '../../../../typings';

const ResultsHeader = (props: ResultsSectionProps) => {
    const { sections, activeSection } = props;

    const headersToRender = [];
    for (const id in sections) {
        const sectionId = id as SectionId;
        headersToRender.push(
            <ContentHeaderWrapper
                key={sectionId}
                isActive={sectionId === activeSection}
                id="header_wrapper">
                <ContentHeaderTitleWrapper>
                    <ContentHeaderTitle>
                        {sections[sectionId]?.title}
                    </ContentHeaderTitle>
                    {sections[sectionId]?.description && (
                        <ContentHeaderDescription>
                            {sections[sectionId]?.description}
                        </ContentHeaderDescription>
                    )}
                </ContentHeaderTitleWrapper>
                {sections[sectionId]?.image && (
                    <ContentHeaderPicture>
                        <Picture src={sections[sectionId]?.image} />
                    </ContentHeaderPicture>
                )}
            </ContentHeaderWrapper>,
        );
    }

    return <>{headersToRender}</>;
};

export default ResultsHeader;
