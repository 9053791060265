import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/time';
import CardsView, { LabelObject } from '../../components/CardsView/CardsView';
import { Assessment } from '../../typings/assessment';
import {
    Card,
    CardList,
    CheckboxWrapper,
    HiddenCheckBox,
    Icon,
    StyledCheckbox,
} from './style';

interface ScansCardsListProps {
    isScanSetupFlow?: boolean;
    isTargetGroupDeepDive?: boolean;
    companySlug: string;
    scansAndGroups: {
        scan: Assessment;
        groupsCount: number;
    }[];
    handleSelectedScan?: (scanId: string) => void;
    handleScanDetails?: (scan: Assessment) => void;
    checked?: string;
}

const ScansCardsList: FunctionComponent<ScansCardsListProps> = (props) => {
    const {
        isScanSetupFlow,
        companySlug,
        scansAndGroups,
        handleSelectedScan,
        checked,
        handleScanDetails,
        isTargetGroupDeepDive,
    } = props;

    const handleScan = (scanId: string) => {
        if (handleSelectedScan) {
            handleSelectedScan(scanId);
        }
    };

    return (
        <CardList>
            {scansAndGroups.length === 0 && (
                <div>
                    <em>There are no scans yet.</em>
                </div>
            )}
            {!!scansAndGroups.length &&
                scansAndGroups.map((scanAndGroups) => {
                    const { scan, groupsCount } = scanAndGroups;
                    const hasCultureFit = scan.modules?.find((module) => {
                        return (
                            module.id === 'culture-fit' ||
                            module.id === 'culture-fit-questionnaire'
                        );
                    });
                    const hasSpeaking = scan.modules?.find((module) => {
                        return module.id === 'interview';
                    });
                    const hasCognitiveAbilities = scan.modules?.find(
                        (module) => {
                            return module.id === 'mental-test';
                        },
                    );
                    const subtitle =
                        groupsCount === 1
                            ? `Used by 1 group`
                            : groupsCount === 0
                            ? `Used by 0 group(s)`
                            : `Used by ${groupsCount} groups`;
                    const cardLabels: LabelObject[] = [];
                    const createdDate = `${formatDate(scan.created_at)}`;
                    cardLabels.push({
                        color: 'secondary',
                        text: scan.language === 'en-US' ? 'EN' : 'NL',
                    });
                    if (
                        (isScanSetupFlow || isTargetGroupDeepDive) &&
                        handleScanDetails
                    ) {
                        return (
                            <Card key={scan.id}>
                                {isScanSetupFlow && (
                                    <label>
                                        <CheckboxWrapper>
                                            <HiddenCheckBox
                                                checked={checked === scan.id}
                                                onChange={() => {
                                                    handleScan(scan.id);
                                                }}
                                            />
                                            <StyledCheckbox
                                                checked={checked === scan.id}>
                                                <Icon
                                                    checked={
                                                        checked === scan.id
                                                    }
                                                    viewBox="0 0 24 24">
                                                    <circle
                                                        className="radioDot"
                                                        cx="12"
                                                        cy="12"
                                                        r="7"
                                                    />
                                                </Icon>
                                            </StyledCheckbox>
                                        </CheckboxWrapper>
                                    </label>
                                )}
                                <div onClick={() => handleScanDetails(scan)}>
                                    <CardsView
                                        labels={cardLabels}
                                        minHeight={'205px'}
                                        createdDate={createdDate}>
                                        <h1>{scan.name}</h1>
                                        <h3>{subtitle}</h3>
                                        {!!hasCultureFit && (
                                            <h4>Cultural fit</h4>
                                        )}
                                        {!!hasSpeaking && <h4>Speaking</h4>}
                                        {!!hasCognitiveAbilities && (
                                            <h4>Cognitive Abilities</h4>
                                        )}
                                    </CardsView>
                                </div>
                            </Card>
                        );
                    } else
                        return (
                            <Link
                                key={scan.id}
                                to={`/${companySlug}/scans/${scan.id}`}>
                                <CardsView
                                    key={scan.id}
                                    labels={cardLabels}
                                    minHeight={'205px'}
                                    createdDate={createdDate}>
                                    <h1>{scan.name}</h1>
                                    <h3>{subtitle}</h3>
                                    {hasCultureFit && <h4>Cultural fit</h4>}
                                    {hasSpeaking && <h4>Speaking</h4>}
                                    {hasCognitiveAbilities && (
                                        <h4>Cognitive Abilities</h4>
                                    )}
                                </CardsView>
                            </Link>
                        );
                })}
        </CardList>
    );
};

export default ScansCardsList;
