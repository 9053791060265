import React, { FunctionComponent, ReactNode } from 'react';
import styles from './CardsView.module.css';
import Badge, { BadgeColors } from 'components/Badge/Badge';

export interface LabelObject {
    color: BadgeColors;
    text: string;
}

interface CardsViewProps {
    children: ReactNode;
    labels: Array<LabelObject>;
    createdDate?: string;
    minHeight?: string;
}

const CardsView: FunctionComponent<CardsViewProps> = (props) => {
    const { children, labels, createdDate, minHeight } = props;
    return (
        <div className={styles.card} style={{ minHeight: minHeight }}>
            <div className={styles.card__header}>{children}</div>
            <div className={styles.card__badges}>
                <div>
                    {labels.map((label, i) => (
                        <span
                            key={`${i}-${label.color}-${label.text}`}
                            className={styles.card__badge}>
                            <Badge color={label.color}>{label.text}</Badge>
                        </span>
                    ))}
                </div>
                {createdDate && (
                    <div className={styles.card__created}>
                        <h6>Created on:</h6>
                        <span>{createdDate}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardsView;
