import React, { useEffect, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import LoginInit from 'components/Auth/LoginInit/LoginInit';
import ProgressSpinner from 'components/ProgressSpinner/ProgressSpinner';
import { selectAuthState } from 'store/auth/auth.selectors';
import { auth0Client } from 'Auth';
import useAuth from 'hooks/useAuth';

interface AuthenticatorProps {
    children: React.ReactElement;
}

const Authenticator: FunctionComponent<AuthenticatorProps> = (props) => {
    const { children } = props;
    const navigate = useNavigate();
    const { user, isLoading } = useSelector(selectAuthState);
    const { onRedirectCallback } = useAuth();

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const auth0code = query.get('code');
    const auth0state = query.get('state');

    useEffect(() => {
        // If we are redirected back from Auth0 universal login:
        // - Store the user
        // - Redirect to the dashboard
        if (auth0Client && auth0code && auth0state) {
            onRedirectCallback().then((appState) => {
                if (appState) {
                    navigate(appState.return_url ? appState.return_url : '/');
                }
            });
        }
    }, [auth0code, auth0state, auth0Client]); //eslint-disable-line react-hooks/exhaustive-deps

    const onLogin = () => {
        navigate(`/login?return_url=${location.pathname}`);
    };

    const onForgotPassword = () => {
        navigate(`/login?mode=forgotPassword`);
    };

    return (
        <>
            {isLoading && <ProgressSpinner />}
            {!isLoading && user && children}
            {!isLoading && !user && (
                <LoginInit
                    onSubmit={onLogin}
                    onForgotPassword={onForgotPassword}
                />
            )}
        </>
    );
};

export default Authenticator;
