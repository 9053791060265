import React, { useState } from 'react';
import ReactCookieConsent from 'react-cookie-consent';
import { useTranslation } from '../../i18n';

import DialogComponent from 'components/Dialog/Dialog';
import * as variables from 'assets/styles/variables';

const CookieConsent = () => {
    const { t } = useTranslation();

    const neurolyticsCookies = [
        {
            name: 'cookieConsentResult',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION.cookieConsentResult',
            ),
            duration: t('COOKIE_CONSENT.DURATION.365_DAYS'),
            value: 'Boolean true/false',
        },
    ];

    const hotjarCookies = [
        {
            name: '_hjSessionUser{site_id}',
            description: t('COOKIE_CONSENT.COOKIE_DESCRIPTION._hjSessionUser'),
            duration: t('COOKIE_CONSENT.DURATION.365_DAYS'),
            value: 'JSON',
        },
        {
            name: '_hjSession{site_id}',
            description: t('COOKIE_CONSENT.COOKIE_DESCRIPTION._hjSession'),
            duration: t('COOKIE_CONSENT.DURATION.30_MINUTES'),
            value: 'JSON',
        },
        {
            name: '_hjClosedSurveyInvites',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjClosedSurveyInvites',
            ),
            duration: t('COOKIE_CONSENT.DURATION.365_DAYS'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjDonePolls',
            description: t('COOKIE_CONSENT.COOKIE_DESCRIPTION._hjDonePolls'),
            duration: t('COOKIE_CONSENT.DURATION.365_DAYS'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjMinimizedPolls',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjMinimizedPolls',
            ),
            duration: t('COOKIE_CONSENT.DURATION.365_DAYS'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjShownFeedbackMessage',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjShownFeedbackMessage',
            ),
            duration: t('COOKIE_CONSENT.DURATION.365_DAYS'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjSessionTooLarge',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjSessionTooLarge',
            ),
            duration: t('COOKIE_CONSENT.DURATION.SESSION'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjSessionRejected',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjSessionRejected',
            ),
            duration: t('COOKIE_CONSENT.DURATION.SESSION'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjSessionResumed',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjSessionResumed',
            ),
            duration: t('COOKIE_CONSENT.DURATION.SESSION'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjid',
            description: t('COOKIE_CONSENT.COOKIE_DESCRIPTION._hjid'),
            duration: t('COOKIE_CONSENT.DURATION.365_DAYS'),
            value: 'UUID',
        },
        {
            name: '_hjRecordingLastActivity',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjRecordingLastActivity',
            ),
            duration: t('COOKIE_CONSENT.DURATION.SESSION'),
            value: 'Numerical Value (Timestamp)',
        },
        {
            name: '_hjTLDTest',
            description: t('COOKIE_CONSENT.COOKIE_DESCRIPTION._hjTLDTest'),
            duration: t('COOKIE_CONSENT.DURATION.SESSION'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjUserAttributesHash',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjUserAttributesHash',
            ),
            duration: t('COOKIE_CONSENT.DURATION.SESSION'),
            value: 'Hash',
        },
        {
            name: '_hjCachedUserAttributes',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjCachedUserAttributes',
            ),
            duration: t('COOKIE_CONSENT.DURATION.SESSION'),
            value: 'JSON',
        },
        {
            name: '_hjLocalStorageTest',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjLocalStorageTest',
            ),
            duration: t('COOKIE_CONSENT.DURATION.UNDER_100MS'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjIncludedInPageviewSample',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjIncludedInPageviewSample',
            ),
            duration: t('COOKIE_CONSENT.DURATION.30_MINUTES'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjIncludedInSessionSample',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjIncludedInSessionSample',
            ),
            duration: t('COOKIE_CONSENT.DURATION.30_MINUTES'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjAbsoluteSessionInProgress',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjAbsoluteSessionInProgress',
            ),
            duration: t('COOKIE_CONSENT.DURATION.30_MINUTES'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjFirstSeen',
            description: t('COOKIE_CONSENT.COOKIE_DESCRIPTION._hjFirstSeen'),
            duration: t('COOKIE_CONSENT.DURATION.SESSION'),
            value: 'Boolean true/false',
        },
        {
            name: '_hjViewportId',
            description: t('COOKIE_CONSENT.COOKIE_DESCRIPTION._hjViewportId'),
            duration: t('COOKIE_CONSENT.DURATION.SESSION'),
            value: 'UUID',
        },
        {
            name: '_hjRecordingEnabled',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION._hjRecordingEnabled',
            ),
            duration: t('COOKIE_CONSENT.DURATION.SESSION'),
            value: 'Boolean true/false',
        },
    ];

    const auth0Cookies = [
        {
            name: 'auth0.is.authenticated, _legacy_auth0.is.authenticated',
            description: t(
                'COOKIE_CONSENT.COOKIE_DESCRIPTION.auth0IsAuthenticated',
            ),
            duration: t('COOKIE_CONSENT.DURATION.1_DAY'),
            value: 'Boolean true/false',
        },
    ];

    const [displayCookieList, setDisplayCookieList] = useState<boolean>(false);
    const cookieList = [
        ...neurolyticsCookies,
        ...hotjarCookies,
        ...auth0Cookies,
    ];

    const renderCookieDialog = () => {
        return (
            <DialogComponent
                title="Cookies"
                isShown={displayCookieList}
                onClose={() => setDisplayCookieList(false)}>
                <table style={{ textAlign: 'left', marginTop: '15px' }}>
                    <tbody>
                        <tr>
                            <th
                                style={{
                                    borderBottom: '1px solid white',
                                    fontSize: '0.9em',
                                    padding: '10px',
                                }}>
                                {t('COOKIE_CONSENT.COOKIE_NAME_TITLE')}
                            </th>
                            <th
                                style={{
                                    borderBottom: '1px solid white',
                                    fontSize: '0.9em',
                                    padding: '10px',
                                }}>
                                {t('COOKIE_CONSENT.DURATION_TITLE')}
                            </th>
                            <th
                                style={{
                                    borderBottom: '1px solid white',
                                    fontSize: '0.9em',
                                    padding: '10px',
                                }}>
                                {t('COOKIE_CONSENT.DESCRIPTION_TITLE')}
                            </th>
                        </tr>
                        {cookieList.map(
                            (
                                cookie: {
                                    name: string;
                                    description: string;
                                    duration: string;
                                    value: string;
                                },
                                index,
                            ) => {
                                const isEvenRow: boolean = index % 2 === 0;
                                return (
                                    <tr
                                        key={cookie.name}
                                        style={{
                                            backgroundColor: isEvenRow
                                                ? variables.lightGrayBackgroundColor
                                                : variables.backgroundColor,
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}>
                                        <td
                                            style={{
                                                fontSize: '0.8em',
                                                padding: '10px',
                                                fontFamily: 'monospace',
                                            }}>
                                            {cookie.name}
                                        </td>
                                        <td
                                            style={{
                                                fontSize: '0.8em',
                                                padding: '10px',
                                            }}>
                                            {cookie.duration}
                                        </td>
                                        <td
                                            dangerouslySetInnerHTML={{
                                                __html: cookie.description,
                                            }}
                                            style={{
                                                fontSize: '0.8em',
                                                padding: '10px',
                                            }}
                                        />
                                    </tr>
                                );
                            },
                        )}
                    </tbody>
                </table>
            </DialogComponent>
        );
    };

    return (
        <ReactCookieConsent
            location="bottom"
            buttonText={t('COOKIE_CONSENT.BUTTON_TEXT')}
            onAccept={() => setDisplayCookieList(false)}
            cookieName="cookieConsentResult"
            style={{ background: '#2B373B' }}
            buttonStyle={{
                color: '#FFFFFF',
                fontSize: '13px',
                background: 'rgb(0 135 143)',
            }}
            expires={365}>
            {t('COOKIE_CONSENT.TITLE')}
            <span
                style={{
                    fontSize: '10px',
                    cursor: 'pointer',
                    color: variables.tealblue,
                    paddingLeft: '10px',
                }}
                onClick={() => {
                    setDisplayCookieList(!displayCookieList);
                }}>
                {displayCookieList
                    ? t('COOKIE_CONSENT.HIDE_COOKIE_LIST')
                    : t('COOKIE_CONSENT.SHOW_COOKIE_LIST')}
            </span>
            {displayCookieList && renderCookieDialog()}
        </ReactCookieConsent>
    );
};

export default CookieConsent;
