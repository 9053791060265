import AppButton from 'components/AppButton/AppButton';
import {
    DocumentOpenIcon,
    ResetIcon,
    TrashIcon,
    EnvelopeIcon,
} from 'evergreen-ui';
import React, { FunctionComponent } from 'react';
import styles from './TargetGroupTableFooter.module.css';
import { Participant } from 'typings/user';
import { getPaginationRange } from '../../utils';

interface TargetGroupTableFooterProps {
    updatingParticipants: boolean;
    isUsersDetailsLoading: boolean;
    resetScanLoading: boolean;
    candidateTotalPages: number[];
    candidateCurrentPage: number;
    isSuperAdmin: boolean;
    participantsSelected: Participant[];
    // onSendInvitationEvent: () => void;
    onSendResetPasswordEvent: () => void;
    onSendReminderEvent: () => void;
    onSendReportEvent: () => void;
    onRemoveCandidateEvent: () => void;
    onFullDeleteCandidateEvent: () => void;
    onResetCandidateScanEvent: () => void;
    handleChangePage: (arg0: number) => void;
}

const TargetGroupTableFooter: FunctionComponent<TargetGroupTableFooterProps> = (
    props: TargetGroupTableFooterProps,
) => {
    const {
        isUsersDetailsLoading,
        resetScanLoading,
        updatingParticipants,
        candidateTotalPages,
        candidateCurrentPage,
        isSuperAdmin,
        participantsSelected,
        onSendResetPasswordEvent,
        onSendReminderEvent,
        onSendReportEvent,
        onRemoveCandidateEvent,
        onFullDeleteCandidateEvent,
        onResetCandidateScanEvent,
        handleChangePage,
    } = props;
    const selectedParticipantsThatDoNotHaveReport = (): Participant[] => {
        return participantsSelected.filter(
            (participant) =>
                !participant.activity || !participant.activity.report,
        );
    };

    const selectedParticipantsThatAreNotDeletable = (): Participant[] => {
        return participantsSelected.filter(
            (participant) =>
                participant.activity &&
                participant.activity.sessions &&
                participant.activity.sessions.length &&
                participant.activity.sessions[0].finishedAt,
        );
    };

    const isSendReminderOrResetDisabled = participantsSelected.length === 0;
    const isSendReportDisabled =
        participantsSelected.length === 0 ||
        selectedParticipantsThatDoNotHaveReport().length ===
            participantsSelected.length;
    const isDeleteDisabled =
        participantsSelected.length === 0 ||
        selectedParticipantsThatAreNotDeletable().length ===
            participantsSelected.length;
    const usersCannotBeDeleted =
        participantsSelected.length > 0 &&
        selectedParticipantsThatAreNotDeletable().length ===
            participantsSelected.length;

    const paginationRange = getPaginationRange(
        candidateCurrentPage + 1,
        candidateTotalPages.length,
        1,
    );

    return (
        <div className={styles.footer}>
            <div className={styles.pages_wrapper}>
                {candidateTotalPages.length > 1 &&
                    paginationRange.map((page, index) => {
                        if (typeof page === 'string') {
                            return (
                                <span
                                    key={index}
                                    className={styles.pages_ellipsis}>
                                    {page}
                                </span>
                            );
                        } else {
                            return (
                                <AppButton
                                    color={
                                        page === candidateCurrentPage + 1
                                            ? 'tealBlue'
                                            : 'gray'
                                    }
                                    isDisabled={
                                        updatingParticipants ||
                                        isUsersDetailsLoading ||
                                        resetScanLoading
                                    }
                                    key={index}
                                    onClick={() => handleChangePage(page - 1)}>
                                    {' '}
                                    {page}
                                </AppButton>
                            );
                        }
                    })}
            </div>
            <div className={styles.footer_with_dialog_buttons}>
                {/*<div className={styles.button}>*/}
                {/*  {participantsSelected.length === 0 ? (*/}
                {/*    <AppButton icon={EnvelopeIcon} color="grayDisabled" cursor="disabled">*/}
                {/*      Send invitation*/}
                {/*    </AppButton>*/}
                {/*  ) : (*/}
                {/*    <AppButton icon={EnvelopeIcon} onClick={() => onSendInvitationEvent()} color="gray">*/}
                {/*      Send invitation*/}
                {/*    </AppButton>*/}
                {/*  )}*/}
                {/*</div>*/}
                <div className={styles.button_wrapper}>
                    <AppButton
                        icon={EnvelopeIcon}
                        color={
                            isSendReminderOrResetDisabled
                                ? 'grayDisabled'
                                : 'gray'
                        }
                        onClick={onSendResetPasswordEvent}
                        isDisabled={isSendReminderOrResetDisabled}>
                        Reset password
                    </AppButton>
                    <AppButton
                        icon={EnvelopeIcon}
                        color={
                            isSendReminderOrResetDisabled
                                ? 'grayDisabled'
                                : 'gray'
                        }
                        onClick={onSendReminderEvent}
                        isDisabled={isSendReminderOrResetDisabled}>
                        Send reminder
                    </AppButton>
                    <AppButton
                        icon={DocumentOpenIcon}
                        color={isSendReportDisabled ? 'grayDisabled' : 'gray'}
                        onClick={onSendReportEvent}
                        isDisabled={isSendReportDisabled}>
                        Send report
                    </AppButton>
                </div>
                <div className={styles.button_wrapper}>
                    <AppButton
                        icon={TrashIcon}
                        color={isDeleteDisabled ? 'grayDisabled' : 'red'}
                        onClick={onRemoveCandidateEvent}
                        isDisabled={isDeleteDisabled}
                        tooltipText={
                            usersCannotBeDeleted
                                ? 'The candidate(s) selected cannot be deleted because they have already completed the scan. Please contact support to delete candidate scan data.'
                                : undefined
                        }>
                        Remove
                    </AppButton>
                    {isSuperAdmin && (
                        <AppButton
                            icon={TrashIcon}
                            color={
                                participantsSelected.length === 0
                                    ? 'grayDisabled'
                                    : 'red'
                            }
                            onClick={onFullDeleteCandidateEvent}
                            isDisabled={participantsSelected.length === 0}>
                            Full delete
                        </AppButton>
                    )}
                    <AppButton
                        icon={ResetIcon}
                        color={
                            participantsSelected.length !== 1
                                ? 'grayDisabled'
                                : 'red'
                        }
                        onClick={onResetCandidateScanEvent}
                        isDisabled={
                            participantsSelected.length !== 1 ||
                            !participantsSelected[0].activity?.analysis
                        }>
                        Reset scan
                    </AppButton>
                </div>
            </div>
        </div>
    );
};

export default TargetGroupTableFooter;
