import { Fragment } from 'react';
import styles from './TargetGroupTable.module.css';
import {
    CandidateScore,
    CultureFitScores,
    InterviewScores,
    MentalTestCategory,
    MentalTestScores,
} from '../../../typings/user';
import {
    capitalizeFirstLetter,
    isCultureFitCandidateScore,
    isInterviewCandidateScore,
    isMentalTestCandidateScore,
} from '../utils';

interface CandidateScoresTableProps {
    scores?: CandidateScore[];
    hasCultureFitEngagementModule: boolean;
    hasCultureFitWorkPreferencesModule: boolean;
    hasSpeakingModule: boolean;
    hasCognitiveAbilitiesModule: boolean;
}

const CandidateScoresTable = (props: CandidateScoresTableProps) => {
    const {
        scores,
        hasCultureFitEngagementModule,
        hasCultureFitWorkPreferencesModule,
        hasSpeakingModule,
        hasCognitiveAbilitiesModule,
    } = props;

    const cultureFitScores = scores?.find(isCultureFitCandidateScore);
    const interviewScores = scores?.find(isInterviewCandidateScore);
    const mentalScores = scores?.find(isMentalTestCandidateScore);

    const scoreToFixedDecimal = (score: number | undefined) => {
        if (score) {
            return score.toFixed(1);
        } else return null;
    };

    const renderCultureFitScoresBlock = (
        cultureFitScores?: CultureFitScores,
    ) => {
        const categoriesPlaceholder = [
            { id: 'Family' },
            { id: 'Hierarchy' },
            { id: 'Market' },
            { id: 'Adhocracy' },
        ];
        return (
            <div className={styles.info_block}>
                {hasCultureFitEngagementModule && (
                    <div className={styles.item}>
                        <span className={styles.highlight}>
                            Organization engagement:
                        </span>
                        {cultureFitScores?.company_engagement
                            ? scoreToFixedDecimal(
                                  cultureFitScores.company_engagement,
                              )
                            : '-'}
                    </div>
                )}
                {cultureFitScores?.culture_match && (
                    <div className={styles.item}>
                        <span className={styles.highlight}>Culture match:</span>{' '}
                        {cultureFitScores?.culture_match
                            ? scoreToFixedDecimal(
                                  cultureFitScores?.culture_match,
                              )
                            : '-'}
                    </div>
                )}
                {hasCultureFitWorkPreferencesModule &&
                    cultureFitScores?.categories && (
                        <>
                            <div className={styles.item}>
                                <span className={styles.highlight}>
                                    {capitalizeFirstLetter(
                                        cultureFitScores.categories[0].id,
                                    )}
                                    :
                                </span>
                                {cultureFitScores.categories[0].candidate_score}
                            </div>
                            <div className={styles.item}>
                                <span className={styles.highlight}>
                                    {capitalizeFirstLetter(
                                        cultureFitScores.categories[1].id,
                                    )}
                                    :
                                </span>
                                {cultureFitScores.categories[1].candidate_score}
                            </div>
                            <div className={styles.item}>
                                <span className={styles.highlight}>
                                    {capitalizeFirstLetter(
                                        cultureFitScores.categories[2].id,
                                    )}
                                    :
                                </span>
                                {cultureFitScores.categories[2].candidate_score}
                            </div>
                            <div className={styles.item}>
                                <span className={styles.highlight}>
                                    {capitalizeFirstLetter(
                                        cultureFitScores.categories[3].id,
                                    )}
                                    :
                                </span>
                                {cultureFitScores.categories[3].candidate_score}
                            </div>
                        </>
                    )}
                {hasCultureFitWorkPreferencesModule &&
                    !cultureFitScores?.categories?.length && (
                        <>
                            {categoriesPlaceholder.map(
                                (category: { id: string }, index: number) => (
                                    <div key={index} className={styles.item}>
                                        <span className={styles.highlight}>
                                            {category.id}:
                                        </span>
                                        -
                                    </div>
                                ),
                            )}
                        </>
                    )}
            </div>
        );
    };

    const renderInterviewScoresBlock = (interviewScores?: InterviewScores) => {
        return (
            <div className={styles.info_block}>
                <div className={styles.item}>
                    <span className={styles.highlight}>
                        General motivation:
                    </span>{' '}
                    {interviewScores?.work_motivation
                        ? scoreToFixedDecimal(interviewScores?.work_motivation)
                        : '-'}
                </div>
                <div className={styles.item}>
                    <span className={styles.highlight}>
                        Overall confidence:
                    </span>{' '}
                    {interviewScores?.confidence
                        ? scoreToFixedDecimal(interviewScores?.confidence)
                        : '-'}
                </div>
            </div>
        );
    };

    const renderMentalScoresBlock = (mentalScores?: MentalTestScores) => {
        const categoryNames: { [key: string]: string } = {
            ak: 'General knowledge',
            lr: 'Logical Reasoning',
            nr: 'Numeric Reasoning',
            vr: 'Verbal Reasoning',
        };
        const categoriesPlaceholder = [
            { id: 'ak' },
            { id: 'lr' },
            { id: 'nr' },
            { id: 'vr' },
        ];
        return (
            <div className={styles.info_block}>
                <div className={styles.item}>
                    <span className={styles.highlight}>Stress resilience:</span>{' '}
                    {mentalScores?.stress_resilience
                        ? scoreToFixedDecimal(mentalScores.stress_resilience)
                        : '-'}
                </div>
                <div className={styles.item}>
                    <span className={styles.highlight}>
                        Ease under pressure:
                    </span>
                    {mentalScores?.ease
                        ? scoreToFixedDecimal(mentalScores.ease)
                        : '-'}
                </div>
                {mentalScores?.categories &&
                mentalScores.categories.length > 0 ? (
                    <>
                        {mentalScores.categories.map(
                            (category: MentalTestCategory, index: number) => (
                                <div key={index} className={styles.item}>
                                    <span className={styles.highlight}>
                                        {categoryNames[category.id] ||
                                            category.id}
                                        :
                                    </span>
                                    {category.candidate_score}
                                </div>
                            ),
                        )}
                    </>
                ) : (
                    <>
                        {categoriesPlaceholder.map(
                            (category: { id: string }, index: number) => (
                                <div key={index} className={styles.item}>
                                    <span className={styles.highlight}>
                                        {categoryNames[category.id]}:
                                    </span>
                                    -
                                </div>
                            ),
                        )}
                    </>
                )}
            </div>
        );
    };

    return (
        <Fragment>
            {(hasCultureFitEngagementModule ||
                hasCultureFitWorkPreferencesModule) && (
                <div className={styles.half_width_section}>
                    <h2 className={styles.user_activity__title}>
                        Culture fit scores
                    </h2>
                    {renderCultureFitScoresBlock(
                        cultureFitScores
                            ? cultureFitScores.data.culture_fit
                            : {},
                    )}
                </div>
            )}
            {hasSpeakingModule && (
                <div className={styles.half_width_section}>
                    <h2 className={styles.user_activity__title}>
                        Interview scores
                    </h2>
                    {renderInterviewScoresBlock(
                        interviewScores
                            ? interviewScores.data.interview
                            : undefined,
                    )}
                </div>
            )}
            {hasCognitiveAbilitiesModule && (
                <div className={styles.half_width_section}>
                    <h2 className={styles.user_activity__title}>
                        Cognitive abilities scores
                    </h2>
                    {renderMentalScoresBlock(
                        mentalScores && mentalScores.data.mental_test
                            ? mentalScores.data.mental_test
                            : undefined,
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default CandidateScoresTable;
