import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import ErrorPage from 'components/ErrorPage/ErrorPage';
import { Skeleton } from '@material-ui/lab';
import { ErrorIcon } from 'evergreen-ui';
import { Container, HeaderBlock, InfoTextLeft, HeadingText, ContentContainer, Content } from './AddCompanyView.style';
import AddCompanyForm from 'views/AddCompanyView/AddCompanyForm/AddCompanyForm';
import { selectCompaniesState } from 'store/companies/companies.selectors';
import { AuthenticatedUserProps } from '../../hoc/WithAuthLayout';

interface CreateCompanyViewProps extends AuthenticatedUserProps {}

const AddCompanyView: React.FC<CreateCompanyViewProps> = (props) => {
  const { userRoles } = props;
  const [createdCompanyName, setCreatedCompanyName] = useState<string>('');
  const { error, creatingCompany } = useSelector(selectCompaniesState);
  const navigate = useNavigate();

  const renderTableInfoBox = () => {
    return (
      <HeaderBlock>
        <InfoTextLeft>
          <HeadingText>
            <h1>Add new client</h1>
          </HeadingText>
        </InfoTextLeft>
      </HeaderBlock>
    );
  };

  const onCreateCompany = (companyName: string) => {
    setCreatedCompanyName(companyName);
  };

  // todo: move Skeleton and results container to AddCompanyForm component
  return (
    <Container>
      {userRoles.includes('super-admin') && (
        <React.Fragment>
          {renderTableInfoBox()}
          {creatingCompany && (
            <ContentContainer>
              <Skeleton variant="text" width="100%" height="25px" animation="wave" />
            </ContentContainer>
          )}
          {!creatingCompany && !error && createdCompanyName && (
            <ContentContainer>
              <Content>
                New company {createdCompanyName} successfully created. <br />
                <br />
                Click <Link to={'/'}>here</Link> to return to the client list.
              </Content>
            </ContentContainer>
          )}
          {!creatingCompany && error && (
            <ContentContainer>
              <Content>Error: {error.message ? error.message : 'Unexpected error'} </Content>
            </ContentContainer>
          )}
          {!creatingCompany && !createdCompanyName && !error && (
            <AddCompanyForm cancelAddingCompany={() => navigate('/')} onCreateCompany={onCreateCompany} />
          )}
        </React.Fragment>
      )}
      {!userRoles.includes('super-admin') && (
        <ErrorPage icon={ErrorIcon} iconColor={'danger'} message="You do not have permission to view this page." />
      )}
    </Container>
  );
};

export default AddCompanyView;
