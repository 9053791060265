import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import DashboardCard, {
    LabelObject,
} from 'components/DashboardCard/DashboardCard';
import { AddIcon, PeopleIcon } from 'evergreen-ui';
import AppButton from 'components/AppButton/AppButton';
import { selectCompaniesState } from 'store/companies/companies.selectors';
import { Company } from 'store/companies/companies.reducer';
import { UserRole } from '../../typings/user';

interface CompaniesListViewProps {
    userRoles: UserRole[];
}

const CompaniesListView: FunctionComponent<CompaniesListViewProps> = (
    props,
) => {
    const { userRoles } = props;
    const isSuperAdmin = userRoles.includes('super-admin');
    const navigate = useNavigate();
    const { companies } = useSelector(selectCompaniesState);

    const renderAddCompanyButton = () => {
        return (
          <AppButton
            icon={AddIcon}
            onClick={() => navigate('/add-company')}>
              Add new client
          </AppButton>
        );
    };

    return (
        <div className={styles.companies_view}>
            <div className={styles.companies_info_box}>
                <div className={styles.companies_info_box__info}>
                    <div className={styles.companies_info_box__name}>
                        <h2>Clients</h2>
                    </div>
                    <div className={styles.number_of_companies}>
                        <div className={styles.number_of_companies__icon}>
                            <PeopleIcon size={13} />
                        </div>
                        <span className={styles.number_of_companies__text}>
                            {(companies.length === 0 && `0 companies`) ||
                                (companies.length === 1 && `1 company`) ||
                                (companies.length > 1 &&
                                    `${companies.length} companies`)}
                        </span>
                    </div>
                </div>
                {isSuperAdmin && (
                    <div className={styles.companies_info_box__add_company}>
                        {renderAddCompanyButton()}
                    </div>
                )}
            </div>

            <div className={styles.card_list}>
                {companies.map((company: Company) => {
                    const cardLabels: LabelObject[] = [];
                    cardLabels.push({ color: 'main', text: company.country });
                    return (
                        <Link key={company.id} to={company.slug}>
                            <DashboardCard labels={cardLabels}>
                                <h1>{company.name}</h1>
                            </DashboardCard>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default CompaniesListView;
