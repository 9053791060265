import styled from 'styled-components';

interface IProps {
    isActive?: boolean;
}

export const SideMenu = styled.div`
    width: 200px;
    height: 100%;
    position: fixed;
    background-color: #435970;
    font-size: 16px;
    color: white;
    padding: 24px 0;
    grid-area: sidebar;
`;
export const SideMenuTop = styled.div`
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const SideMenuBottom = styled.div`
    height: 40%;
    //display: flex;
    display: none;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid white;
    padding: 32px 0;
`;
export const SideMenuLogoWrapper = styled.div`
    background-color: white;
    border: 1px solid white;
    border-radius: 50%;
    height: 92px;
    width: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
`;
export const Img = styled.img`
    width: 40px;
    height: 40px;
`;
export const SideMenuSectionsWrapper = styled.div`
    width: 100%;
`;
export const SideMenuSection = styled('div')<IProps>`
    background-color: #435970;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    ${({ isActive }) =>
        isActive &&
        `
    background-color:#19a6ad;
    height:84px;
    font-weight:bold;
    padding: 10px 0;
  `}
    &&:hover {
        background-color: #19a6ad;
        cursor: pointer;
    }
`;
export const SideMenuSectionIconWrapper = styled('div')<IProps>`
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    background-color: #ffffff66;
    border: 1px solid #ffffff66;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
`;
export const SideMenuReturnWrapper = styled.div`
    color: #ababab;
    font-weight: 300;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
        color: lightgray;
    }
`;
export const SideMenuInfoWrapper = styled.div`
    margin-top: 80px;
`;
export const SideMenuInfo = styled.div`
    height: 40px;
    cursor: pointer;
    &:hover {
        color: #ababab;
    }
`;
