import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 150px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

export const ImageColumn = styled.div`
  display: flex;
  width: 50%;
  flex-flow: row nowrap;
`;

export const Image = styled.img`
  width: 90%;
  height: auto;
`;

export const TextColumn = styled.div`
  display: flex;
  width: 50%;
  flex-flow: column;
  padding-left: 50px;
  justify-content: space-evenly;
`;

export const DashboardDescription = styled.p`
  color: #445870;
  margin: 15px 0;
`;

export const LoginButtonContainer = styled.div`
  align-self: flex-end;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ForgotPassword = styled.span`
  margin-top: 10px;
  font-size: 0.9em;
  color: #445870;
  cursor: pointer;
`;

export const Updates = styled.div`
  color: #445870;
  padding: 10px;
  margin: 50px 0;

  h3 {
    color: #00a5ad;
  }
  ul {
    list-style: inside square;
  }
`;
