import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AfterUploaded,
    AfterUploadedWrapper,
    Tab,
    TabsWrapper,
    TrashIconWrapper,
    UploadButton,
    UploadContainer,
    MediaLibraryWrap,
    UploadTitle,
    UploadWrapper,
    FilesListWrap,
} from './styles';
import { TrashIcon } from 'evergreen-ui';
import useDragAndDrop from '../../hooks/useDragAndDrop';
import { selectMediaFiles } from 'store/mediaFiles/mediaFiles.selectors';
import { actions as mediaFilesActions } from 'store/mediaFiles/mediaFiles.actions';
import FileCard from './FileCard';
import { MediaLibraryProps, MediaLibraryVideo } from '../../typings';

const MediaLibrary = (props: MediaLibraryProps) => {
    const { onToUploadSelect, onFileDeSelect, preselectedVideo, companyId } =
        props;
    const [activeTab, setActiveTab] = useState<'upload' | 'library'>('upload');

    const [selectedVideo, setSelectedVideo] = useState<MediaLibraryVideo>({
        url: '',
        file: undefined,
        id: undefined,
    });
    const [checked, setChecked] = useState<string>('');
    const dispatch = useDispatch();
    const mediaFiles = useSelector(selectMediaFiles(companyId));

    const {
        dragOver,
        setDragOver,
        onDragOver,
        onDragLeave,
        fileDropError,
        setFileDropError,
    } = useDragAndDrop();

    const onDrop = (
        e: React.DragEvent<HTMLLabelElement>,
    ): void | React.Dispatch<React.SetStateAction<string>> => {
        e.preventDefault();
        setDragOver(false);
        const selectedFile = e?.dataTransfer?.files[0];
        if (selectedFile) {
            if (selectedFile.type && selectedFile.type !== 'video/mp4') {
                return setFileDropError(
                    'Please provide a MP4 video file to upload.',
                );
            }
            if (selectedFile.size > 60 * 1024 * 1024) {
                return setFileDropError(
                    'The video file size exceeds the 60 MB limit.',
                );
            }
            const file: MediaLibraryVideo = {
                url: URL.createObjectURL(selectedFile),
                file: selectedFile,
                id: undefined,
            };
            setSelectedVideo(file);
            onToUploadSelect(file);
        }
    };

    const fileSelect = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void | React.Dispatch<React.SetStateAction<string>> => {
        const files = event.target.files as FileList;
        const selectedCurrentFile = files[0];
        if (selectedCurrentFile) {
            if (
                selectedCurrentFile.type &&
                selectedCurrentFile.type !== 'video/mp4'
            ) {
                return setFileDropError(
                    'Please provide a MP4 video file to upload.',
                );
            }
            if (selectedCurrentFile.size > 60 * 1024 * 1024) {
                return setFileDropError(
                    'The video file size exceeds the 60 MB limit.',
                );
            }
            setFileDropError('');
            const file: MediaLibraryVideo = {
                url: URL.createObjectURL(selectedCurrentFile),
                file: selectedCurrentFile,
                id: undefined,
            };
            setSelectedVideo(file);
            onToUploadSelect(file);
        }
    };

    const handleDeSelectVideo = (): void => {
        setSelectedVideo({ url: '', file: undefined, id: undefined });
        setFileDropError('');
        onFileDeSelect();
    };

    const handleSelectedVideo = (fileId: string): void => {
        const mediaFile = mediaFiles.find((file) => file.id === fileId);
        if (mediaFile) {
            setChecked(mediaFile.id);
            const selectedFile: MediaLibraryVideo = {
                url: mediaFile.url,
                file: undefined,
                id: mediaFile.id,
            };
            setSelectedVideo(selectedFile);
            onToUploadSelect(selectedFile);
        }
    };

    useEffect(() => {
        if (preselectedVideo) {
            if (preselectedVideo.id) {
                setChecked(preselectedVideo.id);
                setActiveTab('library');
            } else {
                setSelectedVideo(preselectedVideo);
                setActiveTab('upload');
            }
        }
    }, [preselectedVideo]);

    useEffect(() => {
        if (companyId && !mediaFiles.length) {
            dispatch(mediaFilesActions.getCompanyMediaFiles(companyId));
        }
    }, [companyId, dispatch, mediaFiles.length]);

    return (
        <MediaLibraryWrap>
            <TabsWrapper>
                <Tab
                    aria-label="upload tab"
                    active={activeTab === 'upload'}
                    onClick={() => setActiveTab('upload')}>
                    Upload new video
                </Tab>
                <Tab
                    aria-label="library tab"
                    active={activeTab === 'library'}
                    onClick={() => setActiveTab('library')}>
                    Video Library
                </Tab>
            </TabsWrapper>
            {activeTab === 'upload' && (
                <UploadContainer>
                    {!selectedVideo.file && (
                        <UploadWrapper
                            onDragOver={onDragOver}
                            onDragLeave={onDragLeave}
                            onDrop={onDrop}>
                            {fileDropError && (
                                <span className="file-drop-error">
                                    {fileDropError}
                                </span>
                            )}
                            <UploadTitle>
                                {!dragOver
                                    ? 'Drag & drop video file to upload'
                                    : 'Drop here to upload...'}
                            </UploadTitle>
                            <div>or</div>
                            <UploadButton>
                                <input
                                    aria-label="upload new video"
                                    type="file"
                                    name="video-selector"
                                    onChange={fileSelect}
                                    accept="video/mp4"
                                />
                                Browse
                            </UploadButton>
                            {!fileDropError && (
                                <div>
                                    <span className="file-drop-note">
                                        Maximum video file size is 60MB
                                    </span>
                                    <span className="file-drop-note">
                                        Video file type is MP4
                                    </span>
                                </div>
                            )}
                        </UploadWrapper>
                    )}
                    {!!selectedVideo.url && !selectedVideo.id && (
                        <AfterUploadedWrapper>
                            <AfterUploaded>
                                <video
                                    aria-label="uploaded video"
                                    width={400}
                                    src={selectedVideo.url}
                                    controls>
                                    Your browser does not support the video tag.
                                </video>
                                <TrashIconWrapper
                                    onClick={() => handleDeSelectVideo()}>
                                    <TrashIcon />
                                </TrashIconWrapper>
                            </AfterUploaded>
                            <UploadTitle className="video-title">
                                {selectedVideo.file?.name}
                            </UploadTitle>
                        </AfterUploadedWrapper>
                    )}
                </UploadContainer>
            )}
            {activeTab === 'library' && (
                <FilesListWrap aria-label="library videos">
                    {mediaFiles.map((mediaFile) => {
                        return (
                            <FileCard
                                mediaFile={mediaFile}
                                key={mediaFile.id}
                                checked={checked}
                                handleSelectedVideo={handleSelectedVideo}
                            />
                        );
                    })}
                </FilesListWrap>
            )}
        </MediaLibraryWrap>
    );
};

export default MediaLibrary;
