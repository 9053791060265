import styled from 'styled-components';
import * as variables from '../../../../../assets/styles/variables';

export const ForthStepWrapper = styled('div')`
    padding: 4px 0;
`;
export const ReviewSection = styled('div')`
    padding: 24px 0;
`;
export const Title = styled('div')`
    padding: 4px 0;
    color: #435970;
    font-size: 22px;
    font-weight: bold;
    &.small {
        font-size: 18px;
        font-weight: 500;
    }
`;
export const DetailsBox = styled('div')`
    padding: 24px 0;
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 50%;
    grid-gap: 16px 0;
`;
export const Value = styled('div')`
    padding: 4px 24px;
    color: #435970;
    font-weight: 300;
    line-height: 24px;
`;
export const EditIconWrapper = styled('div')`
    padding: 4px 0;
    cursor: pointer;
    color: #435970;
    &:hover {
        color: #849cb1;
    }
`;
export const AssessmentSection = styled('div')`
    padding: 24px 0;
    border-top: 1px solid #435970;
    border-bottom: 1px solid #435970;
  &.no-border-bottom{
    border-bottom: none;
  }
`;
export const UploadTitle = styled('div')`
    color: #435970;
    font-size: 16px;
    &.video-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 50%;
    }
    &.speaking-title {
        padding: 32px 0 16px 0;
        font-weight: bold;
        font-size: 18px;
    }
`;
export const InfoTooltip = styled('div')`
    font-size: 13px;
    position: absolute;
    top: calc(100% - 34px);
    left: 64px;
    visibility: hidden;
    color: transparent;
    background-color: transparent;
    width: 250px;
    padding: 20px;
    border-radius: 18px;
    transition: visibility 0.5s, color 0.5s, background-color 0.5s,
        padding 0.5s ease-in-out;
`;
export const InfoIcon = styled('div')`
    font-size: 14px;
    font-weight: bold;
    padding-top: 5px;
    color: white;
    width: 26px;
    height: 26px;
    background-color: #435970;
    border-radius: 50%;
    margin-left: 24px;
    text-align: center;
    &:hover {
        background-color: #849cb1;
        cursor: pointer;
    }
`;
export const InfoIconWrapper = styled('div')`
    position: relative;
    display: flex;
    align-items: center;
    & ${InfoIcon}:hover + ${InfoTooltip} {
        visibility: visible;
        color: #435970;
        border: 1px solid #849cb1;
        line-height: 26px;
        width: 250px;
        padding: 20px;
        border-radius: 18px;
        background-color: #f0f6fb;
        cursor: pointer;
    }
`;
export const CardsWrapper = styled('div')`
    padding: 32px 0;
`;
export const CardTitle = styled('div')`
    color: #435970;
    font-size: 20px;
    font-weight: bold;
`;
export const IconsWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 7%;
`;
export const CheckMarkIcon = styled('div')`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #00a5ac;
    font-size: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const Card = styled('div')`
    padding: 24px 32px;
    margin: 24px 0;
    border-radius: 4px;
    width: 50%;
    background-color: #F5F6FB;
`;
export const CardHeader = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
export const VideoWrapper = styled('div')`
    padding: 24px 0;
    color: #435970;
    font-weight: 300;
    line-height: 24px;
`;
export const VideoPreviewWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 18px;
`;
export const ScanSettingSection = styled('div')`
    padding: 24px 0;
    .line {
        background-color: #435970;
        height: 1px;
        border: 0;
        width: 50%;
    }
`;
export const ScanSettingWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 32px 0;
    width: 50%;
    .switch {
        input:checked + div {
            background-color: #00a5ad;
        }
        input:checked:hover + div,
        input:not([disabled]):checked:active + div {
            background-color: #00a5ad;
            transition: 0.3s all ease-in;
            opacity: 0.7;
        }
    }
`;
export const TitleWrapper = styled('div')`
    color: #435970;
    font-weight: 300;
    line-height: 36px;
`;
export const InputBox = styled.div`
    display: flex;
    align-items: center;
    input,
    select {
        background-color: #f5f6fa;
        width: 40%;
        padding: 12px 12px;
        margin: 10px 0 10px 0;
        border: 0;
    }
    textarea:focus,
    input:focus,
    select:focus {
        outline-color: ${variables.tealblue};
    }
`;
