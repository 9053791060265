import React, { FunctionComponent, ReactNode } from 'react';
import styles from './Badge.module.css';

import classNames from 'classnames/bind';

// todo
let cx = classNames.bind(styles);

export type BadgeColors = 'main' | 'secondary';
interface BadgeProps {
    children: ReactNode;
    color?: BadgeColors;
}

const Badge: FunctionComponent<BadgeProps> = (props) => {
    let main = cx({
        badge: true,
        badge__main: true,
    });

    let secondary = cx({
        badge: true,
        badge__secondary: true,
    });

    const color = props.color ? props.color : 'main';

    return (
        <span className={color === 'main' ? main : secondary}>
            {props.children}
        </span>
    );
};

export default Badge;
