import styled from 'styled-components';
export const SuccessScreenWrapper = styled.div`
    --fc1: #0ba6ac;
    --fc2: #0ba6ac;
    --fc3: #0ba6ac;
    --fc4: #0ba6ac;
    --fc5: #0ba6ac;
    --fc: var(--fc1);
    overflow: hidden;
    margin: 0;
    padding: 0;
    .fireworks:before,
    .fireworks:after {
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        box-shadow: -120px 0 var(--fc), 120px 0 var(--fc), 0 120px var(--fc),
            0 -120px var(--fc), 45px 45px var(--fc), -45px 45px var(--fc),
            85px 85px var(--fc), -85px 85px var(--fc), 45px -45px var(--fc),
            -45px -45px var(--fc), 85px -85px var(--fc), -85px -85px var(--fc),
            65px 0 var(--fc), -65px 0 var(--fc), 0 65px var(--fc),
            0 -65px var(--fc), 100px 45px var(--fc), -100px 45px var(--fc),
            -50px 100px var(--fc), 50px 100px var(--fc), 100px -45px var(--fc),
            -100px -45px var(--fc), -50px -100px var(--fc),
            50px -100px var(--fc), 5px 10px var(--fc);
        animation: 1s bang ease-out infinite backwards,
            1s gravity ease-in infinite backwards,
            5s position linear infinite backwards;
    }
    .fireworks:after {
        animation-delay: 1.25s, 1.25s, 1.25s;
        animation-duration: 1.25s, 1.25s, 6.25s;
    }
    @keyframes bang {
        from {
            box-shadow: 0 0 #fff;
        }
    }

    @keyframes gravity {
        to {
            transform: translate(-10px, 150px);
            height: 6px;
            width: 4px;
            opacity: 0;
        }
    }
    @keyframes position {
        0%,
        19.9% {
            margin-top: 10%;
            margin-left: 40%;
            --fc: var(--fc1);
        }

        20%,
        39.9% {
            margin-top: 2%;
            margin-left: 70%;
            --fc: var(--fc2);
        }

        40%,
        59.9% {
            margin-top: 20%;
            margin-left: 10%;
            --fc: var(--fc3);
        }

        60%,
        79.9% {
            margin-top: 30%;
            margin-left: 20%;
            --fc: var(--fc4);
        }

        80%,
        99.9% {
            margin-top: 3%;
            margin-left: 10%;
            --fc: var(--fc5);
        }
    }
`;
export const SuccessScreenCheckMarkWrapper = styled.div`
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .success {
        display: block;
        animation: fill 0.4s ease-in-out 0.4s forwards,
            scale 0.3s ease-in-out 0.9s both;
    }

    .success__circle {
        stroke-dasharray: 304;
        stroke-dashoffset: 300;
        stroke-width: 2;
        stroke-miterlimit: 10;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    &success__check {
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes scale {
        0%,
        100% {
            transform: none;
        }
        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }
`;
export const SuccessBox = styled.div`
    background-color: #00a5ac17;
    border: #00a5ac36;
    min-height: 300px;
    margin-top: 32px;
    margin-bottom: 40px;
    padding: 24px;
    width: 100%;
    max-width: 70vw;
    border-radius: 18px;
    box-shadow: rgb(17 17 26 / 10%) 0 4px 16px, rgb(17 17 26 / 10%) 0 8px 24px,
        rgb(17 17 26 / 10%) 0 16px 56px;
    .success-title {
        margin-top: 32px;
        font-size: 30px;
        font-weight: bold;
        color: #00a5ace3;
        text-align: center;
        padding-left: 16px;
    }
    .success-children {
        font-size: 20px;
        text-align: center;
        line-height: 32px;
        color: #00a5ace3;
    }
`;
